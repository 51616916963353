import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { AppContext } from "../Context/Provider";

import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
const Carousel = () => {
  const context = useContext(AppContext); // Get context object
  const { HomeSlider, FetchHomeSlider } = context; // Destructure values
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    FetchHomeSlider();
  }, []);

  useEffect(() => {
    if (HomeSlider.length > 0) {
      const intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % HomeSlider.length);
      }, 3000); // Change slide every 3 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [HomeSlider]);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return isMobile ? (
    <CarouselMobile
      HomeSlider={HomeSlider}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
    />
  ) : (
    <CarouselDesktop
      HomeSlider={HomeSlider}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
    />
  );
};

export default Carousel;

const CarouselMobile = ({ HomeSlider, activeIndex, setActiveIndex }) => {
  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + " ...";
    }
    return description;
  };

  const truncateTitles = (description) => {
    const words = description.split(" ");
    if (words.length > 5) {
      return words.slice(0, 5).join(" ") + " ...";
    }
    return description;
  };

  return (
    <section className="relative w-full h-screen flex flex-col justify-center items-center overflow-hidden">
      {HomeSlider.map((item, index) => (
        <div
          key={item._id}
          className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
            index === activeIndex ? "opacity-100" : "opacity-0"
          }`}
          style={{
            backgroundImage: `url(${item.fileName})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-6"
            style={{
              background: "rgba(0, 0, 0, 0.5)", // Darker overlay to make text stand out
            }}
          >
            <div className="w-full max-w-md mx-auto">
              <h1
                className="text-2xl font-bold font-aeonik mb-4"
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              />
              <p
                className="text-sm font-roboto mb-6"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
              <div className="flex flex-col space-y-3 w-full">
                <Link className="bg-custom-green px-4 py-3 rounded font-poppins text-center">
                  <span>Download the App</span>
                </Link>
                <Link
                  to={item.supportLink || "/support"}
                  className="border border-custom-green text-white px-4 py-3 rounded font-poppins text-center"
                >
                  <span>Support</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

const CarouselDesktop = ({ HomeSlider, activeIndex, setActiveIndex }) => {
  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 50) {
      return words.slice(0, 50).join(" ") + " ...";
    }
    return description;
  };

  const truncateTitles = (description) => {
    const words = description.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + " ...";
    }
    return description;
  };

  return (
    <section className="relative w-full">
      <div className="relative h-56 md:h-96">
        {HomeSlider.map((item, index) => (
          <div
            key={item._id}
            className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
              index === activeIndex ? "opacity-100" : "opacity-0"
            }`}
            style={{ height: "100%" }}
          >
            <LazyLoad height={532} offset={100} once>
              <img
                src={item.fileName}
                alt={`Slide ${index + 1}`}
                className="block w-full object-cover"
                style={{
                  marginTop: "10px",
                  height: "532px",
                }}
              />
            </LazyLoad>
            <div className="absolute inset-0 flex items-center justify-start text-left text-white p-6 ml-24 mt-24 ml-44">
              <div>
                <h1
                  style={{ marginLeft: "83px", marginTop: "186px" }}
                  className="text-5xl font-bold font-aeonik"
                  dangerouslySetInnerHTML={{
                    __html: truncateTitles(item.title),
                  }}
                />
                <p
                  className="mt-4 text-sm md:text-sm font-roboto"
                  style={{ lineHeight: "30px", marginLeft: "83px" }}
                  dangerouslySetInnerHTML={{
                    __html: truncateDescription(item.description),
                  }}
                />

                <div
                  className="mt-8 flex flex-col md:flex-row justify-start space-y-4 md:space-y-0 md:space-x-4"
                  style={{ marginLeft: "85px" }}
                >
                  <Link className="bg-custom-green px-6 py-3 rounded font-poppins flex items-center gap-3">
                    <span>Download the App</span>
                    <i className="fa-solid fa-arrow-right mt-1"></i>
                  </Link>
                  <Link
                    to={item.supportLink || "/support"}
                    className="border border-custom-green text-white px-6 py-3 rounded font-poppins flex items-center gap-3"
                  >
                    <span>Support</span>
                    <i className="fa-solid fa-arrow-right mt-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        className="absolute flex -translate-x-1/2 left-1/2 space-x-3 rtl:space-x-reverse "
        style={{ marginTop: "100px" }}
      >
        {HomeSlider.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-2 h-2 rounded-full ${
              index === activeIndex ? "bg-custom-green" : "bg-white"
            }`}
            aria-current={index === activeIndex}
            aria-label={`Slide ${index + 1}`}
            onClick={() => setActiveIndex(index)}
          ></button>
        ))}
      </div>
    </section>
  );
};
