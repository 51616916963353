import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";

const ServiceQuestions = () => {
  const { FetchServiceFAQ, ServiceFAQData } = useContext(AppContext);
  useEffect(() => {
    FetchServiceFAQ();
  }, []);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is Rikaab?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed scelerisque egestas nibh. Fusce fermentum vestibulum nisl, non tempus lorem luctus at. Nulla facilisi. Integer at magna vel massa vehicula efficitur.",
    },
    {
      question: "How i create an account?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed scelerisque egestas nibh. Fusce fermentum vestibulum nisl, non tempus lorem luctus at. Nulla facilisi. Integer at magna vel massa vehicula efficitur.",
    },
    {
      question: "How do i contact customer service",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed scelerisque egestas nibh. Fusce fermentum vestibulum nisl, non tempus lorem luctus at. Nulla facilisi. Integer at magna vel massa vehicula efficitur.",
    },
    {
      question: "How do i book a taxi",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed scelerisque egestas nibh. Fusce fermentum vestibulum nisl, non tempus lorem luctus at. Nulla facilisi. Integer at magna vel massa vehicula efficitur.",
    },
    {
      question: "How do i find a restaurants",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed scelerisque egestas nibh. Fusce fermentum vestibulum nisl, non tempus lorem luctus at. Nulla facilisi. Integer at magna vel massa vehicula efficitur.",
    },
    {
      question: "How do i use Buy Now, Pay Latter Emurabaha",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed scelerisque egestas nibh. Fusce fermentum vestibulum nisl, non tempus lorem luctus at. Nulla facilisi. Integer at magna vel massa vehicula efficitur.",
    },
  ];
  console.log("infinita e.");
  return (
    <section className="bg-white container p-8 mx-auto mt-2">
      <div className="md:mx-auto mx-5 mt-8 grid md:max-w-5xl divide-y divide-neutral-200">
        <p className="text-2xl  text-center p-5">
          Frequently asked questions from drivers
        </p>
        {ServiceFAQData?.map((faq, index) => (
          <div className="py-5" key={index}>
            <details className="group">
              <summary
                className="flex cursor-pointer list-none items-center justify-between font-medium"
                onClick={() => toggleFAQ(index)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                <span>{faq.question}</span>
                <span className="transition flex items-center">
                  <svg
                    className={`summary-icon h-6 w-6 bg-custom-green text-white rounded-full me-5 ${
                      openIndex === index ? "hidden" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v6m3-3H9"
                    />
                  </svg>
                  <svg
                    className={`minus-icon h-6 w-6 bg-green-500 text-white rounded-full me-5 ${
                      openIndex === index ? "" : "hidden"
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 12H6"
                    />
                  </svg>
                </span>
              </summary>
              <p
                className={`group-open:animate-fadeIn mt-3 text-neutral-600 ${
                  openIndex === index ? "block" : "hidden"
                }`}
              >
                {faq.answer}
              </p>
            </details>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceQuestions;
