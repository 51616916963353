import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const JoinSection = () => {
  const navigate = useNavigate();
  const { FetchHomeCards, HomeBecomeCards } = useContext(AppContext);

  useEffect(() => {
    FetchHomeCards();
  }, []);

  const handleNavigation = (link, _id) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(link, { state: { id: _id } });
  };

  return (
    <section className="py-10 bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {HomeBecomeCards?.map((card, index) => (
            <div
              key={index}
              className="p-10 rounded-lg text-left transition-all duration-300 hover:shadow-lg cursor-pointer"
              onClick={() => handleNavigation(card.link, card._id)}
            >
              <LazyLoad offset={100} once>
                <img
                  src={card.fileName}
                  alt={`${card.title} Icon`}
                  className="mx-auto mb-4 w-24 h-auto" // Controlled width and auto height for responsiveness
                />
              </LazyLoad>
              <h3 className="text-lg font-bold mb-2 text-gray-800">
                {card.title}
              </h3>
              <p className="text-gray-600 text-sm">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default JoinSection;
