import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeroDeliveryImage from "../images/heros.jpg"; // Ensure the image path is correct
import GooglePlayImage from "../images/googleplay.png";
import AppStoreImage from "../images/appstore.png";
import LazyLoad from "react-lazyload";

const DeliveryCoverSection = () => {
  // const [backgroundImage, setBackgroundImage] = useState(""); // State for background image

  // useEffect(() => {
  //   // Load the background image after component mounts
  //   const loadBackgroundImage = () => {
  //     setBackgroundImage(`url(${HeroDeliveryImage})`);
  //   };

  //   loadBackgroundImage();
  // }, []);

  return (
    <div
      className="relative bg-cover bg-center mt-6"
      style={{
        backgroundImage: `url(${HeroDeliveryImage})`,
        backgroundColor: "transparent", // Optional placeholder color
        marginTop: "10px",
        height: "532px",
        transition: "background-image 1s ease-in-out",
      }}
    >
      {/* <LazyLoad height={532} offset={100}>
        <img
          src={HeroDeliveryImage}
          alt="Delivery Background"
          style={{ display: "none" }} // Hide the actual image used for lazy loading
        />
      </LazyLoad> */}

      <div className="relative z-10 flex flex-col items-center justify-center h-full p-4">
        <div className="text-center text-white rounded-lg max-w-2xl">
          <div className="flex justify-center">
            <Link
              to="#" // Replace href with to for Link component
              className="text-white py-2 rounded-md  flex items-center space-x-2 text-2xl md:text-4xl lg:text-6xl mt-10 md:mt-20"
            >
              <span style={{ fontWeight: 700 }}>
                Make money driving with Rikaab
              </span>
            </Link>
          </div>
          <div className="flex justify-center mt-4 md:mt-6">
            <Link
              to="#" // Replace href with to for Link component
              className="text-white py-2 rounded-md  flex items-center space-x-2 text-lg md:text-2xl"
            >
              <span>
                Become a Rikaab deliveryman, set your schedule and earn money by
                driving!
              </span>
            </Link>
          </div>
          <div className="flex justify-center space-x-4 items-center mt-12 md:mt-24 mb-6">
            <Link to="https://play.google.com/store/apps/details?id=com.faras&pli=1">
              <LazyLoad height={50} offset={100}>
                <img
                  className="w-24 md:w-48 lg:w-60 h-auto"
                  src={GooglePlayImage}
                  alt="Google Play Store"
                />
              </LazyLoad>
            </Link>
            <Link to="https://apps.apple.com/us/app/rikaab/id1636848011">
              <LazyLoad height={50} offset={100}>
                <img
                  className="w-24 md:w-48 lg:w-60 h-auto"
                  src={AppStoreImage}
                  alt="App Store"
                />
              </LazyLoad>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryCoverSection;
