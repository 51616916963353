import React, { useEffect } from "react";

import DeliveryBenefitsSection2 from "../../Components/DeliveryBenefitsSection2";
import DegDegSection2 from "../../Components/DegDegSection2";
import RequirementsSection2 from "../../Components/RequirementsSection2";
import HowItWorksSection2 from "../../Components/HowItWorksSection2";
import FAQSection from "../../Components/SupportFAQSection";
import FAQSectionQuestions from "../../Components/SupportFAQSection 1";
import Footer from "../../Components/Footer";
import DeliveryCoverSection2 from "../../Components/DeliveryCoverSection2";
import { useLocation } from "react-router-dom";
import GeneralQuestions from "../../Components/GeneralFAQ";
function DeliveryManTax() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { id } = location.state || {};
  console.log("delivery man:", id);
  return (
    <div>
      <DeliveryCoverSection2 />
      <DeliveryBenefitsSection2 />
      <DegDegSection2 />
      <RequirementsSection2 />
      <HowItWorksSection2 />
      {/* <FAQSection /> */}
      {/* <FAQSectionQuestions /> */}
      <GeneralQuestions id={id} />
      <Footer />
    </div>
  );
}

export default DeliveryManTax;
