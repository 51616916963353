import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";

const SupportsFAQs = () => {
  const { FetchSupportFAQ, SupportFAQ } = useContext(AppContext);
  const [openIndex, setOpenIndex] = useState(null);
  useEffect(() => {
    FetchSupportFAQ();
  }, []);
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-white container p-8 mx-auto mt-2">
      <div className="md:mx-auto mx-5 mt-8 grid md:max-w-5xl divide-y divide-neutral-200">
        <h1 className="text-lg md:text-lg font-semibold text-gray-800 text-center mb-8">
          Frequently Asked Questions
        </h1>
        {SupportFAQ?.map((faq, index) => (
          <div className="py-5" key={index}>
            <details className="group">
              <summary
                className="flex cursor-pointer list-none items-center justify-between font-medium"
                onClick={() => toggleFAQ(index)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                <span>{faq.question}</span>
                <span className="transition flex items-center">
                  <svg
                    className={`summary-icon h-6 w-6 bg-custom-green text-white rounded-full me-5 ${
                      openIndex === index ? "hidden" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v6m3-3H9"
                    />
                  </svg>
                  <svg
                    className={`minus-icon h-6 w-6 bg-green-500 text-white rounded-full me-5 ${
                      openIndex === index ? "" : "hidden"
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 12H6"
                    />
                  </svg>
                </span>
              </summary>
              <p
                className={`group-open:animate-fadeIn mt-3 text-neutral-600 ${
                  openIndex === index ? "block" : "hidden"
                }`}
              >
                {faq.answer}
              </p>
            </details>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SupportsFAQs;
