import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const GallerySection = () => {
  const navigate = useNavigate();
  const { FetchSupportService, SupportOurservice } = useContext(AppContext);

  useEffect(() => {
    FetchSupportService();
  }, []);
  return (
    <section className="container mx-auto p-6 md:p-20 mb-16 md:mb-32">
      <div className="text-center mb-8">
        <h2 className="text-3xl md:text-5xl font-bold mb-16">
          How can we help?
        </h2>
        {/* <div className="relative mx-auto">
          <input
            type="text"
            placeholder="Type your question"
            className="w-full border border-gray-300 rounded-md py-4 px-8 text-1xl md:text-3xl"
          />
          <button className="absolute right-4 top-1/2 transform -translate-y-1/2 text-green-500">
            <i className="fas fa-search text-4xl"></i>
          </button>
        </div> */}
      </div>

      <div className="bg-green-50 grid grid-cols-2 md:grid-cols-3 md:gap-4">
        {SupportOurservice?.map((item, index) => (
          <div
            key={index}
            className="text-center bg-white p-6 cursor-pointer transition-transform transform hover:scale-105"
            // onClick={() => item.path !== "#" && navigate(item.path)}
            onClick={() =>
              item.path !== "#" &&
              navigate(item.path, { state: { id: item._id } })
            }
          >
            <LazyLoad height={200} offset={100}>
              <img
                className="md:h-72 h-34 w-full object-cover"
                src={item.fileName}
                alt={item.title}
              />
            </LazyLoad>
            {/* <h3 className="text-2xl font-semibold mt-4">{item.title}</h3> */}
            <h3 className="font-ubuntu text-[20px] font-medium leading-[25px] text-center mt-4">
              {item.title}
            </h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default GallerySection;
