import React from "react";
import ShareAndComment from "../../Components/ShareAndComment ";
import Footer from "../../Components/Footer";
import CallSection from "../../Components/BlogCallSection";
import { useLocation } from "react-router-dom";
import CategoriesSection from "../../Components/CatSection";
import HelpSection from "../../Components/HelpSection";
import EventsPosts from "../../Components/EventsPosts";
import EventContants from "../../Components/EventContent";
import EventHeaderDetails from "../../Components/EventHeaderDetails";

const EventLayout = () => {
  const location = useLocation();
  const { id } = location.state || {};

  return (
    <>
      <EventHeaderDetails />
      <div className="mx-auto max-w-7xl p-4">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          {/* Sidebar - Visible only on medium screens and up */}
          <div className="md:col-span-4 bg-white p-4 my-2 space-y-4">
            <EventsPosts id={id} />
            <HelpSection />
            <CategoriesSection />
          </div>

          {/* Main Content - Takes full width on small screens */}
          <div className="md:col-span-8 bg-white p-4 space-y-4">
            <EventContants id={id} />
            <ShareAndComment />
          </div>
        </div>
      </div>
      <CallSection />
      <Footer />
    </>
  );
};

export default EventLayout;
