import React, { useEffect } from "react";
import Footer from "../../Components/Footer";

import CallSection from "../../Components/BlogCallSection";
import EventSection from "../../Components/EventArticle";
import EventsCovers from "../../Components/EventCovr";
import AwardsCovers from "../../Components/AwardCovers";
import AwardsSection from "../../Components/AwardsSection";
function RewardPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AwardsCovers />
      <AwardsSection />
      <CallSection />
      <Footer />
    </div>
  );
}

export default RewardPage;
