import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import "../Styles/Service.css";
import ModelTest from "./ModelTest";
import { AppContext } from "../Context/Provider";

const Services = () => {
  const { FetchHomeService, HomeServices } = useContext(AppContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  useEffect(() => {
    FetchHomeService();
  }, []);

  const handleCardClick = (service) => {
    if (service.title === "Taxi") {
      setSelectedService(service);
      setIsModalVisible(true);
    } else if (service.externalLink) {
      window.open(service.link, "_blank");
    } else {
      window.location.href = service.link;
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  console.log("HomeServices", HomeServices);
  return (
    <section className="bg-gray-100 py-10">
      <div
        className="container mx-auto text-center"
        style={{ paddingTop: "30px" }}
      >
        <h2 className="text-3xl font-bold font-poppins">Our Services</h2>
        {/* <p
          className="text-center mt-2 font-poppins"
          style={{ color: "#556987", fontWeight: 400 }}
        >
          With our integrated CRM, project management, collaboration, and
          invoicing capabilities, you can <br />
          manage every aspect of your business in one secure platform.
        </p> */}
        <div
          className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8"
          style={{ paddingRight: "7%", paddingLeft: "6.6%" }}
        >
          {HomeServices?.map((service, index) => (
            <div
              key={index}
              className="p-10 rounded text-left service-card hover-effect"
              style={{ cursor: "pointer" }}
              onClick={() => handleCardClick(service)}
            >
              <LazyLoad height={120} offset={100} once>
                <img
                  src={service.fileName}
                  height="120"
                  width="120"
                  alt="Icon"
                />
              </LazyLoad>
              <h3
                className="text-xl font-bold mt-5 font-poppins"
                style={{
                  color: "#090f4e",
                  fontWeight: 500,
                  letterSpacing: "1px",
                }}
              >
                {service.title}
              </h3>
              <p
                className="mt-4 text-gray-800 font-poppins"
                style={{ fontSize: "14px" }}
              >
                {service.description}
              </p>
              <Link
                to={service.externalLink ? "#" : service.link}
                className="mt-10 inline-block font-poppins font-bold"
                style={{ fontSize: "13px" }}
                onClick={(e) => {
                  if (service.externalLink) {
                    e.preventDefault();
                    window.open(service.link, "_blank");
                  }
                }}
              >
                Learn More
              </Link>
            </div>
          ))}
        </div>
        {isModalVisible && (
          <ModelTest
            setIsVisible={setIsModalVisible}
            isVisible={isModalVisible}
            onClose={closeModal}
          />
        )}
      </div>
    </section>
  );
};

export default Services;
