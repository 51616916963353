import React, { useEffect } from "react";
import RestayrantsCoverSection from "../../Components/RestayrantsCoverSection";
import AchieveSection from "../../Components/AchieveSection";
import SmartInsightsSection from "../../Components/SmartInsightsSection";
import RikaabFoodSection from "../../Components/RikaabFoodSection";
import FAQSection from "../../Components/SupportFAQSection";
import FAQSectionQuestions from "../../Components/SupportFAQSection 1";
import Footer from "../../Components/Footer";
import RikabFoodsSections from "../../Components/RikabFoodsSections";
import { useLocation } from "react-router-dom";
import GeneralQuestions from "../../Components/GeneralFAQ";
function Resturants() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { id } = location.state || {};
  console.log("Restaurants:", id);
  return (
    <div>
      <RestayrantsCoverSection />
      <AchieveSection />
      <SmartInsightsSection />
      <RikabFoodsSections />
      <RikaabFoodSection />
      {/* <FAQSection /> */}
      {/* <FAQSectionQuestions /> */}
      <GeneralQuestions id={id} />
      <Footer />
    </div>
  );
}

export default Resturants;
