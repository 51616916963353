import React from "react";

const RikaabFoodSection = () => {
  return (
    <div className="w-full py-12 px-0 bg-[#F2F6FF]">
      <div className="container mx-auto mt-12 mb-12">
        <div className="text-center">
          <h2 className="text-4xl font-bold">
            Starting with Rikaab Food is easy
          </h2>
          <p className="text-gray-600 mt-6">
            Tellus rutrum tellus pellentesque eu tincidunt tortor condimentum.
          </p>
        </div>
        <div className="flex flex-wrap justify-center md:ml-40 mb-12 mt-12">
          <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-12 h-12 bg-custom-green text-white rounded-full text-xl mr-4">
                1
              </div>
              <div className="flex-1">
                <h5 className="text-xl font-bold text-gray-800">
                  Sign a partnership agreement
                </h5>
                <p className="text-gray-600">
                  Proving that you meet the minimum age requirement
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-12 h-12 bg-white text-green-500 border-2 border-green-500 rounded-full text-xl mr-4">
                2
              </div>
              <div className="flex-1">
                <h5 className="text-xl font-bold text-gray-800">
                  Provide us with your menus and product photos
                </h5>
                <p className="text-gray-600">
                  We need your detailed menus and high-quality photos of your
                  products.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-12 h-12 bg-custom-green text-white rounded-full text-xl mr-4">
                3
              </div>
              <div className="flex-1">
                <h5 className="text-xl font-bold text-gray-800">
                  Complete the onboarding process
                </h5>
                <p className="text-gray-600">
                  Follow our onboarding steps to get started with Rikaab Food.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 px-4 mb-8 md:mb-0">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-12 h-12 bg-white text-green-500 border-2 border-green-500 rounded-full text-xl mr-4">
                4
              </div>
              <div className="flex-1">
                <h5 className="text-xl font-bold text-gray-800">
                  Start offering your products
                </h5>
                <p className="text-gray-600">
                  Begin selling your delicious products through our platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RikaabFoodSection;
