import React, { useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";
import TitleBox from "../images/Title_Box.jpg";
import { AppContext } from "../Context/Provider";

const AboutGallery = () => {
  const { FetchAboutService, AboutServiceData } = useContext(AppContext);

  useEffect(() => {
    FetchAboutService();
  }, []);

  const truncateData = (element) => {
    const words = element.split(" ");
    if (words.length > 50) {
      return words.slice(0, 50).join(" ") + " ...";
    }
    return element;
  };

  return (
    <>
      {AboutServiceData?.map((item, index) => (
        <section key={index} className="relative bg-white">
          <div className="relative">
            <LazyLoad height={681} offset={100}>
              <img
                src={item.fileName}
                alt={item.title || "Service Image"}
                className="w-full object-cover"
                style={{ height: "681px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/681"; // Fallback image
                }}
              />
            </LazyLoad>
            <div className="absolute inset-0 flex items-center justify-start bg-opacity-40">
              <div
                className="text-white bg-teal-800 px-4 md:px-24 object-cover w-full md:w-[778px] bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${TitleBox})`,
                  marginBottom: "-500px",
                }}
              >
                <div className="py-8 md:py-32 w-full md:w-[778px] h-auto md:h-[468px]">
                  <button
                    className="bg-indigo-200 text-[#122B47] py-2 px-4 md:py-4 md:px-8 rounded-full font-semibold text-center"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      lineHeight: "22.4px",
                    }}
                  >
                    OUR SERVICES
                  </button>

                  <h2
                    className="font-semibold mb-4 md:mb-6 font-poppins"
                    style={{
                      fontSize: "36px",
                      lineHeight: "50.4px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: truncateData(item.title),
                    }}
                  ></h2>

                  <p
                    className="font-normal font-poppins"
                    style={{
                      fontSize: "16px",
                      lineHeight: "28.8px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: truncateData(item.description),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default AboutGallery;
