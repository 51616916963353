import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include the necessary Quill styles
import axios from "axios";
import { AppContext } from "../../Context/Provider";
import toast from "react-hot-toast";

const EventEditor = () => {
  const { CreateEventsLists, FetchEvents, EventData } = useContext(AppContext);
  const [text, setText] = useState(""); // State to store only the text content
  const [images, setImages] = useState([]); // State to store all image files
  const { User } = useContext(AppContext);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    FetchEvents();
  }, []);

  useEffect(() => {
    if (EventData && EventData.length > 0) {
      const optionsArr = EventData.map((item) => ({
        label: item.title,
        value: item._id,
      }));
      setOptions(optionsArr);
    }
  }, [EventData]);

  const handleEditorChange = (content) => {
    setText(content); // Update the text content
  };

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          // Add the uploaded image along with its metadata to the state
          setImages((prevImages) => [
            ...prevImages,
            {
              file,
              title: file.name, // Use the file name as the title
              width: img.width,
              height: img.height,
            },
          ]);
          // Add the uploaded image to the editor content
          setText(
            (prevText) =>
              `${prevText}<img src="${reader.result}" alt="${file.name}" />`
          );
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      return toast.error("Please fill all fields");
    }
    setIsLoading(true);

    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const imgElements = doc.getElementsByTagName("img");

      // Replace base64 images with empty <img /> tags with dynamic attributes
      for (let i = 0; i < imgElements.length; i++) {
        const srcValue = imgElements[i].getAttribute("src");
        if (srcValue && srcValue.startsWith("data:image")) {
          // Find the corresponding image metadata
          const uploadedImage = images[i]; // Assuming the order is maintained

          // Create new empty img tag with dynamic attributes
          const newImgTag = `<img title="${uploadedImage.title}" width="${uploadedImage.width}" height="${uploadedImage.height}">`;
          imgElements[i].replaceWith(
            new DOMParser().parseFromString(newImgTag, "text/html").body
              .firstChild
          );
        }
      }

      // Get the modified HTML content
      const updatedContent = doc.body.innerHTML;

      const formData = new FormData();
      formData.append("postId", selectedOption);
      formData.append("userId", "6699231725417aef30885732");
      formData.append("htmlContent", updatedContent); // Use the modified content

      images.forEach((image) => {
        formData.append("Photos", image.file); // Append each original image file
      });

      await CreateEventsLists(formData);
      setText("");
      setImages([]);
      setSelectedOption("");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#F7F9FC",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        maxWidth: "100%",
        width: "100%",
        margin: "0 auto",
      }}
    >
      <ReactQuill
        value={text}
        onChange={handleEditorChange}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            // ["link"],
            // ["clean"],
          ],
        }}
        formats={[
          "header",
          "font",
          "list",
          "bullet",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "link",
          "image",
        ]}
        style={{
          backgroundColor: "#ffffff",
          color: "#333333",
          borderRadius: "8px",
          border: "1px solid #ddd",
          padding: "10px",
        }}
      />

      <div className="grid grid-cols-12 gap-4 mb-4">
        <div className="col-span-8">
          <label
            htmlFor="roles"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Select an option
          </label>
          <select
            id="roles"
            value={selectedOption}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" disabled>
              Choose an option
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={isLoading}
          onClick={handleSubmit}
          className="col-span-4 mt-6 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          {isLoading ? "Saving..." : "Submit Content"}
        </button>
      </div>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
    </div>
  );
};

export default EventEditor;
