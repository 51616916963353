import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AppContext } from "../../Context/Provider";
import { IoTrashOutline } from "react-icons/io5";
import { HiOutlinePencilSquare } from "react-icons/hi2";

function MyComponent() {
  const {
    CreateAboutChooseOurService,
    FetchAboutChooseOurService,
    ChooseOurservice,
    UpdateAboutChooseOurService,
    DeleteAboutChooseOurService,
    User,
  } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filtertext, setFiltertext] = useState("");
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [Headers, setHeaders] = useState("");
  const [SubHeaders, setSubHeaders] = useState("");
  const [error, setError] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [addedItems, setAddedItems] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    FetchAboutChooseOurService();
  }, []);

  const columns = [
    { name: "Title", selector: (row) => row.title, grow: 1 },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 2,
      cell: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.description}</div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleEdit(row)}
            className="text-custom-green hover:text-blue-700"
          >
            <HiOutlinePencilSquare />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            className="text-custom-red hover:text-custom-yellow"
          >
            <IoTrashOutline />
          </button>
        </div>
      ),
      button: true,
      grow: 0.5,
    },
  ];

  const customStyles = {
    rows: { style: { minHeight: "72px" } },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#f8f9fa",
        fontWeight: "bold",
      },
    },
    cells: { style: { paddingLeft: "8px", paddingRight: "8px" } },
  };

  const ClearData = () => {
    setSelectedFile(null);
    setTitle("");
    setDescription("");
    setError("");
    setCurrentId("");
    setEditMode(false);
    setAddedItems([]);
    setSubHeaders("");
    setHeaders("");

    setCount(0);
  };

  const filteredItems = useMemo(() => {
    return ChooseOurservice?.filter((item) => {
      return columns.some((column) => {
        const value = column.selector ? column.selector(item) : null;
        return (
          value &&
          value.toString().toLowerCase().includes(filtertext.toLowerCase())
        );
      });
    });
  }, [ChooseOurservice, filtertext]);

  const subHeaderComponentMemo = useMemo(
    () => (
      <div className="flex flex-col items-end space-y-2 w-full p-4">
        <button
          onClick={() => {
            setShowModal(true);
            ClearData();
          }}
          className="px-4 py-2 bg-custom-green text-white rounded"
        >
          + Add
        </button>
        <input
          type="text"
          onChange={(e) => setFiltertext(e.target.value)}
          value={filtertext}
          placeholder="Search here"
          className="border rounded p-2 w-full max-w-xs"
        />
      </div>
    ),
    [filtertext, resetPaginationToggle]
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(
        file.type
      )
    ) {
      setSelectedFile(file);
      setError(false);
    } else {
      setSelectedFile(null);
      setError(true);
      toast.error("Please select a valid image file (PNG, JPEG, JPG, SVG).");
    }
  };

  const handleAddOrUpdate = async () => {
    const formData = new FormData();
    formData.append("title", Title);
    formData.append("description", Description);
    formData.append("userId", User?.data?.id);
    formData.append("details", JSON.stringify(addedItems));
    if (selectedFile) {
      formData.append("Photo", selectedFile);
    }

    if (!Title || !Description || (!editMode && !selectedFile)) {
      setError("Please fill out all fields.");
      return;
    }

    setIsLoading(true);
    try {
      if (editMode) {
        await UpdateAboutChooseOurService(currentId, formData);
      } else {
        await CreateAboutChooseOurService(formData);
      }
    } catch (err) {
      // Handle any potential errors from the API calls
      console.error(err);
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      ClearData();
      setShowModal(false);
    }
  };

  const handleEdit = (row) => {
    setTitle(row.title);
    setDescription(row.description);
    setCurrentId(row._id);
    setEditMode(true);
    setShowModal(true);
    setAddedItems(row.details);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await DeleteAboutChooseOurService(deleteId);
      setDeleteId("");
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete the item:", error);
      // Optionally, show an error message to the user
    }
  };
  const addItem = () => {
    if (Headers && SubHeaders) {
      setAddedItems([
        ...addedItems,
        {
          header: Headers,
          subheader: SubHeaders,
        },
      ]);

      setHeaders("");
      setSubHeaders("");
    } else {
      toast.error("Please fill out all fields.");
    }
  };

  const removeItem = (index) => {
    setAddedItems(addedItems.filter((_, i) => i !== index));
  };

  const truncateTitles = (element) => {
    const words = element.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + " ...";
    }
    return element;
  };

  return (
    <div className="px-8 py-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        customStyles={customStyles}
        className="shadow-lg bg-white rounded"
      />

      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg max-h-screen overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-4">
              {editMode ? "Edit Services" : "Create Services"}
            </h2>
            {/* {error && <p className="text-red-500 mb-4">{error}</p>} */}

            {/* Modal Content */}
            <div className="space-y-15">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Title <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !Title && error ? "border-red-500" : ""
                  }`}
                  onChange={(e) => setTitle(e.target.value)}
                  value={Title}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Header <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !Headers && error ? "border-red-500" : ""
                  }`}
                  onChange={(e) => setHeaders(e.target.value)}
                  value={Headers}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Sub Header <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !SubHeaders && error ? "border-red-500" : ""
                  }`}
                  onChange={(e) => setSubHeaders(e.target.value)}
                  value={SubHeaders}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !Description && error ? "border-red-500" : ""
                  }`}
                  rows="4"
                  onChange={(e) => setDescription(e.target.value)}
                  value={Description}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Photo
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !selectedFile && error ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div className="space-y-4 p-2">
                <button
                  onClick={addItem}
                  className="px-4 py-2 bg-custom-green text-white rounded"
                >
                  + Add Item
                </button>
                {/* {error && <p className="text-red-500">{error}</p>} */}
              </div>
              <div className="space-y-4 p-4">
                {addedItems.map((item, index) => (
                  <div
                    key={index}
                    className="border p-2 rounded flex justify-between items-center"
                  >
                    <div>
                      <p className="font-semibold">
                        {truncateTitles(item.header)}
                      </p>
                      <p className="text-sm text-gray-600">
                        {truncateTitles(item.subheader)}
                      </p>
                    </div>
                    <button
                      onClick={() => removeItem(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <IoTrashOutline />
                    </button>
                  </div>
                ))}
              </div>

              {/* <div className="flex justify-end space-x-2">
                <button
                  onClick={() => {
                    setShowModal(false);
                    ClearData();
                  }}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddOrUpdate}
                  className="px-4 py-2 bg-custom-green text-white rounded"
                >
                  {editMode ? "Update" : "Create"}
                </button>
              </div> */}
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => {
                    setShowModal(false);
                    ClearData();
                  }}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddOrUpdate}
                  className={`px-4 py-2 rounded ${
                    isLoading ? "bg-gray-400" : "bg-custom-green"
                  } text-white`}
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : editMode ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete this item?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteId("");
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyComponent;
