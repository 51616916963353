import React from "react";

const requirements = [
  {
    id: 1,
    title: "Valid Photo ID",
    description: "Proving that you meet the minimum age requirement",
    bgColor: "bg-custom-green",
    textColor: "text-white",
  },
  {
    id: 2,
    title: "Upload your documents",
    description: "Android 9.0+ or iOS 12+, with a local number",
    bgColor: "bg-white",
    textColor: "text-custom-green",
    borderColor: "border-custom-green",
  },
  {
    id: 3,
    title: "Car with valid licence",
    description: "With a valid licence and insurance",
    bgColor: "bg-white",
    textColor: "text-custom-green",
    borderColor: "border-custom-green",
  },
];

const RequirementsSection2 = () => (
  <div className="container mx-auto my-12 py-10 px-4 md:px-32 flex flex-col items-center">
    <div className="text-center mb-12">
      <h2 className="text-2xl md:text-3xl font-bold">
        Rikaab Driver Requirements
      </h2>
      <p className="text-gray-600 text-base md:text-lg mt-3">
        Tellus rutrum tellus pellentesque eu tincidunt tortor condimentum.
      </p>
    </div>

    <div className="flex flex-wrap justify-center">
      {requirements.map(
        ({ id, title, description, bgColor, textColor, borderColor }) => (
          <div key={id} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="flex items-center justify-center">
              <div
                className={`flex items-center justify-center w-12 h-12 ${bgColor} ${textColor} rounded-full text-xl mr-4 ${
                  borderColor ? `border-2 ${borderColor}` : ""
                }`}
              >
                {id}
              </div>
              <div className="flex-1">
                <h5 className="text-lg md:text-xl font-bold text-gray-800">
                  {title}
                </h5>
                <p className="text-gray-600 text-sm md:text-base">
                  {description}
                </p>
              </div>
              {id !== 3 && (
                <div className="text-3xl text-gray-400 ml-4 hidden md:block">
                  &gt;
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  </div>
);

export default RequirementsSection2;
