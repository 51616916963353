import React, { useEffect } from "react";
import ServiceBenifits from "../../Components/ServiceBenifits";
import ServiceCover from "../../Components/ServiceCover";
import ServiceDriver from "../../Components/ServiceDriver";
import EMuraabaha from "../../Components/Emurabaxa";
import ServicesConvinience from "../../Components/ServicesConvinience";
import Ourservices from "../../Components/Ourservices";
import ServiceQuestions from "../../Components/ServiceQuestions";
import Footer from "../../Components/Footer";
function ServicesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <ServiceCover />
      <ServiceBenifits />
      <ServiceDriver />
      <EMuraabaha />
      <ServicesConvinience />
      <Ourservices />
      <ServiceQuestions />
      <Footer />
    </div>
  );
}

export default ServicesPage;
