import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GooglePlay from "../images/googleplay.png";
import AppStore from "../images/appstore.png";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const ServiceCover = () => {
  const { FetchServiceSlider, ServiceSliderData } = useContext(AppContext);

  useEffect(() => {
    FetchServiceSlider();
  }, []);

  const truncateTitles = (element) => {
    const words = element.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + " ...";
    }
    return element;
  };

  const truncateDescription = (element) => {
    const words = element.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + " ...";
    }
    return element;
  };

  return (
    <>
      {ServiceSliderData &&
        ServiceSliderData?.map((el) => (
          <div
            key={el._id}
            className="relative bg-cover bg-center mt-6"
            style={{
              backgroundImage: `url(${el.fileName})`,
              height: "532px",
              transition: "background-image .3s ease-in-out",
            }}
          >
            <div
              key={el._id}
              imageUrl={el.fileName} // Lazy load the background image
              height="532px"
              className="mt-6"
            >
              <div className="relative z-10 flex items-center justify-center h-full p-4">
                <div className="text-center text-white bg-opacity-75 rounded-lg items-center max-w-xl mx-auto mt-14">
                  <div className="flex justify-center">
                    <Link
                      to="#"
                      className="text-white py-2 rounded-md  flex items-center space-x-2 text-2xl md:text-4xl lg:text-6xl mt-20"
                    >
                      <span
                        style={{ fontWeight: 500, fontFamily: "Roboto Serif" }}
                        dangerouslySetInnerHTML={{
                          __html: truncateTitles(el.title),
                        }}
                      />
                    </Link>
                  </div>
                  <div className="flex justify-center mt-1">
                    <Link
                      to="#"
                      className="text-white py-2 rounded-md  flex items-center space-x-2 text-base md:text-xl lg:text-2xl"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: truncateDescription(el.description),
                        }}
                      />
                    </Link>
                  </div>
                  <div className="flex justify-center space-x-4 items-center mt-4 mb-6">
                    <Link to="https://play.google.com/store/apps/details?id=com.faras&pli=1">
                      <LazyLoad height={70} offset={100} once>
                        <img
                          className="w-24 md:w-40 lg:w-60 h-auto"
                          src={GooglePlay}
                          alt="Google Play"
                        />
                      </LazyLoad>
                    </Link>
                    <Link to="https://apps.apple.com/us/app/rikaab/id1636848011">
                      <LazyLoad height={70} offset={100} once>
                        <img
                          className="w-24 md:w-40 lg:w-60 h-auto"
                          src={AppStore}
                          alt="App Store"
                        />
                      </LazyLoad>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ServiceCover;
