import React from "react";

const RequirementsSection = () => {
  return (
    <div className="container my-12 py-10 md:mx-auto px-4">
      <div className="text-center mb-12">
        <h2 className="text-2xl md:text-3xl font-bold">
          Rikaab Deliveryman Requirements
        </h2>
        <p className="text-gray-600 text-base md:text-lg mt-3">
          Tellus rutrum tellus pellentesque eu tincidunt tortor condimentum.
        </p>
      </div>
      <div className="flex flex-wrap justify-center">
        <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
          <div className="flex items-center justify-start md:justify-center">
            <div className="flex items-center justify-center w-12 h-12 bg-custom-green text-white rounded-full text-xl mr-4">
              1
            </div>
            <div className="flex-1">
              <h5 className="text-lg md:text-xl font-bold text-gray-800">
                Valid Photo ID
              </h5>
              <p className="text-gray-600 text-sm md:text-base">
                Proving that you meet the <br />
                minimum age requirement
              </p>
            </div>
            <div className="hidden md:block text-3xl text-gray-400 ml-4">
              &gt;
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
          <div className="flex items-center justify-start md:justify-center">
            <div className="flex items-center justify-center w-12 h-12 bg-white text-custom-green border-2 border-custom-green rounded-full text-xl mr-4">
              2
            </div>
            <div className="flex-1">
              <h5 className="text-lg md:text-xl font-bold text-gray-800">
                SmartPhone
              </h5>
              <p className="text-gray-600 text-sm md:text-base">
                Android 9.0+ or iOS 12+, with <br />a local number
              </p>
            </div>
            <div className="hidden md:block text-3xl text-gray-400 ml-4">
              &gt;
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3 px-4">
          <div className="flex items-center justify-start md:justify-center">
            <div className="flex items-center justify-center w-12 h-12 bg-white text-custom-green border-2 border-custom-green rounded-full text-xl mr-4">
              3
            </div>
            <div className="flex-1">
              <h5 className="text-lg md:text-xl font-bold text-gray-800">
                Scooter, bike or car
              </h5>
              <p className="text-gray-600 text-sm md:text-base">
                With a valid license and <br />
                insurance
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementsSection;
