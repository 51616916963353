import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext, DecryptSession } from "../../Context/Provider";
// Adjust the import path as necessary

const ProtectedRoute = ({ element }) => {
  const { secretKey } = useContext(AppContext);
  const plainData = DecryptSession(secretKey, "userId");
  console.log("plainData", plainData);
  const token = plainData?.data?.token;

  if (!token) {
    // If no token, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If token exists, render the requested component
  return element;
};

export default ProtectedRoute;
