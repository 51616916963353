import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AppContext } from "../../Context/Provider";
import { IoTrashOutline } from "react-icons/io5";
import { HiOutlinePencilSquare } from "react-icons/hi2";

function HomeBecomeImage() {
  const {
    CreateHomeBecomePartImages,
    FetchHomeBecomePartImages,
    HomeBecomeImages,
    UpdateHomeBecomePartImages,
    DeleteHomeBecomePartImages,
    User,
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filtertext, setfiltertext] = useState("");
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [selectedPartImage, setSelectedPartImage] = useState(null);
  const [error, setError] = useState("");
  const [currentId, setCurrentId] = useState(""); // Corrected spelling
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editMode, setEditMode] = useState(false); // New state to track edit mode
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    FetchHomeBecomePartImages();
  }, []);

  const handleEdit = (row) => {
    console.log("Edit row: ", row);
    setTitle(row.title);
    setDescription(row.description);
    setCurrentId(row._id);

    // Reset file input
    setShowModal(true);
    setEditMode(true); // Enable edit mode
  };
  const handleDelete = (rowId) => {
    setDeleteId(rowId); // Set the ID to delete
    setShowDeleteModal(true); // Show delete modal
    console.log("deleteId", deleteId);
  };

  const handleConfirmDelete = async () => {
    try {
      await DeleteHomeBecomePartImages(deleteId);
      setDeleteId("");
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete the item:", error);
      // Optionally, show an error message to the user
    }
  };
  const columns = [
    // { name: "User ID", selector: (row) => row.userId },
    { name: "Title", selector: (row) => row.title, width: "auto" },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "auto",
      maxWidth: "500px",
      display: "flex",
      flexWrap: "wrap",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleEdit(row)}
            className="text-custom-green hover:text-blue-700"
          >
            <HiOutlinePencilSquare />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            className="text-custom-red hover:text-custom-yellow"
          >
            <IoTrashOutline />
          </button>
        </div>
      ),
      width: "150px",
    },
  ];

  const customStyles = {
    headCells: {
      style: { fontWeight: "bold" },
    },
    cells: {
      style: { padding: "8px" },
    },
  };

  const ClearData = () => {
    setTitle("");
    setDescription("");

    setError("");
    setCurrentId("");
    setEditMode(false); // Reset edit mode
  };

  const filteredItems = HomeBecomeImages?.filter((item) => {
    return columns.some((column) => {
      const value = column.selector ? column.selector(item) : null;
      return (
        value &&
        value.toString().toLowerCase().includes(filtertext.toLowerCase())
      );
    });
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-col items-end space-y-2 w-full p-4">
        <button
          onClick={() => {
            setShowModal(true);
            ClearData();
          }}
          className="px-4 py-2 bg-custom-green text-white rounded"
        >
          + Add
        </button>
        <input
          type="text"
          onChange={(e) => setfiltertext(e.target.value)}
          value={filtertext}
          placeholder="Search here"
          className="border rounded p-2 w-full max-w-xs"
        />
      </div>
    );
  }, [filtertext, resetPaginationToggle]);
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (
      file &&
      ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(
        file.type
      )
    ) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          if (type === "cover") {
            if (img.width === 1920 && img.height === 532) {
              setSelectedCoverImage(file);
              setError(false);
            } else {
              setSelectedCoverImage(null);
              setError(true);
              toast.error("Please select an image with dimensions 1920x532.");
            }
          } else if (type === "part") {
            // No size restriction for "part" image
            setSelectedPartImage(file);
            setError(false);
          }
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    } else {
      if (type === "cover") {
        setSelectedCoverImage(null);
      } else if (type === "part") {
        setSelectedPartImage(null);
      }
      setError(true);
      toast.error("Please select a valid image file (PNG, JPEG, JPG, SVG).");
    }
  };

  // const handleFileChange = (event, type) => {
  //   const file = event.target.files[0];
  //   if (
  //     file &&
  //     ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(
  //       file.type
  //     )
  //   ) {
  //     if (type === "cover") {
  //       setSelectedCoverImage(file);
  //     } else if (type === "part") {
  //       setSelectedPartImage(file);
  //     }
  //     setError(false);
  //   } else {
  //     if (type === "cover") {
  //       setSelectedCoverImage(null);
  //     } else if (type === "part") {
  //       setSelectedPartImage(null);
  //     }
  //     setError(true);
  //     toast.error("Please select a valid image file (PNG, JPEG, JPG, SVG).");
  //   }
  // };

  const handleAddOrUpdate = async () => {
    const formData = new FormData();
    formData.append("userId", User?.data?.id);
    formData.append("title", Title);
    formData.append("description", Description);

    if (selectedCoverImage) {
      formData.append("coverImage", selectedCoverImage);
    }
    if (selectedPartImage) {
      formData.append("partImage", selectedPartImage);
    }

    // Log the FormData contents
    for (let pair of formData.entries()) {
      if (pair[1] instanceof File) {
        console.log(
          `Field: ${pair[0]}, File name: ${pair[1].name}, File type: ${pair[1].type}, File size: ${pair[1].size}`
        );
      } else {
        console.log(`Field: ${pair[0]}, Value: ${pair[1]}`);
      }
    }

    if (!editMode && (!selectedCoverImage || !selectedPartImage)) {
      setError("Please provide both images.");
      return;
    }

    try {
      if (editMode) {
        await UpdateHomeBecomePartImages(currentId, formData);
      } else {
        await CreateHomeBecomePartImages(formData);
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred. Please try again.");
    } finally {
      ClearData();
      setShowModal(false);
    }
  };

  return (
    <div className="px-8 py-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        customStyles={customStyles}
        className="shadow-lg bg-white rounded"
      />

      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-xl">
            <h2 className="text-xl font-bold mb-4">
              {editMode ? "Edit" : "Add"} part of Rikaab
            </h2>
            <div className="grid grid-cols-1 gap-4 mb-4">
              <div className="col-span-12">
                <label className="block text-gray-700 mb-2">Title</label>
                <input
                  type="text"
                  value={Title}
                  onChange={(e) => setTitle(e.target.value)}
                  className={`w-full p-2 border rounded ${
                    !Title && error ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Title"
                />
              </div>
              <div className="col-span-12">
                <label className="block text-gray-700 mb-2">Description</label>
                <textarea
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={`w-full p-2 border rounded ${
                    !Description && error ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Description"
                  rows={4}
                />
              </div>

              <div className="col-span-12">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="cover_image_input"
                >
                  {editMode
                    ? "Change Cover Image (Optional)"
                    : "Upload Cover Image"}
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !selectedCoverImage && error ? "border-red-500" : ""
                  }`}
                  id="cover_image_input"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                  onChange={(e) => handleFileChange(e, "cover")}
                />
              </div>

              <div className="col-span-12">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="part_image_input"
                >
                  {editMode
                    ? "Change Part Image (Optional)"
                    : "Upload Part Image"}
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !selectedPartImage && error ? "border-red-500" : ""
                  }`}
                  id="part_image_input"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                  onChange={(e) => handleFileChange(e, "part")}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setShowModal(false);
                  ClearData();
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrUpdate}
                className={`px-4 py-2 rounded ${
                  isLoading ? "bg-gray-400" : "bg-custom-green"
                } text-white`}
                // disabled={isLoading}
              >
                {editMode ? "Update" : "Create"}
                {/* isLoading ? "Saving..." : */}
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl font-bold mb-4 text-red-400 text-center">
              Are you sure?
            </h2>

            <p className="text-gray-700 mb-4">
              Are you sure you want to delete this record?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteId("");
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Yes,Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeBecomeImage;
