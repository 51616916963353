import React from "react";
import RectangleImage from "../images/rectangle_192741.png";
import BoostIcon from "../images/boosticon2.png";
import EarnIcon from "../images/EarnIcon.png";
import SettingIcon from "../images/settingIcon.png";
import LazyLoad from "react-lazyload";

const DegDegSection = () => {
  return (
    <div className="container mx-auto my-16 px-4">
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="md:h-[592px] mb-8 md:mb-0">
          <img
            src={RectangleImage}
            alt="Delivery Man"
            className="rounded-lg shadow-lg w-full md:h-[592px] h-auto"
          />
        </div>

        <div className="md:pl-16 flex flex-col justify-center h-full">
          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg mr-4">
              <LazyLoad offset={100}>
                <img
                  src={BoostIcon}
                  alt="Boost your earnings"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Boost your earnings
              </h4>
              <p className="text-gray-700 text-sm md:text-lg">
                Our large rider community means more orders per day and higher
                earnings.
              </p>
            </div>
          </div>
          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg mr-4">
              <LazyLoad offset={100}>
                <img
                  src={EarnIcon}
                  alt="Earn more when it’s busy"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Earn more when it’s busy
              </h4>
              <p className="text-gray-700 text-sm md:text-lg">
                Maximize your earnings with our dynamic pricing model, so you
                get paid more during busy times.
              </p>
            </div>
          </div>
          <div className="my-4 flex items-start md:my-4">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg mr-4">
              <LazyLoad offset={100}>
                <img
                  src={SettingIcon}
                  alt="Get paid more with bonuses"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Get paid more with bonuses
              </h4>
              <p className="text-gray-700 text-sm md:text-lg">
                Customers who value your service can now tip you directly
                through the app!
              </p>
            </div>
          </div>
          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg mr-4">
              <LazyLoad offset={100}>
                <img
                  src={SettingIcon}
                  alt="Earn more tips"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">Earn more tips</h4>
              <p className="text-gray-700 text-sm md:text-lg">
                Earn more money for completing deliveries on weekends, during
                late hours, or on bad weather days.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DegDegSection;
