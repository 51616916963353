import React from "react";
import "../Styles/Service.css";
import google from "../images/googleplay.png";
import app from "../images/appstore.png";

const ModelTest = ({ isVisible, setIsVisible }) => {
  const closeModal = () => setIsVisible(false);

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={closeModal}
    >
      <div
        className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className="absolute top-4 right-4 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg p-2"
          onClick={closeModal}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <h2 className="text-2xl font-bold mb-2 text-center text-black">
          Download App
        </h2>
        <h6 className="text-center mb-6 text-black">
          Please Download Apps to continue
        </h6>
        <div className="flex justify-center space-x-4 items-center">
          <img
            className="w-32 h-10 cursor-pointer"
            src={google}
            onClick={() =>
              openLink(
                "https://play.google.com/store/apps/details?id=com.faras&pli=1"
              )
            }
            alt="Google Play Store"
          />
          <img
            className="w-32 h-10 cursor-pointer"
            src={app}
            onClick={() =>
              openLink("https://apps.apple.com/us/app/rikaab/id1636848011")
            }
            alt="App Store"
          />
        </div>
      </div>
    </div>
  );
};

export default ModelTest;
