import React from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import HeaderLogo from "../images/headerlogo.png"; // Adjust path as needed
import FooterQRCode from "../images/Footer_Qrcode.png"; // Adjust path as needed
import LazyLoad from "react-lazyload";

const Footer = () => {
  return (
    <footer
      className="text-white py-10 font-poppins"
      style={{ backgroundColor: "#054752" }}
    >
      <div className="container mx-auto px-6 md:px-20">
        {/* <div className="mb-8 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:ms-12">
            Let's Help Your <br className="hidden md:block" />
            Space Come To Life
          </h2>
          <Link
            to="#" // Adjust path as needed
            className="bg-[#42DE64] text-black text-sm px-6 md:px-8 py-2 rounded-full inline-block mt-2 md:ms-12"
          >
            Try Starlight For Free
          </Link>
        </div> */}
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8 md:gap-4 text-center md:text-left">
          <div className="col-span-2 md:col-span-1 flex flex-col items-center md:items-start">
            <LazyLoad height={532} offset={100}>
              <img
                src={HeaderLogo}
                alt="Rikaab Logo"
                className="mb-4"
                style={{ width: "150px" }}
              />

              <img
                src={FooterQRCode}
                alt="QR Code"
                className="mt-4"
                style={{ width: "100px" }}
              />
            </LazyLoad>
          </div>
          <div>
            <h3 className="text-base font-bold mb-2">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/delivery" className="text-gray-400 text-sm">
                  Courier
                </Link>
              </li>
              <li>
                <Link to="/deliverymantax" className="text-gray-400 text-sm">
                  Driver
                </Link>
              </li>
              <li>
                <Link to="/Resturants" className="text-gray-400 text-sm">
                  Restaurant Owner
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-base font-bold mb-2">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-400 text-sm">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/service" className="text-gray-400 text-sm">
                  Service
                </Link>
              </li>
              <li>
                <Link to="/About" className="text-gray-400 text-sm">
                  About
                </Link>
              </li>
              <li>
                <Link to="/Support" className="text-gray-400 text-sm">
                  Support
                </Link>
              </li>
              <li>
                <Link to="/Blogs" className="text-gray-400 text-sm">
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-base font-bold mb-2">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="https://user.rikaab.com/food"
                  className="text-gray-400 text-sm"
                >
                  Food
                </Link>
              </li>
              <li>
                <Link
                  to="https://user.rikaab.com/supermarket"
                  className="text-gray-400 text-sm"
                >
                  Supermarket
                </Link>
              </li>

              <li>
                <Link
                  to="https://user.rikaab.com/suuq"
                  className="text-gray-400 text-sm"
                >
                  Suuq
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex justify-center md:justify-start space-x-4 mt-4 md:mt-0">
            <Link
              to="https://www.facebook.com/Rikaab"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-4 py-3"
            >
              <i className="fab fa-facebook-f"></i>
            </Link>

            <Link
              to="https://www.instagram.com/rikaab_app?igsh=M29jZnFnb2psZmZr"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-4 py-3"
            >
              <i className="fab fa-instagram"></i>
            </Link>

            <Link
              to="https://www.linkedin.com/company/rikaabsuperapp/?viewAsMember=true"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-4 py-3"
            >
              <i className="fab fa-linkedin-in"></i>
            </Link>
            <Link
              to="https://x.com/Rikaabapp"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-4 py-3"
            >
              <i className="fab fa-twitter"></i>
            </Link>
          </div>
        </div>
        <div className="mt-10 pt-4">
          <hr className="opacity-70" />
          <p className="text-gray-400 text-center text-sm mt-4">
            &copy; 2024 Rikaab, All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
