import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";
import LoadingSpinner from "./Loading";

const GeneralQuestions = ({ id }) => {
  const [loading, setLoading] = useState(true); // Loading state
  const { FetchHomeFAQById, FAQDataById } = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await FetchHomeFAQById(id);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-8">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
          Frequently Asked Questions
        </h1>
        <p className="text-gray-600 mt-2">
          Can't find the answer here? Please contact us.
        </p>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="mt-8 grid gap-4 divide-y divide-neutral-200 md:mx-auto md:max-w-4xl">
          {FAQDataById?.map((faq, index) => (
            <div key={index} className="py-5">
              <details className="group">
                <summary className="flex cursor-pointer list-none items-center justify-between font-medium p-3 rounded-md shadow-sm">
                  <span>{faq.question}</span>
                  <span className="transition-transform duration-200 transform group-open:rotate-180">
                    <svg
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  </span>
                </summary>
                <div className="mt-3 text-neutral-600 px-3">
                  <p>{faq.answer}</p>
                </div>
              </details>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GeneralQuestions;
