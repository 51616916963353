import React from "react";
import { useMediaQuery } from "react-responsive";
const services = [
  {
    title: "One App for Everything",
    description: "Ride, Food, Shopping & More!",
  },
  {
    title: "Fast & Reliable",
    description: "Get What You Need, When You Need It",
  },
  {
    title: "Shop From Home, Delivered Fast",
    description: "Stay Comfortable, We'll Handle the Rest",
  },
];

const SubHeaderSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const c = isMobile ? { marginTop: "0px" } : { marginTop: "155px" };
  return (
    <section
      className="bg-service-section py-5"
      style={{ paddingRight: "15%", paddingLeft: "15%", ...c }} // Adjusted padding for alignment
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              style={{ backgroundColor: "#f0f5ff" }}
              className="bg-opacity-80 py-3 px-5 rounded shadow-md font-aeonik"
            >
              <h3
                className="text-xl font-bold text-left"
                style={{ fontWeight: 700, fontSize: "15px", marginLeft: "0" }} // Left-aligned with the logo
              >
                {service.title}
              </h3>
              <p
                className="text-gray-800 text-left"
                style={{ fontWeight: 300, fontSize: "15px", marginLeft: "0" }} // Left-aligned with the logo
              >
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubHeaderSection;
