import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import BlogHeader from "../../Components/BlogHeader";
import ArticleSection from "../../Components/BlogArticleSection";
import CallSection from "../../Components/BlogCallSection";
function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlogHeader />
      <ArticleSection />
      <CallSection />
      <Footer />
    </div>
  );
}

export default Blogs;
