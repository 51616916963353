import React, { useEffect } from "react";
import Services from "../../Components/Services";
import JoinUsSection from "../../Components/JoinSection";
import JoinSection from "../../Components/JoinUsSection";
import LearnAboutUsSection from "../../Components/LearnMore";
import StatisticsSection from "../../Components/StatisticsSection";
import Footer from "../../Components/Footer";
import Carousel from "../../Components/CoverCarousel";
import SubHeaderSection from "../../Components/SubHeader";
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Carousel />
      <SubHeaderSection />
      <Services />
      <JoinUsSection />
      <JoinSection />
      <LearnAboutUsSection />
      <StatisticsSection />
      <Footer />
    </div>
  );
}

export default Home;
