import React, { useEffect } from "react";
import AboutCover from "../../Components/AboutCover";
import ChooseService from "../../Components/ChooseServce";
import AboutGallery from "../../Components/AboutGalary";
import AboutServices from "../../Components/AboutService";
import MissionSection from "../../Components/AboutMissin";
import RikaabPartnerSection from "../../Components/AboutPartnerSection ";

import Footer from "../../Components/Footer";
import OurTeamSection from "../../Components/AboutOurTeamSection";
import ExpertTeam from "../../Components/ExpertTeam";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <DetailsLayout /> */}
      <AboutCover />
      <ChooseService />
      <AboutGallery />
      <AboutServices />
      <MissionSection />
      <RikaabPartnerSection />
      <OurTeamSection />
      <ExpertTeam />
      <Footer />
    </div>
  );
}

export default About;
