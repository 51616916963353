import React, { useState, useEffect, useContext } from "react";
import "../Styles/Tijaabo.css"; // Import the CSS file
import { AppContext } from "../Context/Provider";

const EMuraabaha = () => {
  const { FetchEmurabaha, MurabahaData } = useContext(AppContext);
  useEffect(() => {
    FetchEmurabaha();
  }, []);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [gradientPosition, setGradientPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e, index) => {
    setHoveredCardIndex(index); // Set the index of the hovered card
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setGradientPosition({ x, y });
  };

  const handleMouseLeave = () => {
    setHoveredCardIndex(null); // Reset the hovered card index when the mouse leaves
  };

  return (
    <section className="py-10">
      <div className="container mx-auto text-center px-6">
        <h2
          className="text-[36px] md:text-[36px]  mb-2 "
          style={{ fontFamily: "Plus Jakarta Sans" }}
        >
          E-Muraabaha (Buy Now, Pay Later E-Muraabaha)
        </h2>
        <p className="text-gray-600 text-base md:text-lg mb-8">
          Flexible Payment Solutions
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {MurabahaData?.map((feature, index) => (
            <div
              key={index}
              className=" bg-[#F2F6FF] text-gray-800 p-6 rounded-lg shadow-md"
              // style={
              //   hoveredCardIndex === index
              //     ? {
              //         background: `radial-gradient(circle at ${gradientPosition.x}% ${gradientPosition.y}%, #22C55E, transparent)`,
              //       }
              //     : {}
              // }
              // onMouseMove={(e) => handleMouseMove(e, index)}
              // onMouseLeave={handleMouseLeave}
            >
              <h3 className="font-semibold text-lg md:text-xl mb-2">
                {feature.title}
              </h3>
              <p className="text-base md:text-lg">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EMuraabaha;
