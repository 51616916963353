import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../Context/Provider";

const Sidebar = () => {
  const { LogoutUser } = useContext(AppContext);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  const toggleDropdown = (id) => {
    setDropdownOpenId(dropdownOpenId === id ? null : id);
  };

  return (
    <div className="flex">
      <button
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0 sm:block bg-gray-100 dark:bg-gray-800`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="#"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg
                  className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Dashboard</span>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-home"
                onClick={() => toggleDropdown("home")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                </svg>
                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Home
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-home"
                className={`${
                  dropdownOpenId === "home" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/homeslider"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header Slider
                  </Link>
                </li>
                <li>
                  <Link
                    to="Sidebar/homeservice"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/homeimages"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Become apart image
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/homecards"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Become apart cards
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/homelearnmore"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Learn about image
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/homestatistictitle"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Statistics Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/homestatistics"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Statistics
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/faq"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-about"
                onClick={() => toggleDropdown("about")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0C5.373 0 0 5.373 0 12c0 6.627 5.373 12 12 12s12-5.373 12-12c0-6.627-5.373-12-12-12zm0 17.933c-.691 0-1.25-.559-1.25-1.25 0-.691.559-1.25 1.25-1.25s1.25.559 1.25 1.25c0 .691-.559 1.25-1.25 1.25zm1.25-9.683v6.716h-2.5v-6.716h2.5zm-1.25-1.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
                </svg>
                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  About
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-about"
                className={`${
                  dropdownOpenId === "about" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/aboutslider"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/whyus"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Why us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/aboutservice"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Our services images
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/aboutpartner"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Partner
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/aboutbecomepartner"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Become Partner
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/aboutservicedetails"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Service Details
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/aboutourteam"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Expert team
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-support"
                onClick={() => toggleDropdown("support")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 1C5.925 1 1 5.925 1 12v2c0 1.103.897 2 2 2h1v-3H3v-1c0-5.514 4.486-10 10-10s10 4.486 10 10v1h-1v3h1c1.103 0 2-.897 2-2v-2c0-6.075-4.925-11-11-11zM8 16c-1.103 0-2 .897-2 2s.897 2 2 2h1v-1c0-.553.447-1 1-1s1 .447 1 1v1h2v-1c0-.553.447-1 1-1s1 .447 1 1v1h1c1.103 0 2-.897 2-2s-.897-2-2-2h-8z" />
                </svg>
                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Support
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-support"
                className={`${
                  dropdownOpenId === "support" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/suportSlider"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/supportservice"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Our services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/supportfaq"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-service"
                onClick={() => toggleDropdown("service")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z" />
                </svg>

                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Service
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-service"
                className={`${
                  dropdownOpenId === "service" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/serviceheader"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/servicecard"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Service Card
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/Emurabaha"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    E-murabaha
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/chooseService"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Choose Our Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/ServiceFaq"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-blog"
                onClick={() => toggleDropdown("blog")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M4 5h16v2H4V5zm0 4h16v2H4V9zm0 4h10v2H4v-2zm0 4h16v2H4v-2z" />
                </svg>

                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Blog
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-blog"
                className={`${
                  dropdownOpenId === "blog" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/blogslider"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/blogservice"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Blog Articles
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-support-details"
                onClick={() => toggleDropdown("support-details")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7c0 2.38-1.17 4.47-2.94 5.74l-.03.02-.01.01c-.55.37-.98.93-.98 1.61v2.16l2.9 2.9 1.41-1.41-2.17-2.17c.61-.95.97-2.05.97-3.24 0-5.52-4.48-10-10-10zm1 13h-2v-2h2v2zm0-4h-2V7h2v4z" />
                </svg>

                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Support Details
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-support-details"
                className={`${
                  dropdownOpenId === "support-details" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/supportsetailsheader"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/supportdetailfaq"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    FAQ
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/Sidebar/blogservice"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Blog Articles
                  </Link>
                </li> */}
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-blog-list"
                onClick={() => toggleDropdown("blog-list")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 2H9C7.9 2 7 2.9 7 4V20C7 21.1 7.9 22 9 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM19 20H9V4H19V20ZM11 6H17V8H11V6ZM11 10H17V12H11V10Z" />
                </svg>
                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Blog Details
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-blog-list"
                className={`${
                  dropdownOpenId === "blog-list" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/blogdetail"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/editor"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Posts
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/posts"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Delete Post
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-restaurans"
                onClick={() => toggleDropdown("restaurans")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.1 13.34L6.67 11.92C5.1 10.35 5.1 7.89 6.67 6.32L9.88 3.11L11.3 4.53L8.1 7.73L10.95 10.58L14.15 7.37L15.57 8.79L12.36 11.99C10.79 13.56 8.33 13.56 6.76 11.99L8.1 13.34ZM16 3H17V11H16V3ZM19 11C19.55 11 20 11.45 20 12V21C20 21.55 19.55 22 19 22H17C16.45 22 16 21.55 16 21V12C16 11.45 16.45 11 17 11H19ZM7 14C8.66 14 10 12.66 10 11H8C8 11.55 7.55 12 7 12S6 11.55 6 11H4C4 12.66 5.34 14 7 14ZM9 19H5C3.9 19 3 18.1 3 17V12C3 11.45 3.45 11 4 11H10C10.55 11 11 11.45 11 12V17C11 18.1 10.1 19 9 19Z" />
                </svg>

                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Restaurant
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-restaurans"
                className={`${
                  dropdownOpenId === "restaurans" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/restauransheader"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/restaurantservice"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Achievements
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/restaurantpartnerships"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Partnerships
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-event-h"
                onClick={() => toggleDropdown("event-h")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 4h-1V2h-2v2H8V2H6v2H5C3.9 4 3 4.9 3 6v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zM7 12h5v5H7z" />
                </svg>

                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Events
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-event"
                className={`${
                  dropdownOpenId === "event-h" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/eventcover"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/events"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Events
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/event-editor"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Event Details
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/event-header"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Event Header Details
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/delete-event"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Delete Event
                  </Link>
                </li>
              </ul>
            </li>
            {/* ------------Awards------------ */}
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-award-h"
                onClick={() => toggleDropdown("award-h")}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3V1h-6v1H6c-1.1 0-2 .9-2 2v3c0 3.9 2.8 7.2 6.5 7.9V18H9c-1.1 0-2 .9-2 2v1h10v-1c0-1.1-.9-2-2-2h-1.5v-3.1C17.2 14.2 20 10.9 20 7V4c0-1.1-.9-2-2-2zM6 5h3v2H6V5zm12 2h-3V5h3v2z" />
                </svg>

                <span className="flex-1 ms-3 text-left whitespace-nowrap">
                  Award
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="dropdown-award"
                className={`${
                  dropdownOpenId === "award-h" ? "" : "hidden"
                } py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/Sidebar/awardcover"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/awardcontent"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Awards
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/award-post"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Awards Detail
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/award-header"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Awards Detail Header
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Sidebar/delete-award"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    Delete Award
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mt-auto">
              <Link
                // to="/logout"
                className="flex items-center p-2 text-red-600 rounded-lg dark:text-red-500 hover:bg-red-100 dark:hover:bg-red-700 group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 4a1 1 0 011-1h6a1 1 0 110 2H7v10h6a1 1 0 110 2H7a1 1 0 01-1-1V4zM11 10a1 1 0 011 1v2a1 1 0 102 0v-2a1 1 0 011-1h3a1 1 0 000-2h-3a1 1 0 00-1-1v-2a1 1 0 10-2 0v2a1 1 0 01-1 1H8a1 1 0 000 2h3z"
                  />
                </svg>

                <span className="ms-3" onClick={LogoutUser}>
                  Logout
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
