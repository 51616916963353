import React from "react";
import contactImage from "../images/contactUS_image.png"; // Adjust the import path as needed

const ContactUsSection = () => {
  return (
    <div className="container mx-auto py-12">
      <div className="mt-12">
        <div className="bg-white shadow-lg rounded-lg flex p-6 md:mx-60">
          <div className="flex-shrink-0">
            <img
              src={contactImage}
              alt="Contact Image"
              className="w-62 h-32 md:w-80 md:h-56 rounded-lg object-cover"
            />
          </div>
          <div className="ml-6">
            <h2 className="text-2xl font-semibold text-gray-800 md:mt-10">
              Still need help?
            </h2>
            <p className="text-gray-600 mt-2">Feel free to contact us</p>
            <button className="mt-4 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
