import React from "react";
import taxCar from "../images/tax_car.png";
import boostIcon from "../images/boosticon2.png";
import earnIcon from "../images/EarnIcon.png";
import settingIcon from "../images/settingIcon.png";
import LazyLoad from "react-lazyload";

const DegDegSection2 = () => (
  <div className="container mx-auto my-16 px-4">
    <div className="flex flex-col md:flex-row justify-center items-center">
      <div className="md:w-1/2">
        <LazyLoad offset={100}>
          <img
            src={taxCar}
            alt="Delivery Man"
            className="rounded-lg shadow-lg w-full md:h-[592px] h-auto"
          />
        </LazyLoad>
      </div>

      {/* <div className="md:w-1/2 flex flex-col justify-center h-full mt-10 md:mt-0 md:pl-16">
        <div></div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={boostIcon}
                alt="Earn more when it’s busy"
                className="w-8 h-8"
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">
              Boost your earnings
            </h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Rikaab provides flexible driving opportunities, allowing you to
              earn more the longer you drive.
            </p>
          </div>
        </div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={earnIcon}
                alt="Earn more when it’s busy"
                className="w-8 h-8"
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">
              Earn more when it’s busy
            </h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Take advantage of busy periods with higher pay rates. The more
              deliveries you make when demand is high, the more money you take
              home.
            </p>
          </div>
        </div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={settingIcon}
                alt="Get paid more with bonuses"
                className="w-8 h-8"
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">
              Get paid more with bonuses
            </h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Get rewarded for driving during peak hours, weekends, or special
              events with higher bonus payouts.
            </p>
          </div>
        </div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img src={settingIcon} alt="Earn more tips" className="w-8 h-8" />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">Earn more tips</h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Our app makes it easy for customers to tip directly through the
              platform, allowing you to earn more for great service.
            </p>
          </div>
        </div>
      </div> */}
      <div className="md:w-1/2 flex flex-col justify-center h-full mt-10 md:mt-0 md:pl-16">
        <div></div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={boostIcon}
                alt="Earn more when it’s busy"
                className="w-8 h-8 object-contain"
                style={{ minWidth: "32px", minHeight: "32px" }}
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">
              Boost your earnings
            </h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Are you looking for a way to increase your income? Rikaab provides
              flexible earning opportunities for drivers. Whether you're looking
              to make some extra cash on the side or you're interested in a
              full-time role, Rikaab offers the flexibility to work on your
              terms. With our fair pay structure, you can be assured that your
              efforts are rewarded. The more you drive, the more you earn—it's
              that simple.
            </p>
          </div>
        </div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={earnIcon}
                alt="Earn more when it’s busy"
                className="w-8 h-8 object-contain"
                style={{ minWidth: "32px", minHeight: "32px" }}
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">
              Earn more when it’s busy
            </h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Take advantage of busy periods with higher pay rates. The more
              deliveries you make when demand is high, the more money you take
              home.
            </p>
          </div>
        </div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={settingIcon}
                alt="Get paid more with bonuses"
                className="w-8 h-8 object-contain"
                style={{ minWidth: "32px", minHeight: "32px" }}
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">
              Get paid more with bonuses
            </h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Get rewarded for driving during peak hours, weekends, or special
              events with higher bonus payouts.
            </p>
          </div>
        </div>
        <div className="my-4 flex items-start md:my-6">
          <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
            <LazyLoad offset={100}>
              <img
                src={settingIcon}
                alt="Earn more tips"
                className="w-8 h-8 object-contain"
                style={{ minWidth: "32px", minHeight: "32px" }}
              />
            </LazyLoad>
          </div>
          <div>
            <h4 className="font-bold text-xl md:text-2xl">Earn more tips</h4>
            <p className="text-gray-700 text-sm md:text-lg">
              Our app makes it easy for customers to tip directly through the
              platform, allowing you to earn more for great service.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DegDegSection2;
