import React, { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { AppContext } from "../Context/Provider";
import LoadingSpinner from "./Loading";

const RikaabPartnerSection = () => {
  const {
    FetchARikaabPartner,
    RikaabPartner,
    RikaabPartnerData,
    FetchABecomeRikaabPartnerById,
  } = useContext(AppContext);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    FetchARikaabPartner();
  }, []);

  useEffect(() => {
    if (RikaabPartner && RikaabPartner.length > 0) {
      // Automatically select the first partner when the component loads
      setSelectedPartner(RikaabPartner[0]._id);
    }
  }, [RikaabPartner]);

  useEffect(() => {
    if (selectedPartner) {
      setLoading(true);
      FetchABecomeRikaabPartnerById(selectedPartner).finally(() =>
        setLoading(false)
      );
    }
  }, [selectedPartner]);

  useEffect(() => {
    if (RikaabPartnerData && RikaabPartnerData.data) {
      setPartnerDetails(RikaabPartnerData.data[0]);
    } else {
      setPartnerDetails(null); // Reset if no data is available
    }
  }, [RikaabPartnerData]);

  const handleSelect = async (partnerId) => {
    await setSelectedPartner(partnerId);
  };

  return (
    <section className="bg-[#E0FFEB] p-8">
      <h1 className="text-center text-4xl font-bold mb-4 text-[#1C2752] mt-12 font-['Mulish']">
        How To Be Rikaab Partner
      </h1>
      <p className="text-center text-gray-600 mb-8 font-['Inter']">
        where we believe in building strong, mutually beneficial relationships{" "}
        that drive growth and success for <br /> everyone involved. At Rikaab,
        we’re not just looking for collaborators; we’re looking for partners who{" "}
        <br />
        share our vision of transforming transportation and delivery services
        across Somalia
      </p>

      {/* Partner Options */}
      <div className="flex flex-wrap justify-center space-y-4 md:space-y-0 md:space-x-4">
        {RikaabPartner?.map((partner) => (
          <Link
            key={partner._id}
            onClick={() => handleSelect(partner._id)}
            className={`${
              selectedPartner === partner._id ? "bg-white" : "bg-[#E0FFEB]"
            } flex flex-col md:flex-row items-center space-x-2 px-8 py-4 border border-black w-full md:w-auto text-center hover:bg-[#ffffff] transition-all duration-300`}
            style={{ width: "260px", height: "100px", padding: "10px" }} // Added padding here
          >
            <LazyLoad height={532} offset={100}>
              <img
                src={partner.fileName}
                alt={partner.name}
                className="w-18 h-18"
              />
            </LazyLoad>
            <span className="text-black font-bold font-['Inter']">
              {partner.name}
            </span>
          </Link>
        ))}
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        RikaabPartnerData &&
        RikaabPartnerData.map((data) => (
          <section
            key={data._id}
            className="section-with-z-index py-10 md:py-20 flex justify-center items-center"
            style={{ marginBottom: "-100px" }}
          >
            <div className="max-w-6xl w-full flex flex-col items-center">
              <div className="bg-white flex flex-wrap md:space-x-12 items-center w-full py-10 md:py-20 px-5 md:px-10 shadow-md space-y-6 md:space-y-0">
                <div className="w-full md:w-1/2 lg:w-2/4">
                  <div id="content-container" className="relative space-y-4">
                    <div id="mission-content" className="space-y-4">
                      <h2 className="text-2xl md:text-3xl font-bold text-blue-900 font-['Mulish']">
                        {data.title}
                      </h2>
                      <p className="text-gray-600 text-lg font-['Inter']">
                        {data.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-6 w-full h-full">
                    <Link to={data.link || "#"}>
                      <button className="bg-[#054752] text-white px-4 py-3 md:px-6 md:py-4 text-md rounded-md font-['Inter']">
                        READ MORE
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="w-full md:w-1/2 lg:w-2/5 mb-6 md:mb-0 relative">
                  <LazyLoad height={532} offset={100}>
                    <img
                      src={data.fileName}
                      alt="Partner"
                      className="w-full h-auto"
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </section>
        ))
      )}
    </section>
  );
};

export default RikaabPartnerSection;
