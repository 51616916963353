import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GooglePlay from "../images/googleplay.png";
import AppStore from "../images/appstore.png";
import "../Styles/About.css";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";
const AboutCover = () => {
  const { FetchAboutSlider, AboutSliderData } = useContext(AppContext);

  useEffect(() => {
    FetchAboutSlider();
  }, []);
  const truncateTitles = (element) => {
    const words = element.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + " ...";
    }
    return element;
  };
  const truncateDescription = (element) => {
    const words = element.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + " ...";
    }
    return element;
  };
  console.log(AboutSliderData);
  return (
    <>
      {AboutSliderData?.map((el) => (
        <div
          className="relative bg-cover bg-center mt-6"
          style={{
            backgroundImage: `url(${el.fileName})`,
            marginTop: "10px",
            height: "532px",
            transition: "background-image .5s ease-in-out",
          }}
        >
          <div className="relative z-10 flex items-center justify-center h-[532px] p-4 mr-0 md:mr-66">
            <div className="text-center text-white bg-opacity-75 rounded-lg items-center">
              <div className="flex justify-center">
                <Link
                  to="#"
                  className="text-white py-2 rounded-md flex items-center text space-x-2 text-2xl md:text-6xl mt-20 ms-5"
                >
                  <span
                    style={{ fontWeight: 700 }}
                    dangerouslySetInnerHTML={{
                      __html: truncateTitles(el.title),
                    }}
                  ></span>
                </Link>
              </div>
              <div className="flex justify-center mt-1">
                <Link
                  to="#"
                  className="text-white py-2 rounded-md flex items-center space-x-2 md:text-2xl text-1xl"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: truncateDescription(el.description),
                    }}
                  ></span>
                </Link>
              </div>
              <div className="bg-blue flex justify-center space-x-4 items-center mt-4 mb-6">
                <Link to="https://play.google.com/store/apps/details?id=com.faras&pli=1">
                  <LazyLoad height={70} offset={100} once>
                    <img
                      className="w-32 md:w-60 h-70 md:h-30"
                      src={GooglePlay}
                      alt="Google Play"
                    />
                  </LazyLoad>
                </Link>
                <Link to="https://apps.apple.com/us/app/rikaab/id1636848011">
                  <LazyLoad height={70} offset={100} once>
                    <img
                      className="w-32 md:w-60 h-70 md:h-30"
                      src={AppStore}
                      alt="App Store"
                    />
                  </LazyLoad>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AboutCover;
