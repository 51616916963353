import React from "react";
import MoneyVector from "../images/moneyVector.png";
import DeliveryManVector from "../images/deliveryManvector.png";
import DeliveryManVector2 from "../images/deliveryman_vector.png";
import LineImage from "../images/LineImage.png";
import LazyLoad from "react-lazyload";

const benefits = [
  {
    id: 1,
    image: MoneyVector,
    alt: "Weekly payouts",
    title: "Weekly payouts",
    description:
      "Aliquam erat volutpat. Integer malesuada turpis in ligula suscipit. Maecenas ultrices.",
  },
  {
    id: 2,
    image: DeliveryManVector,
    alt: "Flexible schedule",
    title: "Flexible schedule",
    description:
      "Aliquam erat volutpat. Integer malesuada turpis in ligula suscipit. Maecenas ultrices.",
  },
  {
    id: 3,
    image: DeliveryManVector2,
    alt: "Delivery support 24/7",
    title: "Delivery support 24/7",
    description:
      "Aliquam erat volutpat. Integer malesuada turpis in ligula suscipit. Maecenas ultrices.",
  },
];

const DeliveryBenefitsSection = () => {
  return (
    <div className="container my-12 px-4 mx-auto">
      <div className="text-center mb-12">
        <h2 className="text-2xl md:text-3xl font-bold">
          Rikaab Delivery Benefits
        </h2>
        <p className="text-gray-600 text-sm md:text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et
        </p>
      </div>
      <div className="flex flex-wrap text-center justify-center items-center">
        {benefits.map((benefit, index) => (
          <React.Fragment key={benefit.id}>
            <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-8">
              <LazyLoad offset={100}>
                <img
                  src={benefit.image}
                  alt={benefit.alt}
                  className="mx-auto mb-4"
                  style={{ width: "90px" }}
                />
              </LazyLoad>
              <h4 className="text-lg md:text-xl font-bold">{benefit.title}</h4>
              <p className="text-gray-600 text-sm md:text-base">
                {benefit.description}
              </p>
            </div>
            {(index + 1) % 3 !== 0 && index < benefits.length - 1 && (
              <div className="hidden md:flex w-auto justify-center items-center">
                <LazyLoad height={200} offset={100}>
                  <img
                    src={LineImage}
                    alt="Arrow"
                    className="mx-auto"
                    style={{ width: "100px" }}
                  />
                </LazyLoad>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default DeliveryBenefitsSection;
