import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

import { AppContext } from "../Context/Provider";

const BlogHeader = () => {
  const { FetchBlogSlider, BlogSlider } = useContext(AppContext);

  useEffect(() => {
    FetchBlogSlider();
  }, []);

  // console.log("hello......");
  return (
    <>
      {BlogSlider?.map((el) => (
        <div
          className="relative bg-cover bg-center mt-6"
          style={{
            backgroundImage: `url(${el.fileName})`, // Apply the background image from state
            marginTop: "10px",
            height: "532px",
            transition: "background-image 1s ease-in-out", // Optional: Add transition for a smoother effect
          }}
        >
          <div className="relative z-10 flex items-center justify-center h-full p-4 mr-0 md:mr-66">
            <div className="text-center text-white px-4 max-w-md bg-opacity-75 rounded-lg">
              <div className="flex justify-center md:justify-start space-x-4">
                <Link
                  to="#" // Replace with the actual path
                  className="text-white py-2 px-4 rounded-md  flex items-center text space-x-2 text-2xl md:text-4xl mt-40 ms-5"
                >
                  <span style={{ fontWeight: 700 }}>Blog</span>
                </Link>
              </div>
              <div className="flex justify-center md:justify-start space-x-4 mt-1">
                <Link
                  to="/" // Replace with the actual path
                  className="text-white py-2 px-4 rounded-md  flex items-center space-x-2 md:text-2xl text-1xl"
                >
                  <span>Home &gt; Blog</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlogHeader;
