import React from "react";
import LazyLoad from "react-lazyload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import locationDeliveryMan from "../images/tax_location.png";

const steps = [
  {
    id: 1,
    title: "Sign Up",
    description:
      "Download the Rikaab driver app from the App Store or Google Play. Sign up by providing your personal details, driver’s license, and vehicle information. Once your account is approved, you’re ready to start accepting deliveries.",
  },
  {
    id: 2,
    title: "Receive Delivery Requests",
    description:
      "When you’re online, the app will notify you of available delivery requests in your area. You’ll see details like the pickup location, drop-off location, and estimated earnings before accepting a job.",
  },
  {
    id: 3,
    title: "Accept and Navigate",
    description:
      "Once you accept a delivery request, the app provides step-by-step navigation to the pickup location. The app integrates with GPS to guide you efficiently to your destination.",
  },
  {
    id: 4,
    title: "Get Paid",
    description:
      "After completing the delivery, your earnings, including any tips, are automatically calculated and added to your account. You can track your earnings in real-time within the app and choose how and when you want to cash out.",
  },
];

const HowItWorksSection2 = () => (
  <div className="bg-[#f0f5ff] py-12 mt-24">
    <div className="container mx-auto px-4">
      <h2 className="text-2xl md:text-3xl font-bold text-center mb-12">
        How the Rikaab driver app works
      </h2>
      <div className="flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/4 mb-8 lg:mb-0">
          <LazyLoad offset={100}>
            <img
              src={locationDeliveryMan}
              alt="App Interface"
              className="mx-auto lg:mr-8"
              style={{ height: "560.22px" }}
            />
          </LazyLoad>
        </div>
        <div className="lg:w-3/4 grid grid-cols-1 sm:grid-cols-2 gap-8">
          {steps.map(({ id, title, description }) => (
            <div key={id} className="flex items-start">
              <div className="w-12 h-12 bg-custom-green text-white flex items-center justify-center rounded-full mr-4">
                <FontAwesomeIcon icon={faCheck} className="text-xl p-2" />
              </div>
              <div>
                <h4 className="text-lg md:text-xl font-bold">{title}</h4>
                <p className="text-gray-700 text-sm md:text-base">
                  {description.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default HowItWorksSection2;
