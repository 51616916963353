import React, { useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

// Import images dynamically
import RectangleImage from "../images/rectangle_19274.png";
import Group1Image from "../images/Group 48095474.png";
import Group2Image from "../images/Group 48095515.png";
import Group3Image from "../images/Group 48095516.png";
import Group4Image from "../images/Group 48095517.png";

const SmartInsightsSection = () => {
  const { FetchRestaurantPartnership, RestaurantPartnershipData } =
    useContext(AppContext);

  useEffect(() => {
    FetchRestaurantPartnership();
  }, []);

  // Data for the section items
  const insightsData = [
    {
      id: 1,
      title: "Unique users",
      description:
        "The number of users who made their first order from your restaurant during the week.",
      image: Group1Image,
    },
    {
      id: 2,
      title: "Average order value",
      description:
        "Better understand your customers’ average pre-discount spend.",
      image: Group2Image,
    },
    {
      id: 3,
      title: "Revenue",
      description:
        "Total order value before discounts, including VAT and excluding delivery fees.",
      image: Group3Image,
    },
    {
      id: 4,
      title: "Conversion",
      description:
        "The percentage of customers who placed an order after opening your restaurant profile in the app.",
      image: Group4Image,
    },
  ];

  return (
    <div className="container mx-auto px-4 md:px-6 bg-white">
      <div className="relative my-8 p-8 flex flex-col items-center">
        <div className="mb-4 text-center font-poppins font-semibold text-2xl md:text-3xl text-[#2A3342]">
          Smart insights for smart businesses
        </div>
        <div className="mb-12 text-center font-nunito font-normal text-base md:text-lg text-[#676673]">
          Track your key performance indicators and make data-driven decisions
          with Bolt Food.
        </div>
        <div className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 mb-8">
          <div
            className="bg-cover bg-center bg-no-repeat w-full md:w-[602px] h-[18rem] md:h-[37rem] rounded-lg"
            style={{ backgroundImage: `url(${RectangleImage})` }}
          ></div>
          <div className="flex flex-col items-center lg:items-start space-y-8">
            {insightsData.map((insight) => (
              <div
                key={insight.id}
                className="flex items-start space-x-4 w-full md:w-auto"
              >
                <div className="shadow-lg rounded-lg bg-white p-2 flex items-center justify-center w-12 h-12 md:w-16 md:h-16">
                  <LazyLoad offset={100}>
                    <img src={insight.image} alt={insight.title} />
                  </LazyLoad>
                </div>
                <div className="flex flex-col">
                  <div className="text-lg md:text-xl font-poppins font-semibold text-[#333333]">
                    {insight.title}
                  </div>
                  <div className="text-sm md:text-base font-roboto font-medium text-[#999999] md:w-[443px]">
                    {insight.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartInsightsSection;
