import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import HeroDeliveryImage from "../images/heros.jpg"; // Ensure the image path is correct
import GooglePlayImage from "../images/googleplay.png";
import AppStoreImage from "../images/appstore.png";
import LazyLoad from "react-lazyload";
const DeliveryCoverSection2 = () => {
  // const [backgroundImage, setBackgroundImage] = useState(""); // State for background image

  // useEffect(() => {
  //   // Load the background image after component mounts
  //   const loadBackgroundImage = () => {
  //     setBackgroundImage(`url(${HeroDeliveryImage})`);
  //   };

  //   loadBackgroundImage();
  // }, []);
  return (
    <div
      className="relative bg-cover bg-center mt-6"
      style={{
        backgroundImage: `url(${HeroDeliveryImage})`,
        transition: "background-image 1s ease-in-out",
        marginTop: "10px",
        height: "532px",
      }}
    >
      <div className="absolute inset-0 h-full w-full"></div>
      <div className="relative z-10 flex items-center justify-center h-[532px] p-4 mr-0 md:mr-66">
        <div className="text-center text-white rounded-lg items-center">
          <div className="flex justify-center">
            <Link
              to="#" // Replace href with to for Link component
              className="text-white py-2 rounded-md  flex items-center text space-x-2 text-2xl md:text-6xl mt-20 ms-5"
            >
              <span style={{ fontWeight: 700 }}>
                Make money driving with Rikaab
              </span>
            </Link>
          </div>
          <div className="flex justify-center mt-1">
            <Link
              to="#" // Replace href with to for Link component
              className="text-white py-2 rounded-md  flex items-center space-x-2 md:text-2xl text-1xl"
            >
              <span>
                Become a Rikaab deliveryman, set your schedule and earn money by
                driving!
              </span>
            </Link>
          </div>
          <div className="bg-blue flex justify-center space-x-4 items-center mt-24 mb-6">
            <Link to="https://play.google.com/store/apps/details?id=com.faras&pli=1">
              <LazyLoad offset={100}>
                {" "}
                <img
                  className="w-32 md:w-60 h-50 md:h-30"
                  src={GooglePlayImage}
                  alt="Google Play Store"
                />
              </LazyLoad>
            </Link>
            <Link to="https://apps.apple.com/us/app/rikaab/id1636848011">
              <LazyLoad offset={100}>
                <img
                  className="w-32 md:w-60 h-50 md:h-30"
                  src={AppStoreImage}
                  alt="App Store"
                />
              </LazyLoad>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryCoverSection2;
