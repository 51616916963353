import React, { useState } from "react";

const SearchSection = () => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearchClick = () => {
    // Implement search functionality here
    console.log("Search query:", query);
  };

  return (
    <div className="container mx-auto py-12">
      <div className="flex flex-col items-center justify-center mt-16">
        <h1 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">
          How can we help?
        </h1>
        <div className="relative w-full max-w-lg px-4 sm:max-w-2xl md:max-w-3xl">
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            className="w-full px-6 py-3 text-gray-800 bg-white border border-green-200 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Type your question"
          />
          <button
            onClick={handleSearchClick}
            className="absolute inset-y-0 right-0 flex items-center pr-4 text-green-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 me-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-4.35-4.35m1.4-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
