import React, { useState, useEffect, useContext } from "react";
import { IoTrashOutline } from "react-icons/io5";
import "../../Styles/EditorStyle.css";
import { AppContext } from "../../Context/Provider";
const FetchEditor = ({ id }) => {
  const { BlogLists, FetchBlogLists, DeletePostsContents } =
    useContext(AppContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  // sessionStorage.setItem("postId", id);
  useEffect(() => {
    FetchBlogLists();
  }, []); // Empty dependency array ensures this runs only once on mount

  const updatedPosts = BlogLists?.map((post) => {
    let updatedHtmlContent = post.htmlContent;

    // Replace empty <img> tags with actual image URLs and dimensions
    post.images.forEach((image) => {
      const imgTag = new RegExp(
        `<img[^>]*title=["']${image.originalFileName}["'][^>]*>`,
        "g"
      );
      updatedHtmlContent = updatedHtmlContent.replace(
        imgTag,
        `<img src="${image.fileName}" title="${
          image.originalFileName
        }" width="${image.width || "auto"}" height="${image.height || "auto"}">`
      );
    });

    return {
      ...post,
      htmlContent: updatedHtmlContent,
    };
  });
  const handleDelete = (rowId) => {
    setDeleteId(rowId); // Set the ID to delete
    setShowDeleteModal(true); // Show delete modal
    console.log("deleteId", rowId);
  };

  const handleConfirmDelete = async () => {
    try {
      await DeletePostsContents(deleteId);
      setDeleteId("");
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete the item:", error);
      // Optionally, show an error message to the user
    }
  };
  return (
    <div className="bg-white p-6 space-y-4">
      <div className="max-w-2xl mx-auto p-4 bg-white">
        {updatedPosts.map((post) => (
          <div
            key={post._id}
            className="mb-10 p-6 bg-white rounded-lg shadow-md"
          >
            <div
              className="content mb-4 leading-relaxed text-gray-800 text-base"
              style={{
                wordWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: post.htmlContent }}
            />
            <div className="text-xs text-gray-500">
              <p>
                Posted on: {new Date(post.createdAt).toLocaleDateString()} at{" "}
                {post.currentHour}
              </p>
            </div>
            <div className="flex justify-end items-center text-xs text-gray-500">
              <button
                onClick={() => handleDelete(post._id)}
                className="text-custom-red hover:text-custom-yellow"
              >
                <IoTrashOutline size={24} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl font-bold mb-4 text-red-400 text-center">
              Are you sure?
            </h2>

            <p className="text-gray-700 mb-4">
              Are you sure you want to delete this post?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteId("");
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Yes,Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FetchEditor;
