import React from "react";

const OurTeamSection = () => {
  return (
    <section
      className="bg-white relative max-w-8xl"
      style={{ overflowX: "hidden" }}
    >
      <div className="container mx-auto px-0 md:px-0 py-40">
        <div className="text-center px-20">
          <button className="bg-[#D3DBF8] text-black px-12 py-4 text-1.5xl rounded-full font-poppins font-bold">
            Our Team
          </button>
          <h2 className="text-4xl font-poppins font-bold mt-6">
            Meet Our Expert Team
          </h2>
        </div>
      </div>
    </section>
  );
};

export default OurTeamSection;
