import React from "react";
import BlogCover from "../images/Blog_Cover.png";

const HelpSection = () => (
  <div
    className="p-8 bg-black rounded-lg shadow space-y-2 bg-cover bg-center text-white"
    style={{ backgroundImage: `url(${BlogCover})` }}
  >
    <p className="text-2xl font-bold">Need For Some Help?</p>
    <p>Want to be a part of Rikaab? Get in touch with us</p>
    <div className="flex p-2 rounded-lg">
      <div className="flex items-center">
        <div className="rounded-[0.6rem] bg-[#D9D9D9] m-[0_1.2rem_0_0] w-[3rem] h-[3rem]"></div>
      </div>
      <div className="flex flex-col space-x-2">
        <p className="text-2xl font-bold">Call for Booking</p>
        {/* <p className="text-lg">(+252) 618344570</p> */}
        <p className="text-lg">7000</p>
      </div>
    </div>
  </div>
);

export default HelpSection;
