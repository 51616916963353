import React, { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazyload"; // Import LazyLoad
import { useNavigate } from "react-router-dom";
import { AppContext } from "../Context/Provider";

const AwardsSection = () => {
  const { FetchAwardsContent, AwardsContentData } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    FetchAwardsContent();
  }, []);
  return (
    <div className="container mx-auto py-8">
      <div className="px-8 mb-8">
        {/* <h2 className="text-xl font-bold">Our News</h2> */}
        <h2 className="text-3xl font-black">Awards and Accreditation</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {AwardsContentData?.map((article) => (
          <div
            key={article.id}
            className="bg-white rounded-lg p-4 relative cursor-pointer"
            onClick={() => {
              navigate(article.link, { state: { id: article._id } });
              sessionStorage.setItem("postId", article._id);
            }}
          >
            <LazyLoad height={234} offset={100}>
              {" "}
              {/* Add LazyLoad wrapper */}
              <img
                src={article.fileName}
                alt={`Article ${article.id}`}
                className="w-full object-cover rounded-lg mb-4"
                style={{ height: "234px" }}
              />
            </LazyLoad>
            {/* <button className="bg-[#26AA49] text-xs text-white font-bold py-1 px-2 rounded absolute top-10 left-8">
              Booking
            </button> */}
            <h3
              className="text-lg font-semibold mb-2"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
              }}
            >
              {article.title}
            </h3>
            <p
              className="text-gray-600 text-sm"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              {article.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AwardsSection;
