import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AppContext } from "../../Context/Provider";
import { IoTrashOutline } from "react-icons/io5";
import { HiOutlinePencilSquare } from "react-icons/hi2";

function HomeStatistics() {
  const {
    CreateHomeStatistics,
    FetchHomeStatistics,
    HomeStatisticsData,
    UpdateHomeStatistics,
    DeleteHomeStatistics,
    User,
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filtertext, setfiltertext] = useState("");
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState(""); // Corrected variable name
  const [error, setError] = useState("");
  const [currentId, setCurrentId] = useState(""); // Corrected spelling
  const [selectedFile, setSelectedFile] = useState(null);
  const [editMode, setEditMode] = useState(false); // New state to track edit mode
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Count, setCount] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [Name, setName] = useState("");
  useEffect(() => {
    FetchHomeStatistics();
  }, []);

  const handleEdit = (row) => {
    console.log("Edit row: ", row);
    setTitle(row.title);
    setCurrentId(row._id);
    setDescription(row.description);
    setCount(row.count);
    setName(row.name);
    setSelectedFile(null); // Reset file input
    setShowModal(true);
    setEditMode(true); // Enable edit mode
  };
  const handleDelete = (rowId) => {
    setDeleteId(rowId); // Set the ID to delete
    setShowDeleteModal(true); // Show delete modal
    console.log("deleteId", deleteId);
  };

  const handleConfirmDelete = async () => {
    try {
      await DeleteHomeStatistics(deleteId);
      setDeleteId("");
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete the item:", error);
      // Optionally, show an error message to the user
    }
  };
  const columns = [
    // { name: "Title", selector: (row) => row.title, grow: 1 },
    { name: "Name", selector: (row) => row.name, grow: 1 },
    {
      name: "Count",
      selector: (row) => row.count,
      grow: 1,
      format: (row) => row.count.toLocaleString(),
    },
    // {
    //   name: "Description",
    //   selector: (row) => row.description,
    //   grow: 2,
    //   cell: (row) => (
    //     <div style={{ whiteSpace: "normal" }}>{row.description}</div>
    //   ),
    // },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleEdit(row)}
            className="text-custom-green hover:text-blue-700"
          >
            <HiOutlinePencilSquare />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            className="text-custom-red hover:text-custom-yellow"
          >
            <IoTrashOutline />
          </button>
        </div>
      ),
      button: true,
      grow: 0.5,
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#f8f9fa",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const ClearData = () => {
    setSelectedFile(null);
    setTitle("");
    setDescription("");
    setError("");
    setCurrentId("");
    setName("");
    setEditMode(false); // Reset edit mode
    setCount(""); // Reset edit mode
  };

  const filteredItems = HomeStatisticsData?.filter((item) => {
    return columns.some((column) => {
      const value = column.selector ? column.selector(item) : null;
      return (
        value &&
        value.toString().toLowerCase().includes(filtertext.toLowerCase())
      );
    });
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-col items-end space-y-2 w-full p-4">
        <button
          onClick={() => {
            setShowModal(true);
            ClearData();
          }}
          className="px-4 py-2 bg-custom-green text-white rounded"
        >
          + Add
        </button>
        <input
          type="text"
          onChange={(e) => setfiltertext(e.target.value)}
          value={filtertext}
          placeholder="Search here"
          className="border rounded p-2 w-full max-w-xs"
        />
      </div>
    );
  }, [filtertext, resetPaginationToggle]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(
        file.type
      )
    ) {
      setSelectedFile(file);
      setError(false);
    } else {
      setSelectedFile(null);
      setError(true);
      toast.error("Please select a valid image file (PNG, JPEG, JPG, SVG).");
    }
  };

  const handleCountChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    // Set maximum length for count
    const maxLength = 15; // Adjust this value as needed
    if (numericValue.length <= maxLength) {
      setCount(numericValue);
    } else {
      // Truncate the input to maxLength if it exceeds the limit
      const truncatedValue = numericValue.slice(0, maxLength);
      setCount(truncatedValue);
    }
  };

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    // Set maximum length for title
    const maxLength = 90; // Adjust this value as needed
    if (inputValue.length <= maxLength) {
      setTitle(inputValue);
    } else {
      // Optionally show an error message or trim the input
      const truncatedValue = inputValue.slice(0, maxLength);
      setTitle(truncatedValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Set maximum length for title
    const maxLength = 90; // Adjust this value as needed
    if (inputValue.length <= maxLength) {
      setName(inputValue);
    } else {
      // Optionally show an error message or trim the input
      const truncatedValue = inputValue.slice(0, maxLength);
      setName(truncatedValue);
    }
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    // Set maximum length for description
    const maxLength = 250; // Adjust this value as needed
    if (inputValue.length <= maxLength) {
      setDescription(inputValue);
    } else {
      // Optionally show an error message or trim the input
      const truncatedValue = inputValue.slice(0, maxLength);
      setDescription(truncatedValue);
    }
  };
  const handleAddOrUpdate = async () => {
    const formData = new FormData();

    formData.append("userId", User?.data?.id);
    formData.append("name", Name);
    formData.append("count", Count);
    if (selectedFile) {
      formData.append("Photo", selectedFile);
    }

    if (!Name || !Count || (!editMode && !selectedFile)) {
      setError("Please fill out all fields.");
      return;
    }
    setIsLoading(true);
    try {
      if (editMode) {
        await UpdateHomeStatistics(currentId, formData);
      } else {
        // Otherwise, create new entry
        await CreateHomeStatistics(formData);
      }
    } catch (err) {
      // Handle any potential errors from the API calls
      console.error(err);
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      ClearData();
      setShowModal(false);
    }
  };

  return (
    <div className="px-8 py-2">
      {/* <h1 className="text-2xl font-semibold mb-4">Sercice</h1> */}

      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        customStyles={customStyles}
      />

      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-xl">
            <h2 className="text-xl font-bold mb-4  ">
              {editMode ? "Edit" : "Add"} Statistics
            </h2>
            <div className="grid grid-cols-12 gap-4 mb-4">
              <div className="col-span-12">
                <label className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  value={Name}
                  onChange={handleNameChange}
                  className={`w-full p-2 border rounded ${
                    !Title && error ? "border-red-500" : ""
                  }`}
                  placeholder="Enter name"
                />
              </div>
              <div className="col-span-12">
                <label className="block text-gray-700 mb-2">Count</label>
                <input
                  type="text"
                  value={Count}
                  onChange={(e) => setCount(e.target.value)}
                  className={`w-full p-2 border rounded ${
                    !Count && error ? "border-red-500" : ""
                  }`}
                  placeholder="Enter count"
                />
              </div>

              <div className="col-span-12">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="file_input"
                >
                  {editMode ? "Change file (Optional)" : "Upload file"}
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !selectedFile && error ? "border-red-500" : ""
                  }`}
                  id="file_input"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                  onChange={handleFileChange}
                />
                {editMode && selectedFile && selectedFile.name && (
                  <p className="mt-2 text-sm text-gray-600">
                    Current file: {selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            {/* <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrUpdate}
                className="px-4 py-2 bg-green-500 text-white rounded"
              >
                {editMode ? "Update" : "Add"}
              </button>
            </div> */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setShowModal(false);
                  ClearData();
                  setDeleteId("");
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrUpdate}
                className={`px-4 py-2 rounded ${
                  isLoading ? "bg-gray-400" : "bg-custom-green"
                } text-white`}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : editMode ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl font-bold mb-4 text-red-400 text-center">
              Are you sure?
            </h2>

            <p className="text-gray-700 mb-4">
              Are you sure you want to delete this record?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteId("");
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Yes,Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeStatistics;
