import React, { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";
import "../Styles/About.css";
import UserIcon from "../images/About_User_Icon.png";
import AchievementIcon from "../images/About_Achive_Tag_Icon.png";

const ChooseService = () => {
  const { FetchAboutChooseOurService, ChooseOurservice } =
    useContext(AppContext);
  const [openIndex, setOpenIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  useEffect(() => {
    FetchAboutChooseOurService();
  }, []);

  // Wait until the data is fetched and available
  if (!ChooseOurservice || ChooseOurservice.length === 0) return;

  const data = ChooseOurservice[0];

  return (
    <section className="py-4 md:py-8 bg-white font-poppins">
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12 relative px-4 md:px-8">
        <div className="w-full lg:w-1/2 relative">
          <div className="relative">
            <LazyLoad height={532} offset={100}>
              <img
                src={data.fileName}
                alt="Rikaab Workers"
                className="w-full rounded-lg"
              />
            </LazyLoad>
            <div className="achievement-badge">
              <div>
                <span>
                  <span className="text-custom-green">150+</span> Verified
                  Assistant
                </span>
                <LazyLoad height={532} offset={100}>
                  <img
                    src={AchievementIcon} // Update to dynamic if you have a specific achievement icon URL
                    alt="Achievement"
                    className="absolute top-0 right-0"
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <span
            className="mt-24 inline-block bg-blue-100 rounded-full px-6 py-2 md:px-10 md:py-3 mb-4 text-sm font-semibold"
            style={{ color: "#122b47" }}
          >
            WHY US
          </span>
          <h2
            className="text-2xl md:text-3xl font-bold mb-6"
            style={{ color: "#122b47" }}
          >
            {data.title}
          </h2>
          <p
            className="text-base md:text-lg mb-8"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></p>
          <div className="space-y-6">
            {data.details.map((item, index) => (
              <div className="details-hover" key={item._id}>
                <div
                  className={`w-full flex justify-between items-center text-left p-5 text-lg md:text-xl font-semibold cursor-pointer ${
                    openIndex === index || hoverIndex === index
                      ? "bg-[#122b47] text-white"
                      : "bg-[#e1ffeb] text-green-600"
                  }`}
                  onClick={() => handleToggle(index)}
                  // onMouseEnter={() => handleMouseEnter(index)}
                  // onMouseLeave={handleMouseLeave}
                >
                  <div className="flex gap-5">
                    <img src={UserIcon} width="30" alt="User Icon" />
                    <span className="summary-text">{item.header}</span>
                  </div>
                  <span className="text-end">
                    <i
                      className={`fas fa-chevron-${
                        openIndex === index || hoverIndex === index
                          ? "down"
                          : "up"
                      }`}
                    ></i>
                  </span>
                </div>
                {(openIndex === index || hoverIndex === index) && (
                  <div className="px-4 pb-4 bg-[#122b47]">
                    <p className="text-white">{item.subheader}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-32"></div>
    </section>
  );
};

export default ChooseService;
