import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";
const BlogHeader = () => {
  const { FetchBlogDetails, BlogDetailsData } = useContext(AppContext);
  useEffect(() => {
    FetchBlogDetails();
  }, []);
  return (
    <div
      className="relative bg-cover bg-center mt-6"
      style={{ height: "532px" }}
    >
      {BlogDetailsData?.map((item) => {
        return (
          <LazyLoad height={532} offset={100}>
            <div
              className="relative bg-cover bg-center mt-6"
              style={{
                backgroundImage: `url(${item.fileName})`,
                height: "532px",
              }}
            >
              <div className="relative z-10 flex items-center justify-center h-full p-4">
                <div className="text-center text-white bg-opacity-75 rounded-lg p-6">
                  <div className="flex justify-center mb-4">
                    <Link
                      to="#"
                      className="text-white py-2 rounded-md  flex items-center space-x-2 text-2xl md:text-6xl font-bold"
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></span>
                    </Link>
                  </div>
                  <div className="flex justify-center mb-4">
                    <Link
                      to="#"
                      className="text-white rounded-md  flex items-center space-x-2 text-2xl md:text-6xl font-bold"
                    >
                      <span>On Your Way</span>
                    </Link>
                  </div>
                  <div className="flex justify-center space-x-4 items-center mt-8 text-xl">
                    <i className="fas fa-car-side"></i>
                    <span className="capitalize">Taxi</span>
                    <i className="fa fa-calendar"></i>
                    <span className="capitalize">July 20, 2024</span>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoad>
        );
      })}
    </div>
  );
};

export default BlogHeader;
