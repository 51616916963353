import React, { createContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import reducer from "./Reducer";
import {
  BECOME_RIKAAB_PARTNERS,
  BECOME_RIKAAB_PARTNERS_BY_ID,
  BLOG_POST_DETAILS,
  BLOG_POST_DETAILS_BY_ID,
  CHOOSE_US,
  FETCH_ABOUT_SERVICE,
  FETCH_ABOUT_SERVICE_DRTAILS,
  FETCH_ABOUT_SLIDER,
  FETCH_ABOUT_TEAMS_IMAGES,
  FETCH_ABOUT_WHY_US,
  FETCH_AWARD_DETAILS_HEADER,
  FETCH_AWARDS_CONTENTS,
  FETCH_AWARDS_LISTS,
  FETCH_AWARDS_POSTS,
  FETCH_AWARDS_POSTS_BY_ID,
  FETCH_BLOG_DETAILS,
  FETCH_BLOG_SERVICES,
  FETCH_BLOG_SLIDER,
  FETCH_EMURABAHA,
  FETCH_EVENT_DETAILS_HEADER,
  FETCH_EVENTS,
  FETCH_EVENTS_COVER,
  FETCH_EVENTS_LISTS,
  FETCH_EVENTS_LISTS_BY_ID,
  FETCH_EVENTS_LISTS_DATA,
  FETCH_HOME_BECOME_CARDS,
  FETCH_HOME_BECOME_IMAGES,
  FETCH_HOME_LEARN_MORE,
  FETCH_HOME_SERVICE,
  FETCH_HOME_SLIDER,
  FETCH_HOME_STATISTICS,
  FETCH_HOME_STATISTISCS_TITLES,
  FETCH_RECENTLY_POSTS,
  FETCH_RESTAURANST_HEADERS,
  FETCH_RESTAURANST_PARTNERSHIPS,
  FETCH_RESTAURANST_SERVICE,
  FETCH_REWARD_LISTS,
  FETCH_SERVICE_CARD,
  FETCH_SERVICE_SLIDER,
  FETCH_SUPPORT_DETAILS,
  FETCH_SUPPORT_HEADERS,
  FETCH_SUPPORT_SERVICES,
  HOME_FAQ,
  HOME_FAQ_BY_ID,
  RIKAAB_PARTNERS,
  SERVICE_FAQS,
  SUPPORT_DETAILS_FAQ,
  SUPPORT_DETAILS_FAQ_BY_ID,
  SUPPORT_FAQ,
} from "./Action";
import { baseURL } from "../config";

import toast from "react-hot-toast";
import CryptoJS from "crypto-js";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = baseURL;

export const AppContext = createContext();
let secretKey =
  "fa9e2d7b3be0e86fa698dc1ba4c95cd35c0122165acd35bacbd4705189d82fec20778b137dhr03662dbd4dfde3b6e4f66b7db2733c6df17223cd652bc72e3739";
export const DecryptSession = (secretKey, name) => {
  // Retrieve and decrypt the data
  const storedData = sessionStorage.getItem(name);
  if (!storedData) {
    return null; // Handle the case where there's no data in sessionStorage
  }

  try {
    const bytes = CryptoJS.AES.decrypt(storedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (e) {
    console.error("Decryption error:", e);
    return null; // Handle decryption errors
  }
};
const plainData = DecryptSession(secretKey, "userId");
const EncryptData = (secretKey, data, name) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  sessionStorage.setItem(name, encryptedData);
  return encryptedData;
};
const initialState = {
  secretKey,
  User: plainData || "",
  isLoading: false,
  HomeSlider: [],
  HomeServices: [],
  HomeBecomeImages: [],
  HomeBecomeCards: [],
  HomeLearnMoreData: [],
  HomeStatisticsData: [],
  AboutSliderData: [],
  AboutServiceData: [],
  AboutServiceDetailsData: [],
  TeamImages: [],
  ChooseOurservice: [],
  SupportOurservice: [],
  SupportHeaderData: [],
  BlogServiceData: [],
  BlogSlider: [],
  SupportDetailsData: [],
  BlogDetailsData: [],
  RecentlyPosts: [],
  StatisticsTitleData: [],
  RestauransHeaderData: [],
  RestaurantServiceData: [],
  RestaurantPartnershipData: [],
  SupportFAQ: [],
  SupportDetailFAQ: [],
  RikaabPartner: [],
  BecomeRikaabPartner: [],
  RikaabPartnerData: [],
  FAQbyID: [],
  BlogLists: [],
  PostsListsById: [],
  FAQDataById: [],
  FAQData: [],
  ServiceSliderData: [],
  ServiceCardData: [],
  MurabahaData: [],
  ChooseUsData: [],
  ServiceFAQData: [],
  EventData: [],
  EventListData: [],
  EventById: [],
  EventCoverData: [],
  RewardContentData: [],
  EventContentData: [],
  AwardsData: [],
  AwardsContentData: [],
  AwardsPostsData: [],
  FetchContentById: [],
  AwardHeader: [],
  EventHeader: [],
};

const AppProvider = ({ children }) => {
  // const navigation = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const LoginUser = async (data) => {
    try {
      const Resp = await axios.post(`/users/login`, data);
      if (Resp?.data?.status === true) {
        toast.success(Resp?.data?.message, {
          position: "top-right",
        });
        EncryptData(secretKey, Resp, "userId");
        window.location.href = "/Sidebar"; // Redirect to Sidebar after successful login
      }
      if (Resp?.data?.status === false) {
        toast.error(Resp?.data?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("An error occurred during login. Please try again.", {
        position: "top-right",
      });
    }
  };
  const LogoutUser = () => {
    try {
      // Clear the encrypted user data from local storage or wherever it's stored
      sessionStorage.removeItem("userId"); // Assuming 'userId' is the key used to store the encrypted user data
      // localStorage.clear("userId"); // Assuming 'userId' is the key used to store the encrypted user data

      // Optionally, you can also clear any other relevant data or reset application state

      // Redirect to the login page
      window.location.href = "/login"; // Update the path to your login page as needed

      // Optionally, you can display a success message to confirm the user has logged out
      // toast.success("You have been logged out successfully.", {
      //   position: "top-right",
      // });
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("An error occurred during logout. Please try again.", {
        position: "top-right",
      });
    }
  };

  // home silders
  const FetchHomeSlider = async () => {
    try {
      const response = await axios.get(`/home/fetch-headers`);
      dispatch({
        type: FETCH_HOME_SLIDER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeSlider = async (data) => {
    try {
      const Resp = await axios.post(`/home/create-header`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchHomeSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateHomeSlider = async (id, data) => {
    try {
      const Resp = await axios.patch(`/home/update-header/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeSlider = async (id) => {
    try {
      const Resp = await axios.delete(`/home/delete-header/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // home services
  const FetchHomeService = async () => {
    try {
      const response = await axios.get(`/home/fetch-services`);
      dispatch({
        type: FETCH_HOME_SERVICE,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeService = async (data) => {
    try {
      const Resp = await axios.post(`/home/create-service`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchHomeService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateHomeService = async (id, data) => {
    try {
      const Resp = await axios.patch(`/home/update-service/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeService = async (id, data) => {
    try {
      const Resp = await axios.delete(`/home/delete-service/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  //Home Become part images
  const FetchHomeBecomePartImages = async () => {
    try {
      const response = await axios.get(`/home/fetch-rikab-images`);
      dispatch({
        type: FETCH_HOME_BECOME_IMAGES,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeBecomePartImages = async (data) => {
    try {
      const Resp = await axios.post(`/home/create-rikab-images`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchHomeBecomePartImages();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  const UpdateHomeBecomePartImages = async (id, data) => {
    try {
      const Resp = await axios.patch(`/home/update-rikab-images/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeBecomePartImages();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeBecomePartImages = async (id) => {
    try {
      const Resp = await axios.delete(`/home/delete-rikab-image/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeBecomePartImages();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // home become cards
  const FetchHomeCards = async () => {
    try {
      const response = await axios.get(`/home/fetch-rikab-cards`);
      dispatch({
        type: FETCH_HOME_BECOME_CARDS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeCards = async (data) => {
    try {
      const Resp = await axios.post(`/home/create-rikab-card`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchHomeCards();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateHomeCards = async (id, data) => {
    try {
      const Resp = await axios.patch(`/home/update-rikab-card/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeCards();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeCards = async (id) => {
    try {
      const Resp = await axios.delete(`/home/delete--rikab-card/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeCards();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // home learn more
  const FetchHomeLearnMore = async () => {
    try {
      const response = await axios.get(`/home/fetch-learn-mores`);
      dispatch({
        type: FETCH_HOME_LEARN_MORE,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeLearnMore = async (data) => {
    try {
      const Resp = await axios.post(`/home/create-learn-more`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchHomeLearnMore();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateHomeLearnMore = async (id, data) => {
    try {
      const Resp = await axios.patch(`/home/update-learn-more/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeLearnMore();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeLearnMore = async (id) => {
    try {
      const Resp = await axios.delete(`/home/delete-learn-more/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeLearnMore();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // home statistic

  const FetchHomeStatistics = async () => {
    try {
      const response = await axios.get(`/home/fetch-statistics`);
      dispatch({
        type: FETCH_HOME_STATISTICS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeStatistics = async (data) => {
    try {
      const Resp = await axios.post(`/home/create-statistics`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchHomeStatistics();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateHomeStatistics = async (id, data) => {
    try {
      const Resp = await axios.patch(`/home/update-statistics/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeStatistics();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeStatistics = async (id) => {
    try {
      const Resp = await axios.delete(`/home/delete-statistics/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeStatistics();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // about sliders
  const FetchAboutSlider = async () => {
    try {
      const response = await axios.get(`/about/fetch-headers`);
      dispatch({
        type: FETCH_ABOUT_SLIDER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAboutSlider = async (data) => {
    try {
      const Resp = await axios.post(`/about/create-header`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAboutSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAboutSlider = async (id, data) => {
    try {
      const Resp = await axios.patch(`/about/update-header/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAboutSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAboutSlider = async (id) => {
    try {
      const Resp = await axios.delete(`/about/delete-header/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAboutSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // about services
  const FetchAboutService = async () => {
    try {
      const response = await axios.get(`/about/fetch-service`);
      dispatch({
        type: FETCH_ABOUT_SERVICE,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAboutService = async (data) => {
    try {
      const Resp = await axios.post(`/about/create-service`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAboutService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAboutService = async (id, data) => {
    try {
      const Resp = await axios.patch(`/about/update-service/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAboutService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAboutService = async (id) => {
    try {
      const Resp = await axios.delete(`/about/delete-service/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAboutService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // about services details
  const FetchAboutServiceDetail = async () => {
    try {
      const response = await axios.get(`/about/fetch-service-detail`);
      dispatch({
        type: FETCH_ABOUT_SERVICE_DRTAILS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAboutServiceDetail = async (data) => {
    try {
      const Resp = await axios.post(`/about/create-service-detail`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAboutServiceDetail();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAboutServiceDetail = async (id, data) => {
    try {
      const Resp = await axios.patch(
        `/about/update-service-detail/${id}`,
        data
      );
      if (Resp?.data?.status === "success") {
        FetchAboutServiceDetail();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAboutServiceDetail = async (id) => {
    try {
      const Resp = await axios.delete(`/about/delete-service-detail/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAboutServiceDetail();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // about Team images
  const FetchAboutTeamImages = async () => {
    try {
      const response = await axios.get(`/about/fetch-team`);
      dispatch({
        type: FETCH_ABOUT_TEAMS_IMAGES,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAboutTeamImages = async (data) => {
    try {
      const Resp = await axios.post(`/about/create-team`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAboutTeamImages();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAboutTeamImages = async (id, data) => {
    try {
      const Resp = await axios.patch(`/about/update-team/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAboutTeamImages();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAboutTeamImages = async (id) => {
    try {
      const Resp = await axios.delete(`/about/delete-team/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAboutTeamImages();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // about choose our service
  const FetchAboutChooseOurService = async () => {
    try {
      const response = await axios.get(`/about/fetch-us`);
      dispatch({
        type: FETCH_ABOUT_WHY_US,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAboutChooseOurService = async (data) => {
    try {
      const Resp = await axios.post(`/about/create-us`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAboutChooseOurService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAboutChooseOurService = async (id, data) => {
    try {
      const Resp = await axios.patch(`/about/update-us/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAboutChooseOurService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAboutChooseOurService = async (id) => {
    try {
      const Resp = await axios.delete(`/about/delete-us/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAboutChooseOurService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Support Our Services
  const FetchSupportService = async () => {
    try {
      const response = await axios.get(`/support/fetch-service`);
      dispatch({
        type: FETCH_SUPPORT_SERVICES,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateSupportService = async (data) => {
    try {
      const Resp = await axios.post(`/support/create-service`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchSupportService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateSupportService = async (id, data) => {
    try {
      const Resp = await axios.patch(`/support/update-service/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchSupportService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteSupportService = async (id) => {
    try {
      const Resp = await axios.delete(`/support/delete-service/${id}`);
      if (Resp?.data?.status === "success") {
        FetchSupportService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Support Headers
  const FetchSupportHeaders = async () => {
    try {
      const response = await axios.get(`/support/fetch-header`);
      dispatch({
        type: FETCH_SUPPORT_HEADERS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateSupportHeaders = async (data) => {
    try {
      const Resp = await axios.post(`/support/create-header`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchSupportHeaders();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateSupportHeaders = async (id, data) => {
    try {
      const Resp = await axios.patch(`/support/update-header/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchSupportHeaders();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteSupportHeaders = async (id) => {
    try {
      const Resp = await axios.delete(`/support/delete-header/${id}`);
      if (Resp?.data?.status === "success") {
        FetchSupportHeaders();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // blog Headers
  const FetchBlogService = async () => {
    try {
      const response = await axios.get(`/blog/fetch-service`);
      dispatch({
        type: FETCH_BLOG_SERVICES,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateBlogService = async (data) => {
    try {
      const Resp = await axios.post(`/blog/create-service`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchBlogService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateBlogService = async (id, data) => {
    try {
      const Resp = await axios.patch(`/blog/update-service/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchBlogService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteBlogService = async (id) => {
    try {
      const Resp = await axios.delete(`/blog/delete-service/${id}`);
      if (Resp?.data?.status === "success") {
        FetchBlogService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Blog slider
  const FetchBlogSlider = async () => {
    try {
      const response = await axios.get(`/blog/fetch-slider`);
      dispatch({
        type: FETCH_BLOG_SLIDER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateBlogSlider = async (data) => {
    try {
      const Resp = await axios.post(`/blog/create-slider`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchBlogSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateBlogSlider = async (id, data) => {
    try {
      const Resp = await axios.patch(`/blog/update-slider/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchBlogSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteBlogSlider = async (id) => {
    try {
      const Resp = await axios.delete(`/blog/delete-slider/${id}`);
      if (Resp?.data?.status === "success") {
        FetchBlogSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Support detail header image
  const FetchSupportDetails = async () => {
    try {
      const response = await axios.get(`/supportdetails/fetch-header`);
      dispatch({
        type: FETCH_SUPPORT_DETAILS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateSupportDetails = async (data) => {
    try {
      const Resp = await axios.post(`/supportdetails/create-header`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchSupportDetails();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateSupportDetails = async (id, data) => {
    try {
      const Resp = await axios.patch(
        `/supportdetails/update-header/${id}`,
        data
      );
      if (Resp?.data?.status === "success") {
        FetchSupportDetails();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteSupportDetails = async (id) => {
    try {
      const Resp = await axios.delete(`/supportdetails/delete-header/${id}`);
      if (Resp?.data?.status === "success") {
        FetchSupportDetails();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // blog detail header image
  const FetchBlogDetails = async () => {
    try {
      const response = await axios.get(`/blogdetails/fetch-header`);
      dispatch({
        type: FETCH_BLOG_DETAILS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateBlogDetails = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/blogdetails/create-header`, dataTosend, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchBlogDetails();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateBlogDetails = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/blogdetails/update-header/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchBlogDetails();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteBlogDetails = async (id) => {
    try {
      const Resp = await axios.delete(`/blogdetails/delete-header/${id}`);
      if (Resp?.data?.status === "success") {
        FetchBlogDetails();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // blog detail header image
  const FetchRecentPosts = async () => {
    try {
      const response = await axios.get(`/post/fetch-post`);
      dispatch({
        type: FETCH_RECENTLY_POSTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateRecentPosts = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/post/create-post`, dataTosend, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchRecentPosts();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateRecentPosts = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/post/update-post/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchRecentPosts();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteRecentPosts = async (id) => {
    try {
      const Resp = await axios.delete(`/post/delete-post/${id}`);
      if (Resp?.data?.status === "success") {
        FetchRecentPosts();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // blog detail header image
  const FetchStatisticsTitles = async () => {
    try {
      const response = await axios.get(`/home/fetch-titles`);
      dispatch({
        type: FETCH_HOME_STATISTISCS_TITLES,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateStatisticsTitles = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/home/create-titles`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchStatisticsTitles();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateStatisticsTitles = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/home/update-titles/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchStatisticsTitles();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteStatisticsTitles = async (id) => {
    try {
      const Resp = await axios.delete(`/home/delete-title/${id}`);
      if (Resp?.data?.status === "success") {
        FetchStatisticsTitles();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // about sliders
  const FetchRestauranslider = async () => {
    try {
      const response = await axios.get(`/restaurant/fetch-headers`);
      dispatch({
        type: FETCH_RESTAURANST_HEADERS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateRestauranslider = async (data) => {
    try {
      const Resp = await axios.post(`/restaurant/create-header`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchRestauranslider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateRestauranslider = async (id, data) => {
    try {
      const Resp = await axios.patch(`/restaurant/update-header/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchRestauranslider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteRestauranslider = async (id) => {
    try {
      const Resp = await axios.delete(`/restaurant/delete-header/${id}`);
      if (Resp?.data?.status === "success") {
        FetchRestauranslider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // restaurants service
  const FetchRestaurantsService = async () => {
    try {
      const response = await axios.get(`/restaurant/fetch-services`);
      dispatch({
        type: FETCH_RESTAURANST_SERVICE,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateRestaurantsService = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/restaurant/create-service`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchRestaurantsService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateRestaurantsService = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/restaurant/update-service/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchRestaurantsService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteRestaurantsService = async (id) => {
    try {
      const Resp = await axios.delete(`/restaurant/delete-service/${id}`);
      if (Resp?.data?.status === "success") {
        FetchRestaurantsService();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // blog detail header image
  const FetchRestaurantPartnership = async () => {
    try {
      const response = await axios.get(`/restaurant/fetch-partnerships`);
      dispatch({
        type: FETCH_RESTAURANST_PARTNERSHIPS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateRestaurantPartnership = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(
        `/restaurant/create-partnership`,
        dataTosend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the correct content type is set
          },
        }
      );
      if (Resp?.data?.status === "success") {
        FetchRestaurantPartnership();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateRestaurantPartnership = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(
        `/restaurant/update-partnership/${id}`,
        data
      );
      if (Resp?.data?.status === "success") {
        FetchRestaurantPartnership();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteRestaurantPartnership = async (id) => {
    try {
      const Resp = await axios.delete(`/restaurant/delete-partnership/${id}`);
      if (Resp?.data?.status === "success") {
        FetchRestaurantPartnership();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Support FAQ
  const FetchSupportFAQ = async () => {
    try {
      const response = await axios.get(`/support/fetch-faqs`);
      dispatch({
        type: SUPPORT_FAQ,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateSupportFAQ = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/support/create-faq`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchSupportFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateSupportFAQ = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/support/update-faq/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchSupportFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteSupportFAQ = async (id) => {
    try {
      const Resp = await axios.delete(`/support/delete-faq/${id}`);
      if (Resp?.data?.status === "success") {
        FetchSupportFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Support FAQ
  const FetchSupportDetailsFAQ = async () => {
    try {
      const response = await axios.get(`/supportdetail/fetch-faqs`);
      dispatch({
        type: SUPPORT_DETAILS_FAQ,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const FetchSupportDetailsFAQById = async (id) => {
    try {
      const response = await axios.get(`/supportdetail/fetch-faqs/${id}`);
      dispatch({
        type: SUPPORT_DETAILS_FAQ_BY_ID,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateSupportDetailsFAQ = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/supportdetail/create-faq`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchSupportDetailsFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateSupportDetailsFAQ = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/supportdetail/update-faq/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchSupportDetailsFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteSupportDetailsFAQ = async (id) => {
    try {
      const Resp = await axios.delete(`/supportdetail/delete-faq/${id}`);
      if (Resp?.data?.status === "success") {
        FetchSupportDetailsFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // RIKAAB PARTNER
  const FetchARikaabPartner = async () => {
    try {
      const response = await axios.get(`/Rikaab-Partner/fetch`);
      dispatch({
        type: RIKAAB_PARTNERS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateRikaabPartner = async (data) => {
    try {
      const Resp = await axios.post(`/Rikaab-Partner/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchARikaabPartner();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateRikaabPartner = async (id, data) => {
    try {
      const Resp = await axios.patch(`/Rikaab-Partner/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchARikaabPartner();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteRikaabPartner = async (id) => {
    try {
      const Resp = await axios.delete(`/Rikaab-Partner/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchARikaabPartner();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // RIKAAB PARTNER
  const FetchABecomeRikaabPartner = async () => {
    try {
      const response = await axios.get(`/become-Partner/fetch`);
      dispatch({
        type: BECOME_RIKAAB_PARTNERS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const FetchABecomeRikaabPartnerById = async (id) => {
    try {
      const response = await axios.get(`/become-Partner/PartnerId/${id}`);
      dispatch({
        type: BECOME_RIKAAB_PARTNERS_BY_ID,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateBecomeRikaabPartner = async (data) => {
    try {
      const Resp = await axios.post(`/become-Partner/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchABecomeRikaabPartner();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateBecomeRikaabPartner = async (id, data) => {
    try {
      const Resp = await axios.patch(`/become-Partner/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchABecomeRikaabPartner();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteBecomeRikaabPartner = async (id) => {
    try {
      const Resp = await axios.delete(`/become-Partner/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchABecomeRikaabPartner();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Blog lists
  const FetchBlogLists = async () => {
    try {
      const response = await axios.get(`/blogPosts/fetch`);
      dispatch({
        type: BLOG_POST_DETAILS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };

  const CreateBlogLists = async (data) => {
    try {
      const Resp = await axios.post(`/blogPosts/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchBlogLists();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  const FetchPostsContents = async (id) => {
    try {
      const response = await axios.get(`/blogPosts/fetchById/${id}`);
      dispatch({
        type: BLOG_POST_DETAILS_BY_ID,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };

  /// customers posts
  const CreateCustomersComments = async (data) => {
    try {
      const Resp = await axios.post(`/costomer/create-post`, data);
      if (Resp?.data?.status === "success") {
        // FetchBlogLists();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  const DeletePostsContents = async (id) => {
    try {
      const Resp = await axios.delete(`/blogPosts/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchBlogLists();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Support FAQ
  const FetchHomeFAQ = async () => {
    try {
      const response = await axios.get(`/homefaq/fetch-faqs`);
      dispatch({
        type: HOME_FAQ,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const FetchHomeFAQById = async (id) => {
    try {
      const response = await axios.get(`/homefaq/fetch-faqsbyId/${id}`);
      dispatch({
        type: HOME_FAQ_BY_ID,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateHomeFAQ = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/homefaq/create-faq`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchHomeFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateHomeFAQ = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/homefaq/update-faq/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchHomeFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteHomeFAQ = async (id) => {
    try {
      const Resp = await axios.delete(`/homefaq/delete-faq/${id}`);
      if (Resp?.data?.status === "success") {
        FetchHomeFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Sevice sliders
  const FetchServiceSlider = async () => {
    try {
      const response = await axios.get(`/service/fetch`);
      dispatch({
        type: FETCH_SERVICE_SLIDER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateServiceSlider = async (data) => {
    try {
      const Resp = await axios.post(`/service/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchServiceSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateServiceSlider = async (id, data) => {
    try {
      const Resp = await axios.patch(`/service/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchServiceSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteServiceSlider = async (id) => {
    try {
      const Resp = await axios.delete(`/service/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchServiceSlider();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Sevice cards
  const FetchServiceCard = async () => {
    try {
      const response = await axios.get(`/service/fetch-cards`);
      dispatch({
        type: FETCH_SERVICE_CARD,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateServiceCard = async (data) => {
    try {
      const Resp = await axios.post(`/service/create-card`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchServiceCard();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateServiceCard = async (id, data) => {
    try {
      const Resp = await axios.patch(`/service/update-card/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchServiceCard();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteServiceCard = async (id) => {
    try {
      const Resp = await axios.delete(`/service/delete-card/${id}`);
      if (Resp?.data?.status === "success") {
        FetchServiceCard();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // E-murabaha

  const FetchEmurabaha = async () => {
    try {
      const response = await axios.get(`/murabaha/fetch`);
      dispatch({
        type: FETCH_EMURABAHA,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateEmurabaha = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/murabaha/create`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchEmurabaha();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateEmurabaha = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/murabaha/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchEmurabaha();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteEmurabaha = async (id) => {
    try {
      const Resp = await axios.delete(`/murabaha/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchEmurabaha();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // E-murabaha

  const FetchChooseUs = async () => {
    try {
      const response = await axios.get(`/chooseUs/fetch`);
      dispatch({
        type: CHOOSE_US,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateChooseUs = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/chooseUs/create`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchChooseUs();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateChooseUs = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/chooseUs/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchChooseUs();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteChooseUs = async (id) => {
    try {
      const Resp = await axios.delete(`/chooseUs/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchChooseUs();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // SERFICE FAQ

  const FetchServiceFAQ = async () => {
    try {
      const response = await axios.get(`/servicefaqs/fetch`);
      dispatch({
        type: SERVICE_FAQS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateServiceFAQ = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/servicefaqs/create`, dataTosend);
      if (Resp?.data?.status === "success") {
        FetchServiceFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateServiceFAQ = async (id, data) => {
    try {
      console.log(data);
      const Resp = await axios.patch(`/servicefaqs/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchServiceFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteServiceFAQ = async (id) => {
    try {
      const Resp = await axios.delete(`/servicefaqs/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchServiceFAQ();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // get in touch
  const CreateGetnTouch = async (dataTosend) => {
    try {
      console.log(dataTosend);
      const Resp = await axios.post(`/customer-comment/create`, dataTosend);
      if (Resp?.data?.status === "success") {
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const FetchEvents = async () => {
    try {
      const response = await axios.get(`/event/fetch`);
      dispatch({
        type: FETCH_EVENTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateEvents = async (data) => {
    try {
      const Resp = await axios.post(`/event/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchEvents();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateEvents = async (id, data) => {
    try {
      const Resp = await axios.patch(`/event/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchEvents();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteEvents = async (id) => {
    try {
      const Resp = await axios.delete(`/event/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchEvents();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const FetchEventsLists = async () => {
    try {
      const response = await axios.get(`/events-post/fetch`);
      dispatch({
        type: FETCH_EVENTS_LISTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateEventsLists = async (data) => {
    try {
      const Resp = await axios.post(`/events-post/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchEventsLists();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  const FetchEventsById = async (id) => {
    try {
      const response = await axios.get(`/events-post/fetchById/${id}`);
      dispatch({
        type: FETCH_EVENTS_LISTS_BY_ID,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };

  // Blog slider
  const FetchEventCover = async () => {
    try {
      const response = await axios.get(`/events-cover/fetch`);
      dispatch({
        type: FETCH_EVENTS_COVER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateEventCover = async (data) => {
    try {
      const Resp = await axios.post(`/events-cover/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchEventCover();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateEventCover = async (id, data) => {
    try {
      const Resp = await axios.patch(`/event-cover/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchEventCover();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteEventCover = async (id) => {
    try {
      const Resp = await axios.delete(`/events-cover/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchEventCover();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  const FetchRewardLists = async () => {
    try {
      const response = await axios.get(`/reward-content/fetch`);
      dispatch({
        type: FETCH_REWARD_LISTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const FetchEventContentLists = async () => {
    try {
      const response = await axios.get(`/events-post/fetch`);
      dispatch({
        type: FETCH_EVENTS_LISTS_DATA,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const DeleteEventContent = async (id) => {
    try {
      const Resp = await axios.delete(`/events-post/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchEventContentLists();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteRewardContent = async (id) => {
    try {
      const Resp = await axios.delete(`/reward-content/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchEventContentLists();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  // Awards slider
  const FetchAwards = async () => {
    try {
      const response = await axios.get(`/riwards-cover/fetch`);
      dispatch({
        type: FETCH_AWARDS_LISTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAwards = async (data) => {
    try {
      const Resp = await axios.post(`/riwards-cover/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAwards();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAwards = async (id, data) => {
    try {
      const Resp = await axios.patch(`/riwards-cover/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAwards();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAwards = async (id) => {
    try {
      const Resp = await axios.delete(`/riwards-cover/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAwards();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Awards slider
  const FetchAwardsContent = async () => {
    try {
      const response = await axios.get(`/riwards-articles/fetch`);
      dispatch({
        type: FETCH_AWARDS_CONTENTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAwardsContent = async (data) => {
    try {
      const Resp = await axios.post(`/riwards-articles/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAwardsContent();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAwardsContent = async (id, data) => {
    try {
      const Resp = await axios.patch(`/riwards-articles/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAwardsContent();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAwardsContent = async (id) => {
    try {
      const Resp = await axios.delete(`/riwards-articles/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAwardsContent();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Awards slider
  const FetchAwardsContents = async () => {
    try {
      const response = await axios.get(`/reward-content/fetch`);
      dispatch({
        type: FETCH_AWARDS_POSTS,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAwardsContents = async (data) => {
    try {
      const Resp = await axios.post(`/reward-content/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAwardsContents();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // const UpdateAwardsContents = async (id, data) => {
  //   try {
  //     const Resp = await axios.patch(`/riwards-detail/update/${id}`, data);
  //     if (Resp?.data?.status === "success") {
  //       FetchAwardsContents();
  //       return toast.success(Resp?.data?.message, {
  //         position: "top-right",
  //       });
  //     }

  //     if (Resp?.data?.status === "fail")
  //       return toast.error(Resp?.data?.message, {
  //         position: "top-right",
  //       });
  //   } catch (error) {
  //     return toast.error("something went wrong", {
  //       position: "top-right",
  //     });
  //   }
  // };
  const DeleteAwardsContents = async (id) => {
    try {
      const Resp = await axios.delete(`/reward-content/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAwardsContents();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };

  const FetchContentById = async (id) => {
    try {
      const response = await axios.get(`/reward-content/fetchById/${id}`);
      dispatch({
        type: FETCH_AWARDS_POSTS_BY_ID,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };

  // Awards sliders
  const FetchAwardHeader = async () => {
    try {
      const response = await axios.get(`/riwards-detail/fetch`);
      dispatch({
        type: FETCH_AWARD_DETAILS_HEADER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateAwardHeader = async (data) => {
    try {
      const Resp = await axios.post(`/riwards-detail/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchAwardHeader();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateAwardHeader = async (id, data) => {
    try {
      const Resp = await axios.patch(`/riwards-detail/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchAwardHeader();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteAwardHeader = async (id) => {
    try {
      const Resp = await axios.delete(`/riwards-detail/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchAwardHeader();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  // Event header
  const FetchEventHeader = async () => {
    try {
      const response = await axios.get(`/event-detail/fetch`);
      dispatch({
        type: FETCH_EVENT_DETAILS_HEADER,
        payload: response.data, // Use response.data directly
      });
    } catch (error) {
      console.error("Error fetching home slider:", error);
    }
  };
  const CreateEventHeader = async (data) => {
    try {
      const Resp = await axios.post(`/event-detail/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type is set
        },
      });
      if (Resp?.data?.status === "success") {
        FetchEventHeader();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const UpdateEventHeader = async (id, data) => {
    try {
      const Resp = await axios.patch(`/event-detail/update/${id}`, data);
      if (Resp?.data?.status === "success") {
        FetchEventHeader();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  const DeleteEventdHeader = async (id) => {
    try {
      const Resp = await axios.delete(`/event-detail/delete/${id}`);
      if (Resp?.data?.status === "success") {
        FetchEventHeader();
        return toast.success(Resp?.data?.message, {
          position: "top-right",
        });
      }

      if (Resp?.data?.status === "fail")
        return toast.error(Resp?.data?.message, {
          position: "top-right",
        });
    } catch (error) {
      return toast.error("something went wrong", {
        position: "top-right",
      });
    }
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        LoginUser,
        DeleteEventdHeader,
        UpdateEventHeader,
        CreateEventHeader,
        FetchEventHeader,
        DeleteAwardHeader,
        UpdateAwardHeader,
        CreateAwardHeader,
        FetchAwardHeader,
        DeleteAwardsContents,
        FetchContentById,
        CreateAwardsContents,
        FetchAwardsContents,
        DeleteAwardsContent,
        UpdateAwardsContent,
        CreateAwardsContent,
        FetchAwardsContent,
        DeleteAwards,
        UpdateAwards,
        CreateAwards,
        FetchAwards,
        DeleteEventCover,
        UpdateEventCover,
        CreateEventCover,
        FetchRewardLists,
        FetchEventContentLists,
        DeleteEventContent,
        DeleteRewardContent,
        FetchEventCover,
        FetchHomeSlider,
        FetchPostsContents,
        DeletePostsContents,
        CreateEventsLists,
        CreateHomeSlider,
        FetchEventsById,
        UpdateHomeSlider,
        FetchHomeService,
        FetchEventsLists,
        UpdateHomeService,
        CreateHomeService,
        UpdateHomeBecomePartImages,
        CreateHomeBecomePartImages,
        FetchHomeBecomePartImages,
        CreateHomeCards,
        UpdateHomeCards,
        FetchHomeCards,
        CreateHomeLearnMore,
        FetchHomeLearnMore,
        UpdateHomeLearnMore,
        UpdateHomeStatistics,
        CreateHomeStatistics,
        FetchHomeStatistics,
        UpdateAboutSlider,
        CreateAboutSlider,
        FetchAboutSlider,
        UpdateAboutService,
        CreateAboutService,
        FetchAboutService,
        FetchAboutServiceDetail,
        CreateAboutServiceDetail,
        UpdateAboutServiceDetail,
        FetchAboutTeamImages,
        CreateAboutTeamImages,
        UpdateAboutTeamImages,
        CreateAboutChooseOurService,
        FetchAboutChooseOurService,
        UpdateAboutChooseOurService,
        FetchSupportService,
        CreateSupportService,
        UpdateSupportService,
        UpdateSupportHeaders,
        CreateSupportHeaders,
        FetchSupportHeaders,
        UpdateBlogService,
        FetchBlogService,
        CreateBlogService,
        UpdateBlogSlider,
        CreateBlogSlider,
        FetchBlogSlider,
        UpdateSupportDetails,
        CreateSupportDetails,
        FetchSupportDetails,
        UpdateBlogDetails,
        FetchBlogDetails,
        CreateBlogDetails,
        UpdateRecentPosts,
        CreateRecentPosts,
        FetchRecentPosts,
        UpdateStatisticsTitles,
        CreateStatisticsTitles,
        FetchStatisticsTitles,
        UpdateRestauranslider,
        CreateRestauranslider,
        FetchRestauranslider,
        UpdateRestaurantsService,
        CreateRestaurantsService,
        FetchRestaurantsService,
        UpdateRestaurantPartnership,
        CreateRestaurantPartnership,
        FetchRestaurantPartnership,
        DeleteHomeSlider,
        DeleteHomeService,
        DeleteHomeLearnMore,
        DeleteHomeStatistics,
        DeleteStatisticsTitles,
        DeleteHomeCards,
        DeleteHomeBecomePartImages,
        DeleteAboutSlider,
        DeleteAboutChooseOurService,
        DeleteAboutService,
        DeleteAboutServiceDetail,
        DeleteAboutTeamImages,
        DeleteSupportService,
        DeleteSupportHeaders,
        DeleteBlogService,
        DeleteBlogSlider,
        DeleteSupportDetails,
        DeleteRecentPosts,
        DeleteBlogDetails,
        DeleteRestaurantPartnership,
        DeleteRestaurantsService,
        DeleteRestauranslider,
        DeleteSupportFAQ,
        UpdateSupportFAQ,
        CreateSupportFAQ,
        FetchSupportFAQ,
        DeleteSupportDetailsFAQ,
        UpdateSupportDetailsFAQ,
        CreateSupportDetailsFAQ,
        FetchSupportDetailsFAQ,
        DeleteRikaabPartner,
        UpdateRikaabPartner,
        CreateRikaabPartner,
        FetchARikaabPartner,
        DeleteBecomeRikaabPartner,
        UpdateBecomeRikaabPartner,
        CreateBecomeRikaabPartner,
        FetchABecomeRikaabPartner,
        FetchABecomeRikaabPartnerById,
        LogoutUser,
        FetchSupportDetailsFAQById,
        FetchBlogLists,
        CreateBlogLists,
        CreateCustomersComments,
        DeleteHomeFAQ,
        UpdateHomeFAQ,
        CreateHomeFAQ,
        FetchHomeFAQById,
        FetchHomeFAQ,
        DeleteServiceSlider,
        UpdateServiceSlider,
        CreateServiceSlider,
        FetchServiceSlider,
        DeleteServiceCard,
        UpdateServiceCard,
        CreateServiceCard,
        FetchServiceCard,
        DeleteEmurabaha,
        UpdateEmurabaha,
        CreateEmurabaha,
        FetchEmurabaha,
        DeleteChooseUs,
        UpdateChooseUs,
        CreateChooseUs,
        FetchChooseUs,
        DeleteServiceFAQ,
        UpdateServiceFAQ,
        CreateServiceFAQ,
        FetchServiceFAQ,
        CreateGetnTouch,
        DeleteEvents,
        UpdateEvents,
        CreateEvents,
        FetchEvents,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
