import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";

const Ourservices = () => {
  const { FetchChooseUs, ChooseUsData } = useContext(AppContext);
  useEffect(() => {
    FetchChooseUs();
  }, []);

  return (
    <section className="py-10">
      <div className="container mx-auto px-6">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
          Why Choose Our Services
        </h2>
        <div className="space-y-6">
          {ChooseUsData?.map((service, index) => (
            <div
              key={index}
              className="bg-teal-800 text-white p-6 rounded-lg shadow-md"
            >
              <h3 className="font-bold text-lg md:text-xl">{service.title}</h3>
              <p className="text-base md:text-lg mt-2">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Ourservices;
