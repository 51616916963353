import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";
import { useMediaQuery } from "react-responsive";
const JoinUsSection = () => {
  const { FetchHomeBecomePartImages, HomeBecomeImages } =
    useContext(AppContext);

  useEffect(() => {
    FetchHomeBecomePartImages();
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const c = isMobile ? { marginTop: "0px" } : { marginTop: "0px" };
  // style={{  }} // Adjusted padding for alignment
  return (
    <>
      {HomeBecomeImages?.map((el) => (
        <section
          key={el.id}
          className="flex items-center"
          style={{
            backgroundImage: `url(${el.coverImage.fileName})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            height: "auto",
            minHeight: "532px",
            transition: "background-image .5s ease-in-out",
            paddingRight: "15%", paddingLeft: "13%", ...c
          }}
        >
          <div className="container mx-auto text-center flex flex-wrap justify-between items-center ">
            <div className="w-full lg:w-1/2 p-6 text-left">
              <h2
                className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-4"
                dangerouslySetInnerHTML={{ __html: el.title }}
              ></h2>
              <p
                className="text-white mb-8 text-sm md:text-base"
                dangerouslySetInnerHTML={{ __html: el.description }}
              ></p>
              <Link
                to="https://wa.me/614222000"
                className="main-color text-white px-4 md:px-6 py-2 md:py-3 rounded-full text-sm md:text-base"
              >
                <span className="mr-3">WhatsApp</span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
            <div className="w-full lg:w-1/2 p-6 flex justify-center items-center">
              <LazyLoad height={532} offset={100} once>
                <img
                  src={el.partImage.fileName}
                  alt="WhatsApp Chatbot Icon"
                  className="w-full max-w-xs md:max-w-md lg:max-w-lg"
                />
              </LazyLoad>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default JoinUsSection;
