import React from "react";

const Servicess = () => {
  const services = [
    {
      title: "All-in-One Platform",
      description:
        "Access a wide variety of services in one convenient location, saving you time and effort.",
    },
    {
      title: "Customer-Centric Approach",
      description:
        "We prioritize your satisfaction, offering dedicated support and personalized service to meet your needs.",
    },
    {
      title: "Innovation and Quality",
      description:
        "Our commitment to technological advancement ensures you enjoy a seamless and efficient experience with every service.",
    },
    {
      title: "Trustworthy and Reliable",
      description:
        "Count on Rikaab for consistent quality, safety, and reliability across all our offerings.",
    },
  ];

  return (
    <section className="py-10">
      <div className="container mx-auto px-6">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
          Why Choose Our Services
        </h2>
        <div className="space-y-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-teal-800 text-white p-6 rounded-lg shadow-md"
            >
              <h3 className="font-bold text-lg md:text-xl">{service.title}:</h3>
              <p className="text-base md:text-lg mt-2">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Servicess;
