import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const JoinRikaab = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
const c = isMobile ? { marginTop: "0px" } : { marginTop: "155px" };
  return (
    <div className="bg-[#27424C] py-8 md:py-16"  style={{ paddingRight: "15%", paddingLeft: "19%", ...c }}  >
      <div className="container mx-auto px-4 md:px-8 text-center md:text-left">
        <h2 className="text-2xl md:text-3xl font-bold text-white leading-tight">
          Want to be a part of <br className="hidden md:block" /> Rikaab?
        </h2>
        <p className="text-white mt-4 mb-8 text-sm md:text-base">
          Reach us using our WhatsApp chatbot
        </p>

        <Link
          to="https://wa.me/614222000"
          className="main-color text-white px-4 md:px-6 py-2 md:py-3 rounded-full text-sm md:text-base inline-flex items-center justify-center"
        >
          <span className="mr-2 md:mr-3">WhatsApp</span>
          <i className="fa-solid fa-arrow-right-long"></i>
        </Link>
      </div>
    </div>
  );
};

export default JoinRikaab;
