import React, { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";

import "../Styles/About.css";
import { AppContext } from "../Context/Provider";

const itemsPerSlide = 3;
const autoSlideInterval = 3000; // 3 seconds

const ExpertTeam = () => {
  const { FetchAboutTeamImages, TeamImages } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    FetchAboutTeamImages();
  }, []); // Empty dependency array to avoid re-running

  useEffect(() => {
    const slideInterval = setInterval(() => {
      handleNext();
    }, autoSlideInterval);

    return () => clearInterval(slideInterval); // Cleanup on component unmount
  }, [activeIndex]); // Re-run only when activeIndex changes

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleSelect = (index) => {
    setActiveIndex(index);
  };

  // Generate slides using TeamImages or fallback to defaultSlides
  const imagesToShow = TeamImages;
  const slides = [];

  for (let i = 0; i < imagesToShow.length; i += itemsPerSlide) {
    slides.push(imagesToShow.slice(i, i + itemsPerSlide));
  }

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-500"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
              {slides.map((slide, slideIndex) => (
                <div key={slideIndex} className="flex-shrink-0 w-full">
                  <div className="flex">
                    {slide.map((image, index) => (
                      <div key={index} className="w-1/3 p-2">
                        <LazyLoad height={300} offset={100}>
                          <img
                            src={image.fileName}
                            alt={image.alt}
                            className="w-full h-auto object-cover rounded-lg"
                          />
                        </LazyLoad>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            type="button"
            className="absolute top-1/2 left-0 transform -translate-y-1/2 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={handlePrev}
            data-carousel-prev
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#054752] group-hover:bg-[#054752]/80 group-focus:ring-4 group-focus:ring-[#054752]/50 group-focus:outline-none">
              <svg
                className="w-4 h-4 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="sr-only">Previous</span>
            </span>
          </button>
          <button
            type="button"
            className="absolute top-1/2 right-0 transform -translate-y-1/2 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={handleNext}
            data-carousel-next
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#054752] group-hover:bg-[#054752]/80 group-focus:ring-4 group-focus:ring-[#054752]/50 group-focus:outline-none">
              <svg
                className="w-4 h-4 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="sr-only">Next</span>
            </span>
          </button>
        </div>
        <div className="flex justify-center mt-4">
          {slides.map((_, slideIndex) => (
            <button
              key={slideIndex}
              className={`w-3 h-3 rounded-full mx-1 ${
                activeIndex === slideIndex ? "bg-custom-green" : "bg-gray-400"
              }`}
              onClick={() => handleSelect(slideIndex)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExpertTeam;
