import React from "react";
import { useNavigate } from "react-router-dom";
import error400cover from "../../images/error400-cover.png";

const NotFound = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/"); // Navigate to the home page
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center p-4 md:p-0">
        <img
          src={error400cover}
          alt="Error 404"
          className="mx-auto mb-8 w-full max-w-md"
        />
        <h3 className="text-2xl font-bold uppercase text-gray-800">
          Sorry, Page not Found 😭
        </h3>
        <p className="text-gray-600 mb-6">
          The page you are looking for is not available!
        </p>
        <button
          onClick={handleRedirect}
          className="px-4 py-2 bg-custom-green text-white rounded hover:bg-green-600"
        >
          <i className="mdi mdi-home me-1"></i> Back to Home Page
        </button>
      </div>
    </div>
  );
};

export default NotFound;
