import React, { useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const StatisticsSection = () => {
  const {
    FetchHomeStatistics,
    HomeStatisticsData,
    FetchStatisticsTitles,
    StatisticsTitleData,
  } = useContext(AppContext);

  useEffect(() => {
    FetchHomeStatistics();
    FetchStatisticsTitles();
  }, []);

  return (
    <section className="py-20 bg-white font-poppins">
      <div className="container mx-auto text-center">
        {StatisticsTitleData?.map((item, index) => (
          <div key={index} className="mb-8">
            <span className="inline-block bg-green-100 text-green-500 font-semibold mb-2 px-2 py-1 rounded">
              NUMBERS
            </span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 my-4">
              {item.title}
            </h2>
            <p
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        ))}
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-4">
          {HomeStatisticsData?.map((stat, index) => (
            <div key={index} className="p-10 text-center">
              <LazyLoad height={50} offset={100} once>
                <img
                  src={stat.fileName}
                  alt={`Stat Icon`}
                  className="mx-auto mb-4 w-8 h-auto"
                />
              </LazyLoad>
              <h3 className="text-2xl font-bold text-green-500 mb-2">
                {stat.name === "Payments"
                  ? `$${stat.count.toLocaleString()}`
                  : stat.count.toLocaleString()}
              </h3>
              <p className="text-gray-600">{stat.name}</p>
            </div>
          ))}
        </div> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 px-4">
          {HomeStatisticsData?.map((stat, index) => (
            <div key={index} className="p-10 text-center">
              <LazyLoad height={50} offset={100} once>
                <img
                  src={stat.fileName}
                  alt={`Stat Icon`}
                  className="mx-auto mb-4 w-8 h-auto"
                />
              </LazyLoad>
              <h3 className="text-2xl font-bold text-green-500 mb-2">
                {stat.name === "Payments"
                  ? `$${stat.count.toLocaleString()}`
                  : stat.count.toLocaleString()}
              </h3>
              <p className="text-gray-600">{stat.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
