import React, { useEffect, useContext } from "react";
import "../Styles/Service.css"; // Import the CSS file
import LazyLoad from "react-lazyload";
import boostIcon from "../images/boosticon2.png";
import earnIcon from "../images/EarnIcon.png";
import settingIcon from "../images/settingIcon.png";
import tax_car from "../images/tax_car.png";
import { AppContext } from "../Context/Provider";
import ServiceItem from "./ServiceItems";

const services = [
  {
    icon: boostIcon,
    title: "Instant Bookings",
    description:
      "With our user-friendly app, you can book a taxi in seconds. Whether you're commuting to work or exploring the city, our service ensures a hassle-free experience.",
  },
  {
    icon: earnIcon,
    title: "Reliable Drivers",
    description:
      "Our network of professional drivers is dedicated to delivering safe, timely, and courteous service, ensuring you reach your destination comfortably.",
  },
  {
    icon: settingIcon,
    title: "Real-Time Tracking",
    description:
      "Stay informed with our GPS tracking feature, allowing you to monitor your ride's progress and estimated arrival time.",
  },
  {
    icon: settingIcon,
    title: "Flexible Payment Options",
    description:
      "Choose from multiple payment methods, including cash, credit/debit cards, and digital wallets, for a smooth transaction process.",
  },
];

const ServiceDriver = () => {
  const { FetchHomeLearnMore, HomeLearnMoreData } = useContext(AppContext);

  useEffect(() => {
    FetchHomeLearnMore();
  }, []);

  return (
    <section className="py-10 learn-about-us-section">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-6">
        <div className="md:w-1/2 flex justify-center items-center">
          <LazyLoad height={180} offset={100} once key={tax_car}>
            <img src={tax_car} alt="Rikaab App Screenshot" width="500" />
          </LazyLoad>
        </div>
        <div className="md:w-1/2 flex flex-col justify-center h-full mt-20">
          {services.map((service, index) => (
            <ServiceItem
              key={index}
              icon={service.icon}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceDriver;
