// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// import "../App.css";
// import logo from "../images/headerlogo.png";

// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [activeLink, setActiveLink] = useState("");
//   const [imageSize, setImageSize] = useState("h-8");

//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleLinkClick = (link) => {
//     window.scrollTo(0, 0);
//     setActiveLink(link);
//     setIsMenuOpen(false);
//   };

//   const handleNavigate = () => {
//     window.location.href = "https://user.rikaab.com";
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       const newSize = window.innerWidth >= 768 ? "h-8" : "h-6";
//       if (newSize !== imageSize) {
//         setImageSize(newSize);
//       }
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize();

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   const handleScrollToTop = () => {
//     window.scrollTo(0, 0);
//   };
//   return (
//     <div>
//       <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 mb-10">
//         <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
//           <div className="flex items-center">
//             <Link to="/" onClick={handleScrollToTop}>
//               <img
//                 src={logo}
//                 className={`transition-all duration-300 ${imageSize}`}
//                 alt="Logo"
//               />
//             </Link>
//           </div>

//           <div className="flex md:order-2 space-x-3 md:space-x-0">
//             <Link
//               onClick={handleNavigate}
//               style={{ backgroundColor: "#4caf4f" }}
//               className="btn-shop-now text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Start your journey today!
//               <i className="fa-solid fa-arrow-right ms-1 mt-1 fs-16"></i>
//             </Link>
//             <button
//               onClick={handleMenuToggle}
//               className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//               aria-controls="navbar-sticky"
//               aria-expanded={isMenuOpen}
//             >
//               <span className="sr-only">Open main menu</span>
//               <svg
//                 className={`w-5 h-5 ${isMenuOpen ? "hidden" : ""}`}
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 17 14"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M1 1h15M1 7h15M1 13h15"
//                 />
//               </svg>
//               <svg
//                 className={`w-5 h-5 ${isMenuOpen ? "" : "hidden"}`}
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               >
//                 <path d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//           </div>
//           <div
//             className={`items-center justify-between ${
//               isMenuOpen ? "flex" : "hidden"
//             } w-full md:flex md:w-auto md:order-1`}
//             id="navbar-sticky"
//           >
//             <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 w-full md:w-auto">
//               <li className="w-full">
//                 <Link
//                   to="/"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "home"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500`}
//                   onClick={() => handleLinkClick("home")}
//                   aria-current="page"
//                 >
//                   Home
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/service"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "service"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500`}
//                   onClick={() => handleLinkClick("service")}
//                   aria-current="page"
//                 >
//                   Service
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/About"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "about"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
//                   onClick={() => handleLinkClick("about")}
//                 >
//                   About
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/Support"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "support"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
//                   onClick={() => handleLinkClick("support")}
//                 >
//                   Support
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/Blogs"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "blog"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
//                   onClick={() => handleLinkClick("blog")}
//                 >
//                   Blog
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//       <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 mb-10">
//         <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
//           <div className="flex items-center">
//             <Link to="/" onClick={handleScrollToTop}>
//               <img
//                 src={logo}
//                 className={`transition-all duration-300 ${imageSize}`}
//                 alt="Logo"
//               />
//             </Link>
//           </div>

//           <div className="flex md:order-2 space-x-3 md:space-x-0">
//             <Link
//               onClick={handleNavigate}
//               style={{ backgroundColor: "#4caf4f" }}
//               className="btn-shop-now text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//             >
//               Start your journey today!
//               <i className="fa-solid fa-arrow-right ms-1 mt-1 fs-16"></i>
//             </Link>
//             <button
//               onClick={handleMenuToggle}
//               className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//               aria-controls="navbar-sticky"
//               aria-expanded={isMenuOpen}
//             >
//               <span className="sr-only">Open main menu</span>
//               <svg
//                 className={`w-5 h-5 ${isMenuOpen ? "hidden" : ""}`}
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 17 14"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M1 1h15M1 7h15M1 13h15"
//                 />
//               </svg>
//               <svg
//                 className={`w-5 h-5 ${isMenuOpen ? "" : "hidden"}`}
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               >
//                 <path d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//           </div>
//           <div
//             className={`items-center justify-between ${
//               isMenuOpen ? "flex" : "hidden"
//             } w-full md:flex md:w-auto md:order-1`}
//             id="navbar-sticky"
//           >
//             <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 w-full md:w-auto">
//               <li className="w-full">
//                 <Link
//                   to="/"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "home"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500`}
//                   onClick={() => handleLinkClick("home")}
//                   aria-current="page"
//                 >
//                   Home
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/service"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "service"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500`}
//                   onClick={() => handleLinkClick("service")}
//                   aria-current="page"
//                 >
//                   Service
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/About"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "about"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
//                   onClick={() => handleLinkClick("about")}
//                 >
//                   About
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/Support"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "support"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
//                   onClick={() => handleLinkClick("support")}
//                 >
//                   Support
//                 </Link>
//               </li>
//               <li className="w-full">
//                 <Link
//                   to="/Blogs"
//                   className={`block py-2 px-3 w-full ${
//                     activeLink === "blog"
//                       ? "text-green-700 bg-white"
//                       : "text-gray-900"
//                   } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
//                   onClick={() => handleLinkClick("blog")}
//                 >
//                   Blog
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// };

// export default Navbar;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../App.css";
import logo from "../images/headerlogo.png";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [imageSize, setImageSize] = useState("h-8");
  const [isBlogSubmenuOpen, setIsBlogSubmenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    window.scrollTo(0, 0);
    setActiveLink(link);
    setIsMenuOpen(false);
  };

  const handleNavigate = () => {
    window.location.href = "https://user.rikaab.com";
  };

  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth >= 768 ? "h-8" : "h-6";
      if (newSize !== imageSize) {
        setImageSize(newSize);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [imageSize]);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 mb-10">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <div className="flex items-center">
            <Link to="/" onClick={handleScrollToTop}>
              <img
                src={logo}
                className={`transition-all duration-300 ${imageSize}`}
                alt="Logo"
              />
            </Link>
          </div>

          <div className="flex md:order-2 space-x-3 md:space-x-0">
            <Link
              onClick={handleNavigate}
              style={{ backgroundColor: "#4caf4f" }}
              className="btn-shop-now text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Start your journey today!
              <i className="fa-solid fa-arrow-right ms-1 mt-1 fs-16"></i>
            </Link>
            <button
              onClick={handleMenuToggle}
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded={isMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-5 h-5 ${isMenuOpen ? "hidden" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
              <svg
                className={`w-5 h-5 ${isMenuOpen ? "" : "hidden"}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between ${
              isMenuOpen ? "flex" : "hidden"
            } w-full md:flex md:w-auto md:order-1`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 w-full md:w-auto">
              <li className="w-full">
                <Link
                  to="/"
                  className={`block py-2 px-3 w-full ${
                    activeLink === "home"
                      ? "text-green-700 bg-white"
                      : "text-gray-900"
                  } rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500`}
                  onClick={() => handleLinkClick("home")}
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="w-full">
                <Link
                  to="/service"
                  className={`block py-2 px-3 w-full ${
                    activeLink === "service"
                      ? "text-green-700 bg-white"
                      : "text-gray-900"
                  } rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500`}
                  onClick={() => handleLinkClick("service")}
                  aria-current="page"
                >
                  Service
                </Link>
              </li>
              <li className="w-full">
                <Link
                  to="/About"
                  className={`block py-2 px-3 w-full ${
                    activeLink === "about"
                      ? "text-green-700 bg-white"
                      : "text-gray-900"
                  } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                  onClick={() => handleLinkClick("about")}
                >
                  About
                </Link>
              </li>
              <li
                className="relative"
                onMouseEnter={() => setIsBlogSubmenuOpen(true)}
                onMouseLeave={() => setIsBlogSubmenuOpen(false)}
              >
                <div className="flex items-center space-x-1 text-gray-700 hover:text-green-700">
                  <span>Media</span>
                  {isBlogSubmenuOpen ? (
                    <FaChevronUp className="text-sm" />
                  ) : (
                    <FaChevronDown className="text-sm" />
                  )}
                </div>

                {isBlogSubmenuOpen && (
                  <div className="absolute left-0  w-48 bg-white rounded-lg shadow-lg z-10">
                    <ul className="flex flex-col space-y-1 p-2">
                      <li>
                        <Link
                          to="/Blogs"
                          className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded"
                          onClick={() => setIsBlogSubmenuOpen(false)}
                        >
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/awards"
                          className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded"
                          onClick={() => setIsBlogSubmenuOpen(false)}
                        >
                          Awards
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/events"
                          className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded"
                          onClick={() => setIsBlogSubmenuOpen(false)}
                        >
                          Events
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li>

              <li className="w-full">
                <Link
                  to="/Support"
                  className={`block py-2 px-3 w-full ${
                    activeLink === "support"
                      ? "text-green-700 bg-white"
                      : "text-gray-900"
                  } dark:text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                  onClick={() => handleLinkClick("support")}
                >
                  Support
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
