import React, { useEffect, useContext } from "react";
import "../../Styles/Service.css"; // Import the CSS file
import LazyLoad from "react-lazyload";
import boostIcon from "../../images/boosticon2.png";
import rikab from "../../images/tax_car.png";
import earnIcon from "../../images/EarnIcon.png";
import settingIcon from "../../images/settingIcon.png";
import { AppContext } from "../../Context/Provider";

const Driverss = () => {
  const { FetchHomeLearnMore, HomeLearnMoreData } = useContext(AppContext);

  useEffect(() => {
    FetchHomeLearnMore();
  }, []);

  return (
    <section className="py-10 learn-about-us-section">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-6">
        <div className="md:w-1/2 flex justify-center items-center">
          {HomeLearnMoreData?.map((img) => {
            return (
              <LazyLoad height={180} offset={100} once key={img.fileName}>
                <img src={rikab} alt="Rikaab App Screenshot" width="500" />
              </LazyLoad>
            );
          })}
        </div>
        <div className="md:w-1/2 flex flex-col justify-center h-full mt-20">
          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-200 mr-4">
              <LazyLoad offset={100}>
                <img
                  src={boostIcon}
                  alt="Variety of Cuisines"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Instant Bookings
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                With our user-friendly app, you can book a taxi in seconds.
                Whether you're commuting to work or exploring the city, our
                service ensures a hassle-free experience.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-200 mr-4">
              <LazyLoad offset={100}>
                <img src={earnIcon} alt="Easy Ordering" className="w-8 h-8" />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Reliable Drivers
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Our network of professional drivers is dedicated to delivering
                safe, timely, and courteous service, ensuring you reach your
                destination comfortably.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-200 mr-4">
              <LazyLoad offset={100}>
                <img
                  src={settingIcon}
                  alt="Wide Selection"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Real-Time Tracking
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Stay informed with our GPS tracking feature, allowing you to
                monitor your ride's progress and estimated arrival time.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-200 mr-4">
              <LazyLoad offset={100}>
                <img
                  src={settingIcon}
                  alt="Convenient Shopping"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Flexible Payment Options
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Choose from multiple payment methods, including cash,
                credit/debit cards, and digital wallets, for a smooth
                transaction process.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-200 mr-4">
              <LazyLoad offset={100}>
                <img
                  src={settingIcon}
                  alt="Competitive Prices"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-bold text-xl md:text-2xl">
                Competitive Prices
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Enjoy great deals and savings on a wide array of products, with
                options to suit every budget.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Driverss;
