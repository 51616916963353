import React, { useEffect } from "react";
import HeroSection from "../../Components/SupportHeroSection";
import GallerySection from "../../Components/SupportGalarrySectiontion";
import FAQSection from "../../Components/SupportFAQSection";
// import FAQSectionQuestions from "../../Components/SupportFAQSection 1";
import Footer from "../../Components/Footer";
import SupportsFAQs from "../../Components/SupportsFAQs";
import GetInTouch from "../../Components/GetInTouch";
import JoinRikaab from "../../Components/Join-Rikaab";
function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroSection />
      <GallerySection />
      <GetInTouch />
      <JoinRikaab />
      {/* <FAQSection /> */}
      <SupportsFAQs />
      <Footer />
    </div>
  );
}

export default Support;
