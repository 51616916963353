import React, { useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";

const AchieveSection = () => {
  const { FetchRestaurantsService, RestaurantServiceData } =
    useContext(AppContext);

  useEffect(() => {
    FetchRestaurantsService();
  }, []);

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4 md:px-6">
        <div className="relative my-8 flex flex-col items-start">
          <div className="mb-8">
            <h2 className="text-2xl md:text-4xl font-bold text-gray-800">
              Achieve more with{" "}
              <span className="text-custom-green">Rikaab</span> Food
            </h2>
            <p className="mt-4 text-base md:text-lg text-gray-600">
              Track your key performance indicators and make data-driven
              decisions with Rikaab Food.
            </p>
          </div>
          <div className="flex flex-wrap -mx-4">
            {RestaurantServiceData?.map((service) =>
              service.details.map((detail) => (
                <div
                  key={detail._id}
                  className="w-full sm:w-1/2 lg:w-1/2 px-4 mb-8"
                >
                  <div className="w-full bg-white px-6 py-6 shadow-md h-full">
                    <div className="flex items-start relative">
                      <div className="absolute left-0 top-0 h-full border-l-8 border-[#B3D9AD]"></div>
                      <div className="pl-12">
                        <div className="text-xl md:text-2xl font-medium text-gray-800">
                          {detail.title}
                        </div>
                        <ul className="list-disc pl-5 mt-4 text-base md:text-lg text-gray-600">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: detail.description,
                            }}
                          ></div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AchieveSection;
