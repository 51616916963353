import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "../Styles/Service.css"; // Import the CSS file
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";
import { useMediaQuery } from "react-responsive";
const LearnAboutUsSection = () => {
  const { FetchHomeLearnMore, HomeLearnMoreData } = useContext(AppContext);
  useEffect(() => {
    FetchHomeLearnMore();
  }, []);
  const truncateData = (element) => {
    const words = element.split(" ");
    if (words.length > 50) {
      return words.slice(0, 50).join(" ") + " ...";
    }
    return element;
  };
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const c = isMobile ? { marginTop: "0px" } : { marginTop: "0px" };
  return (
    <section className="py-10 learn-about-us-section" style={{
      paddingRight: "15%", paddingLeft: "12%", ...c
    }}>
      <div className="container mx-auto flex flex-wrap items-center justify-center md:justify-between px-6">
        {/* Left Image */}
        <div className="w-full md:w-1/2 lg:w-2/5 mb-8 md:mb-0">
          {/* <LazyLoad height={180} offset={100} once>
            <img
              src={AboutUsImage}
              alt="Phone Image"
              className="rounded-lg p-6 md:p-12"
              style={{ width: "667px", height: "557px" }}
            /> */}

          {HomeLearnMoreData?.map((img) => {
            return (
              <LazyLoad height={180} offset={100} once>
                <img
                  src={img.fileName}
                  alt="WhatsApp Chatbot Icon"
                  width="500"
                />
              </LazyLoad>
            );
          })}
        </div>

        {/* Right Text and Button */}
        {HomeLearnMoreData?.map((item) => {
          return (
            <div className="w-full md:w-1/2 lg:w-3/5 text-center md:text-left md:pl-10 lg:pl-20">
              <h2
                className="text-sm text-green-500 font-semibold mb-2 w-20 text-center rounded-sm"
                style={{ backgroundColor: "#dcfce7" }}
              >
                FEATURES
              </h2>
              <h3
                className="text-2xl md:text-4xl font-bold mb-4"
                dangerouslySetInnerHTML={{ __html: truncateData(item.title) }}
              ></h3>
              <p
                className="text-gray-700 mb-6"
                dangerouslySetInnerHTML={{
                  __html: truncateData(item.description),
                }}
              ></p>
              <Link
                to="/about" // Adjust the path to the internal route you want to link to
                className="inline-block main-color text-white px-6 py-3 rounded-full text-base hover:bg-green-600 transition duration-300"
              >
                About Us{" "}
                <i className="fa-solid fa-chevron-right text-sm ml-5"></i>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default LearnAboutUsSection;
