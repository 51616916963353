import React from "react";
import CallIcon from "../images/Call_icon.png"; // Adjust path as needed

const CallSection = () => {
  return (
    <div
      className="flex items-center justify-center bg-[#26AA49] mt-10"
      style={{ height: "200px" }}
    >
      <div className="text-center text-white">
        <p className="text-sm mb-2">Call 24 Hour Service Available</p>
        <h1 className="text-2xl font-bold mb-3">
          Call Now & Book Your Taxi <br />
          <span className="mt-6">For Your Next Ride</span>
        </h1>
        <div className="flex items-center justify-center">
          <img src={CallIcon} alt="Phone Icon" className="w-6 h-6 mr-2" />
          {/* <span className="text-xl font-bold">(252) 618344570</span> */}
          <span className="text-xl font-bold">7000</span>
        </div>
      </div>
    </div>
  );
};

export default CallSection;
