import React, { useEffect } from "react";
import Footer from "../../Components/Footer";

import CallSection from "../../Components/BlogCallSection";
import EventSection from "../../Components/EventArticle";
import EventsCovers from "../../Components/EventCovr";
function EventsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <EventsCovers />
      <EventSection />
      <CallSection />
      <Footer />
    </div>
  );
}

export default EventsPage;
