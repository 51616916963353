import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

function RikabFoodsSections() {
  const { FetchRestaurantPartnership, RestaurantPartnershipData } =
    useContext(AppContext);

  useEffect(() => {
    FetchRestaurantPartnership();
  }, []); // Include FetchRestaurantPartnership in dependency array

  return (
    <div className="container mx-auto px-4 md:px-6 bg-white">
      <div className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 mt-12">
        <div className="w-full md:w-1/2">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 leading-tight">
            Our partnerships expand beyond restaurants
          </h2>
        </div>
        <div className="hidden md:flex w-full md:w-1/2 h-[59px] justify-end">
          <Link
            to="#"
            className="bg-custom-green text-white px-6 py-3 md:px-12 md:py-2 rounded-md hover:bg-green-400 text-center text-base md:text-lg font-medium"
          >
            Become Rikaab Food partner now
          </Link>
        </div>
      </div>
      <div className="flex flex-wrap mt-8 mb-12">
        {RestaurantPartnershipData?.map((partnership, index) => (
          <div
            key={index}
            className="w-full sm:w-1/2 lg:w-1/4 p-4 flex flex-col"
          >
            <LazyLoad offset={100}>
              <img
                src={partnership.fileName}
                alt={partnership.title}
                className="w-full h-48 md:h-60 object-cover rounded-lg"
              />
            </LazyLoad>
            <div className="mt-4">
              <h3 className="text-lg md:text-xl font-bold text-gray-800">
                {partnership.title}
              </h3>
              <p className="text-gray-600 text-sm md:text-base">
                {partnership.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden flex justify-center mt-6">
        <Link
          to="#"
          className="bg-custom-green text-white px-6 py-3 rounded-md hover:bg-green-400 text-center text-base md:text-lg font-medium"
        >
          Become Rikaab Food partner now
        </Link>
      </div>
    </div>
  );
}

export default RikabFoodsSections;
