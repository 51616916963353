import {
  BECOME_RIKAAB_PARTNERS,
  BECOME_RIKAAB_PARTNERS_BY_ID,
  BLOG_POST_DETAILS,
  BLOG_POST_DETAILS_BY_ID,
  CHOOSE_US,
  FETCH_ABOUT_SERVICE,
  FETCH_ABOUT_SERVICE_DRTAILS,
  FETCH_ABOUT_SLIDER,
  FETCH_ABOUT_TEAMS_IMAGES,
  FETCH_ABOUT_WHY_US,
  FETCH_AWARD_DETAILS_HEADER,
  FETCH_AWARDS_CONTENTS,
  FETCH_AWARDS_LISTS,
  FETCH_AWARDS_POSTS,
  FETCH_AWARDS_POSTS_BY_ID,
  FETCH_BLOG_DETAILS,
  FETCH_BLOG_SERVICES,
  FETCH_BLOG_SLIDER,
  FETCH_EMURABAHA,
  FETCH_EVENT_DETAILS_HEADER,
  FETCH_EVENTS,
  FETCH_EVENTS_COVER,
  FETCH_EVENTS_LISTS,
  FETCH_EVENTS_LISTS_BY_ID,
  FETCH_EVENTS_LISTS_DATA,
  FETCH_HOME_BECOME_CARDS,
  FETCH_HOME_BECOME_IMAGES,
  FETCH_HOME_LEARN_MORE,
  FETCH_HOME_SERVICE,
  FETCH_HOME_SLIDER,
  FETCH_HOME_STATISTICS,
  FETCH_HOME_STATISTISCS_TITLES,
  FETCH_RECENTLY_POSTS,
  FETCH_RESTAURANST_HEADERS,
  FETCH_RESTAURANST_PARTNERSHIPS,
  FETCH_RESTAURANST_SERVICE,
  FETCH_RESTAURANST_SERVICE_LOADING,
  FETCH_REWARD_LISTS,
  FETCH_SERVICE_CARD,
  FETCH_SERVICE_SLIDER,
  FETCH_SUPPORT_DETAILS,
  FETCH_SUPPORT_HEADERS,
  FETCH_SUPPORT_SERVICES,
  HOME_FAQ,
  HOME_FAQ_BY_ID,
  RIKAAB_PARTNERS,
  SERVICE_FAQS,
  SUPPORT_DETAILS_FAQ,
  SUPPORT_DETAILS_FAQ_BY_ID,
  SUPPORT_FAQ,
} from "./Action";

const reducer = (state, action) => {
  const { type, payload } = action;
  if (type === FETCH_HOME_SLIDER) {
    return {
      ...state,
      HomeSlider: payload.data,
    };
  }
  if (type === FETCH_HOME_SERVICE) {
    return {
      ...state,
      HomeServices: payload.data,
    };
  }
  if (type === FETCH_HOME_BECOME_IMAGES) {
    return {
      ...state,
      HomeBecomeImages: payload.data,
    };
  }
  if (type === FETCH_HOME_BECOME_CARDS) {
    return {
      ...state,
      HomeBecomeCards: payload.data,
    };
  }
  if (type === FETCH_HOME_LEARN_MORE) {
    return {
      ...state,
      HomeLearnMoreData: payload.data,
    };
  }
  if (type === FETCH_HOME_STATISTICS) {
    return {
      ...state,
      HomeStatisticsData: payload.data,
    };
  }
  if (type === FETCH_ABOUT_SLIDER) {
    return {
      ...state,
      AboutSliderData: payload.data,
    };
  }
  if (type === FETCH_ABOUT_SERVICE) {
    return {
      ...state,
      AboutServiceData: payload.data,
    };
  }
  if (type === FETCH_ABOUT_SERVICE_DRTAILS) {
    return {
      ...state,
      AboutServiceDetailsData: payload.data,
    };
  }
  if (type === FETCH_ABOUT_TEAMS_IMAGES) {
    return {
      ...state,
      TeamImages: payload.data,
    };
  }
  if (type === FETCH_ABOUT_WHY_US) {
    return {
      ...state,
      ChooseOurservice: payload.data,
    };
  }
  if (type === FETCH_SUPPORT_SERVICES) {
    return {
      ...state,
      SupportOurservice: payload.data,
    };
  }
  if (type === FETCH_SUPPORT_HEADERS) {
    return {
      ...state,
      SupportHeaderData: payload.data,
    };
  }
  if (type === FETCH_BLOG_SERVICES) {
    return {
      ...state,
      BlogServiceData: payload.data,
    };
  }
  if (type === FETCH_BLOG_SLIDER) {
    return {
      ...state,
      BlogSlider: payload.data,
    };
  }
  if (type === FETCH_SUPPORT_DETAILS) {
    return {
      ...state,
      SupportDetailsData: payload.data,
    };
  }
  if (type === FETCH_BLOG_DETAILS) {
    return {
      ...state,
      BlogDetailsData: payload.data,
    };
  }
  if (type === FETCH_RECENTLY_POSTS) {
    return {
      ...state,
      RecentlyPosts: payload.data,
    };
  }
  if (type === FETCH_HOME_STATISTISCS_TITLES) {
    return {
      ...state,
      StatisticsTitleData: payload.data,
    };
  }
  if (type === FETCH_RESTAURANST_HEADERS) {
    return {
      ...state,
      RestauransHeaderData: payload.data,
    };
  }
  if (type === FETCH_RESTAURANST_SERVICE_LOADING) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (type === FETCH_RESTAURANST_SERVICE) {
    return {
      ...state,
      RestaurantServiceData: payload.data,
      isLoading: false,
    };
  }
  if (type === FETCH_RESTAURANST_PARTNERSHIPS) {
    return {
      ...state,
      RestaurantPartnershipData: payload.data,
    };
  }
  if (type === SUPPORT_FAQ) {
    return {
      ...state,
      SupportFAQ: payload.data,
    };
  }
  if (type === SUPPORT_DETAILS_FAQ) {
    return {
      ...state,
      SupportDetailFAQ: payload.data,
    };
  }
  if (type === RIKAAB_PARTNERS) {
    return {
      ...state,
      RikaabPartner: payload.data,
    };
  }
  if (type === BECOME_RIKAAB_PARTNERS) {
    return {
      ...state,
      BecomeRikaabPartner: payload.data,
    };
  }
  if (type === BECOME_RIKAAB_PARTNERS_BY_ID) {
    return {
      ...state,
      RikaabPartnerData: payload.data,
    };
  }
  if (type === SUPPORT_DETAILS_FAQ_BY_ID) {
    return {
      ...state,
      FAQbyID: payload.data,
    };
  }
  if (type === BLOG_POST_DETAILS) {
    return {
      ...state,
      BlogLists: payload.data,
    };
  }
  if (type === BLOG_POST_DETAILS_BY_ID) {
    return {
      ...state,
      PostsListsById: payload.data,
    };
  }
  if (type === HOME_FAQ) {
    return {
      ...state,
      FAQData: payload.data,
    };
  }
  if (type === HOME_FAQ_BY_ID) {
    return {
      ...state,
      FAQDataById: payload.data,
    };
  }
  if (type === FETCH_SERVICE_SLIDER) {
    return {
      ...state,
      ServiceSliderData: payload.data,
    };
  }
  if (type === FETCH_SERVICE_CARD) {
    return {
      ...state,
      ServiceCardData: payload.data,
    };
  }
  if (type === FETCH_EMURABAHA) {
    return {
      ...state,
      MurabahaData: payload.data,
    };
  }
  if (type === CHOOSE_US) {
    return {
      ...state,
      ChooseUsData: payload.data,
    };
  }
  if (type === SERVICE_FAQS) {
    return {
      ...state,
      ServiceFAQData: payload.data,
    };
  }
  if (type === FETCH_EVENTS) {
    return {
      ...state,
      EventData: payload.data,
    };
  }
  if (type === FETCH_EVENTS_LISTS) {
    return {
      ...state,
      EventListData: payload.data,
    };
  }
  if (type === FETCH_EVENTS_LISTS_BY_ID) {
    return {
      ...state,
      EventById: payload.data,
    };
  }
  if (type === FETCH_EVENTS_COVER) {
    return {
      ...state,
      EventCoverData: payload.data,
    };
  }
  if (type === FETCH_REWARD_LISTS) {
    return {
      ...state,
      RewardContentData: payload.data,
    };
  }
  if (type === FETCH_EVENTS_LISTS_DATA) {
    return {
      ...state,
      EventContentData: payload.data,
    };
  }
  if (type === FETCH_AWARDS_LISTS) {
    return {
      ...state,
      AwardsData: payload.data,
    };
  }
  if (type === FETCH_AWARDS_CONTENTS) {
    return {
      ...state,
      AwardsContentData: payload.data,
    };
  }
  if (type === FETCH_AWARDS_POSTS) {
    return {
      ...state,
      AwardsPostsData: payload.data,
    };
  }
  if (type === FETCH_AWARDS_POSTS_BY_ID) {
    return {
      ...state,
      AwardsById: payload.data,
    };
  }
  if (type === FETCH_AWARD_DETAILS_HEADER) {
    return {
      ...state,
      AwardHeader: payload.data,
    };
  }
  if (type === FETCH_EVENT_DETAILS_HEADER) {
    return {
      ...state,
      EventHeader: payload.data,
    };
  }
  return state;
};
export default reducer;
