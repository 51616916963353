import React, { useEffect, useContext } from "react";
import AboutUserIcon from "../images/About_User_Icon.png"; // Importing user icon image
import { AppContext } from "../Context/Provider";
import LazyLoad from "react-lazyload";

const AboutServices = () => {
  const { FetchAboutServiceDetail, AboutServiceDetailsData } =
    useContext(AppContext);

  useEffect(() => {
    FetchAboutServiceDetail();
  }, []);

  return (
    <>
      {/* About Services Section Start Here */}
      <section className="py-16 bg-gray-100 font-poppins px-4 md:px-6 lg:px-8 mt-48">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {AboutServiceDetailsData.map((service, index) => (
              <div key={index} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-center mb-4">
                  <span className="inline-block bg-green-100 text-xs px-2 py-1 rounded-lg">
                    <span style={{ color: "#f2c94c" }}>★</span> 4.8
                  </span>
                </div>
                <h3 className="text-xl font-bold text-custom-green mb-2">
                  {service.title}
                </h3>
                <div className="flex items-center mb-2">
                  <LazyLoad height={532} offset={100}>
                    <img
                      src={AboutUserIcon}
                      width="20"
                      alt="Assistant Icon"
                      className="mr-2"
                    />
                  </LazyLoad>
                  <span>{service.count} </span>
                </div>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-[#F0F5FF] py-20 flex justify-center items-center px-4 md:px-6 lg:px-8">
        <div className="max-w-6xl w-full flex flex-col items-center">
          <div className="flex flex-wrap md:space-x-24 items-center w-full">
            <div className="w-full md:w-1/2 lg:w-2/5 mb-6 md:mb-0 relative">
              <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center md:text-left">
                Accelerate your career
              </h2>
              <p className="text-gray-600 mb-12 text-lg text-center md:text-left">
                We have proven methods that work for more than 7 years to help
                our client's business grow
              </p>
              <div className="w-full h-auto flex justify-center md:justify-start">
                <iframe
                  width="100%"
                  height="350"
                  src="https://www.youtube.com/embed/J4VZnC6uwpY?controls=0&modestbranding=1&rel=0&start=15&loop=1&playlist=J4VZnC6uwpY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  referrerPolicy="strict-origin-when-cross-origin"
                ></iframe>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-2/5">
              {[
                {
                  number: 1,
                  title: "Speed and scale",
                  description:
                    "We move fast. It took us just 12 months to launch Rikaab Food in all over Banadir district. And only four months to launch Rikaab Drive.",
                },
                {
                  number: 2,
                  title: "Impact",
                  description:
                    "We empower people to take the initiative and own their area of responsibility. As a result, they can continuously impact one of the largest.",
                },
                {
                  number: 3,
                  title: "Opportunities",
                  description:
                    "As the company grows, new opportunities arise. We encourage our people to take on challenges so that they could progress in their careers.",
                },
              ].map((item, index) => (
                <div key={index} className="mb-6 flex items-start">
                  <div className="bg-gray-800 text-white rounded-full w-60 h-20 flex items-center justify-center mr-4 text-3xl">
                    {item.number}
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-gray-800 mb-2">
                      {item.title}
                    </h3>
                    <p className="text-gray-600 text-lg">{item.description}</p>
                  </div>
                </div>
              ))}
              {/* <div className="flex justify-center items-center w-full h-full mt-8 md:mt-12">
                <button className="bg-green-500 text-white px-8 py-2 text-md rounded-md">
                  READ MORE
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutServices;
