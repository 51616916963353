import React, { useState, useEffect, useContext } from "react";
import locationImage from "../images/Component 6.png";
import call from "../images/call.png";
import short from "../images/shortcall.png";
import envelop from "../images/email.png";
import { AppContext } from "../Context/Provider";
import toast from "react-hot-toast";

const GetInTouch = () => {
  const { CreateGetnTouch } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearData = () => {
    setName("");
    setEmail("");
    setPhone("");
    setComment("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      phone,
      comment,
    };

    if (!name || !phone || !email || !comment) {
      toast.error("Please fill out all required fields.");
      return;
    }

    setIsSubmitting(true);
    clearData();

    try {
      await CreateGetnTouch(data);
    } catch (error) {
      toast.error("Failed to send your message. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-[#F0F9FF] p-4">
      <div className="relative bg-white rounded-2xl shadow-lg max-w-5xl w-full flex flex-col lg:flex-row">
        <div className="lg:w-2/3 p-8 w-full lg:mr-8">
          <h2 className="text-3xl font-bold mb-4">
            Get in <span className="text-green-500">Touch</span>
          </h2>
          <p className="text-gray-600 mb-6">
            Any question or Feedback? Just write us a message!
          </p>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              required
              type="email"
              name="email"
              placeholder="Email"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone number"
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={phone}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                setPhone(numericValue);
              }}
            />

            <textarea
              name="comment"
              placeholder="Comment..."
              required
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <button
              type="submit"
              className={`w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "SEND"}
            </button>
          </form>
          <div className="mt-6 text-gray-600 space-y-4 lg:space-y-0 lg:flex lg:justify-between items-center">
            <div className="flex items-center space-x-2">
              <img src={call} alt="Phone Icon" className="w-5 h-5" />
              <div>
                <p className="font-bold mt-1 lg:mt-4">Phone</p>
                <p className="text-green-500">+252612422000</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <img src={short} alt="Short Call Icon" className="w-5 h-5" />
              <div>
                <p className="font-bold mt-1 lg:mt-4">Short call</p>
                <p className="text-green-500">7000</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <img src={envelop} alt="Email Icon" className="w-5 h-5" />
              <div>
                <p className="font-bold mt-1 lg:mt-4">Email</p>
                <p className="text-green-500 ">info@rikaab.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 bg-[#183A4A] rounded-b-lg lg:rounded-r-lg lg:rounded-bl-none w-full relative mt-6 lg:mt-0 flex justify-center items-center">
          <iframe
            title="Map Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.0943728480983!2d45.3094779!3d2.0443819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3d5843e73bbb95d9%3A0x95340167630a311d!2sRikaab+Office!5e0!3m2!1sen!2sso!4v1693658517394!5m2!1sen!2sso"
            width="90%"
            height="80%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            // className="rounded-lg shadow-lg"
            className="lg:absolute left-0 top-0 lg:left-[-40px] lg:top-1/2 lg:transform lg:-translate-y-1/2 rounded-lg shadow-lg z-10 lg:w-auto"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
