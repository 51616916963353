import React, { useState } from "react";
import HandsImage from "../images/hands.png";
import WorldIcon from "../images/world.png";
import EyeIcon from "../images/ey.png";
import LazyLoad from "react-lazyload";

const MissionSection = () => {
  const [isMissionVisible, setIsMissionVisible] = useState(true);

  return (
    <section className="bg-white py-20 flex justify-center items-center">
      <div className="max-w-[1199px] w-full flex flex-col items-center">
        <div className="flex flex-wrap space-x-12 items-start w-full px-6">
          <div className="w-full md:w-1/2 lg:w-[500px] mb-6 md:mb-0 relative">
            <LazyLoad height={532} offset={100}>
              <img src={HandsImage} alt="Hands" className="w-full h-auto" />
            </LazyLoad>
          </div>
          <div className="w-full md:w-1/2 lg:w-[600px] px-8">
            <div>
              <div className="flex space-x-8 mb-4">
                <div>
                  <button
                    id="mission-btn"
                    className={`py-2 px-4 rounded-md focus:outline-none flex flex-row ${
                      isMissionVisible
                        ? "bg-[#054752] text-white"
                        : "bg-[#F4F4F4] text-gray-700"
                    }`}
                    onClick={() => setIsMissionVisible(true)}
                  >
                    <LazyLoad height={26} offset={100}>
                      <img
                        src={WorldIcon}
                        alt="Mission Icon"
                        className="w-[26px] h-[26px] mr-2"
                      />
                    </LazyLoad>
                    Our Mission
                  </button>
                </div>
                <div>
                  <button
                    id="vision-btn"
                    className={`py-2 px-4 rounded-md focus:outline-none flex flex-row ${
                      !isMissionVisible
                        ? "bg-[#054752] text-white"
                        : "bg-[#F4F4F4] text-gray-700"
                    }`}
                    onClick={() => setIsMissionVisible(false)}
                  >
                    <LazyLoad height={26} offset={100}>
                      <img
                        src={EyeIcon}
                        alt="Vision Icon"
                        className="w-[26px] h-[26px] mr-2"
                      />
                    </LazyLoad>
                    Our Vision
                  </button>
                </div>
              </div>
              <div className="text-left">
                {isMissionVisible ? (
                  <p className="text-gray-700">
                    Our mission at Rikaab is to enhance the quality of life by
                    providing reliable, efficient, and accessible online
                    services that cater to the diverse needs of our customers.
                    We are dedicated to:
                  </p>
                ) : (
                  <p className="text-gray-700">
                    At Rikaab, our vision is to be the leading online service
                    platform that seamlessly integrates technology and
                    convenience to transform everyday living. We aspire to
                    create a world where accessing essential services is as
                    simple as a click, empowering individuals and communities to
                    thrive in a fast-paced, digital age. Through continuous
                    innovation and commitment to excellence, we aim to set new
                    standards in customer satisfaction and service delivery,
                    making Rikaab the preferred choice for all online service
                    needs.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
