import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AppContext } from "../../Context/Provider";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { IoTrashOutline } from "react-icons/io5";
function EventDetailHeader() {
  const {
    CreateEventHeader,
    FetchEventHeader,
    EventHeader,
    UpdateEventHeader,
    DeleteEventdHeader,
    User,
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filtertext, setfiltertext] = useState("");
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    FetchEventHeader();
  }, []);

  const handleEdit = (row) => {
    console.log("Edit row: ", row);
    setTitle(row.title);
    setCurrentId(row._id);
    setDescription(row.description);
    setSelectedFile(null);
    setShowModal(true);
    setEditMode(true);
  };

  const handleDelete = (rowId) => {
    setDeleteId(rowId);
    setShowDeleteModal(true);
    console.log("deleteId", deleteId);
  };

  const handleConfirmDelete = async () => {
    try {
      await DeleteEventdHeader(deleteId);
      setDeleteId("");
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete the item:", error);
      // Optionally, show an error message to the user
    }
  };

  const columns = [
    { name: "Title", selector: (row) => row.title, grow: 1 },
    {
      name: "File Name",
      selector: (row) => row.originalFileName,
      grow: 2,
      cell: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.originalFileName}</div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleEdit(row)}
            className="text-custom-green hover:text-blue-700"
          >
            <HiOutlinePencilSquare />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            className="text-custom-red hover:text-custom-yellow"
          >
            <IoTrashOutline />
          </button>
        </div>
      ),
      button: true,
      grow: 0.5,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#f8f9fa",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const ClearData = () => {
    setSelectedFile(null);
    setTitle("");
    setDescription("");
    setError("");
    setCurrentId("");
    setEditMode(false);
  };

  const filteredItems = EventHeader?.filter((item) => {
    return columns.some((column) => {
      const value = column.selector ? column.selector(item) : null;
      return (
        value &&
        value.toString().toLowerCase().includes(filtertext.toLowerCase())
      );
    });
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-col items-end space-y-2 w-full p-4">
        <button
          onClick={() => {
            setShowModal(true);
            ClearData();
          }}
          className="px-4 py-2 bg-custom-green text-white rounded"
        >
          + Add
        </button>
        <input
          type="text"
          onChange={(e) => setfiltertext(e.target.value)}
          value={filtertext}
          placeholder="Search here"
          className="border rounded p-2 w-full max-w-xs"
        />
      </div>
    );
  }, [filtertext, resetPaginationToggle]);

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 100;
    if (inputValue.length <= maxLength) {
      setTitle(inputValue);
    } else {
      const truncatedValue = inputValue.slice(0, maxLength);
      setTitle(truncatedValue);
    }
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 250;
    if (inputValue.length <= maxLength) {
      setDescription(inputValue);
    } else {
      const truncatedValue = inputValue.slice(0, maxLength);
      setDescription(truncatedValue);
    }
  };
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (
  //     file &&
  //     ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(
  //       file.type
  //     )
  //   ) {
  //     setSelectedFile(file);
  //     setError(false);
  //   } else {
  //     setSelectedFile(null);
  //     setError(true);
  //     toast.error("Please select a valid image file (PNG, JPEG, JPG, SVG).");
  //   }
  // };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (
        ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(
          file.type
        )
      ) {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
          img.onload = () => {
            if (img.width === 1920 && img.height === 532) {
              setSelectedFile(file);
              setError(false);
            } else {
              setSelectedFile(null);
              setError(true);
              toast.error(`Image must be 1920x532 pixels.`);
            }
          };
          img.src = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        setError(true);
        toast.error("Please select a valid image file (PNG, JPEG, JPG, SVG).");
      }
    }
  };

  const handleAddOrUpdate = async () => {
    const formData = new FormData();
    formData.append("title", Title);
    formData.append("userId", User?.data?.id);
    formData.append("description", Description);
    if (selectedFile) {
      formData.append("Photo", selectedFile);
    }
    // console.log(editMod);
    if (!Title || (!editMode && !selectedFile)) {
      setError("Please fill out all fields.");
      return;
    }
    setIsLoading(true);

    try {
      if (editMode) {
        await UpdateEventHeader(currentId, formData);
      } else {
        // console.log("create", selectedFile);
        await CreateEventHeader(formData);

        // toast.success("Blog detail created successfully");
      }
    } catch (err) {
      // Handle any potential errors from the API calls
      console.error(err);
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      ClearData();
      setShowModal(false);
    }
  };

  return (
    <div className="px-8 py-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        customStyles={customStyles}
        className="shadow-lg bg-white rounded"
      />

      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-xl">
            <h2 className="text-xl font-bold mb-4">
              {editMode ? "Edit" : "Add"} Header Image
            </h2>
            <div className="grid grid-cols-1 gap-4 mb-4">
              <div className="col-span-1">
                <label className="block text-gray-700 mb-2">Title</label>
                <input
                  type="text"
                  value={Title}
                  onChange={handleTitleChange}
                  className={`w-full p-2 border rounded ${
                    !Title && error ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Title"
                />
              </div>
              {/* <div className="col-span-1">
                <label className="block text-gray-700 mb-2">Description</label>
                <textarea
                  value={Description}
                  onChange={handleDescriptionChange}
                  className={`w-full p-2 border rounded ${
                    !Description && error ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Description"
                />
              </div> */}
              <div className="col-span-1">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="file_input"
                >
                  {editMode ? "Change file (Optional)" : "Upload file"}
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 focus:outline-none ${
                    !selectedFile && error ? "border-red-500" : ""
                  }`}
                  id="file_input"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                  onChange={handleFileChange}
                />
              </div>
            </div>

            {/* <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrUpdate}
                className="px-4 py-2 bg-green-500 text-white rounded"
              >
                {editMode ? "Update" : "Add"}
              </button>
            </div> */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setShowModal(false);
                  ClearData();
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleAddOrUpdate}
                className={`px-4 py-2 rounded ${
                  isLoading ? "bg-gray-400" : "bg-custom-green"
                } text-white`}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : editMode ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl font-bold mb-4 text-red-400 text-center">
              Are you sure?
            </h2>

            <p className="text-gray-700 mb-4">
              Are you sure you want to delete this record?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteId("");
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventDetailHeader;
