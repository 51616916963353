import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import heroImage from "../images/hero.png";
import googlePlayImage from "../images/googleplay.png";
import appStoreImage from "../images/appstore.png";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const RestayrantsCoverSection = () => {
  const { FetchRestauranslider, RestauransHeaderData } = useContext(AppContext);
  const [backgroundImage, setBackgroundImage] = useState(""); // State for background image

  useEffect(() => {
    // Load the background image after component mounts
    const loadBackgroundImage = () => {
      setBackgroundImage(`url(${heroImage})`);
    };

    loadBackgroundImage();
  }, []);

  useEffect(() => {
    FetchRestauranslider();
  }, []);

  return (
    <>
      {RestauransHeaderData?.map((restaurant) => (
        <div
          key={restaurant._id}
          className="relative bg-cover bg-center mt-6"
          style={{
            backgroundImage: `url(${restaurant.fileName})`,
            marginTop: "10px",
            height: "532px",
            transition: "background-image 1s ease-in-out",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-black/85 to-gray-800/75 h-full w-full"></div>
          <div className="relative z-10 flex items-center justify-center h-full p-4">
            <div className="text-center text-white rounded-lg">
              <div className="flex justify-center">
                <Link
                  to="#"
                  className="text-white py-2 rounded-md  flex items-center text space-x-2 text-2xl md:text-5xl mt-10"
                >
                  <span
                    className="font-bold"
                    dangerouslySetInnerHTML={{ __html: restaurant.title }}
                  ></span>
                </Link>
              </div>
              <div className="flex justify-center mt-1">
                <Link
                  to="#"
                  className="text-white py-2 rounded-md  flex items-center space-x-2 text-lg md:text-2xl"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: restaurant.description }}
                  ></span>
                </Link>
              </div>
              <div className="flex justify-center space-x-4 items-center mt-8 md:mt-24 mb-6">
                <Link to="https://play.google.com/store/apps/details?id=com.faras&pli=1">
                  <LazyLoad offset={100}>
                    <img
                      className="w-24 md:w-48 h-auto"
                      src={googlePlayImage}
                      alt="Google Play"
                    />
                  </LazyLoad>
                </Link>
                <Link to="https://apps.apple.com/us/app/rikaab/id1636848011">
                  <LazyLoad offset={100}>
                    <img
                      className="w-24 md:w-48 h-auto"
                      src={appStoreImage}
                      alt="App Store"
                    />
                  </LazyLoad>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default RestayrantsCoverSection;
