import React from "react";
import LazyLoad from "react-lazyload";
import LineImage from "../../images/LineImage.png";
import image1 from "../../images/image.jpeg";
import image2 from "../../images/image(2).jpeg";
import image3 from "../../images/image(3).jpeg";
import image4 from "../../images/image(4).jpeg";
import image5 from "../../images/image(5).jpeg";
import image6 from "../../images/image(6).jpeg";

const benefits = [
  {
    id: 1,
    image: image1,
    alt: "Speedy Service",
    title: "Rikaab Taxi",
    description:
      "With our user-friendly app, you can book a taxi in seconds. Whether you're commuting to work or exploring the city, our service ensures a hassle-free experience.",
  },
  {
    id: 2,
    image: image2,
    title: "Rikaab Suuq",
    description:
      "Whether you're looking for casual wear, formal attire, or accessories, Rikaab offers a variety of choices to match your style.",
  },
  {
    id: 3,
    image: image3,
    alt: "Supermarkets",
    title: "Supermarkets",
    description:
      "One-Stop Shopping for All Your Needs. Our intuitive platform makes it easy to find and purchase everything you need from the comfort of your home.",
  },
  {
    id: 4,
    image: image4,
    alt: "Food",
    title: "Food",
    description:
      "Explore a diverse selection of local restaurants and international cuisines, all available for delivery. Browse menus, customize your orders, and track your delivery with just a few clicks on our app.",
  },
  {
    id: 5,
    image: image5,
    alt: "Ticket Booking",
    title: "Ticket Booking",
    description:
      "Book flights with ease, securing the best prices and itineraries for your journey. Our secure payment system ensures a safe and reliable booking experience every time.",
  },
  {
    id: 6,
    image: image6,
    alt: "DegDeg Delivery",
    title: "DegDeg Delivery",
    description:
      "Bringing Essentials to Your Doorstep. Shop from a wide range of fresh groceries, household items, and more without leaving your home. Our online delivery service brings everything you need directly to you.",
  },
];

const Lasts = () => {
  // Divide the benefits array into chunks of 3
  const benefitRows = [];
  for (let i = 0; i < benefits.length; i += 3) {
    benefitRows.push(benefits.slice(i, i + 3));
  }

  return (
    <div className="container my-12 px-4 mx-auto">
      <div className="text-center mb-12">
        <h2 className="text-2xl font-bold">Rikaab Driver Benefits</h2>
        <p className="text-gray-600">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et
        </p>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        {benefitRows.map((row, rowIndex) => (
          <div
            className="w-full flex justify-center items-center mb-8"
            key={rowIndex}
          >
            {row.map((benefit, colIndex) => (
              <React.Fragment key={benefit.id}>
                <div className="w-full md:w-1/4 px-4 mt-10">
                  <LazyLoad offset={100}>
                    <div
                      className="mx-auto mb-4 p-4 rounded-xl bg-gray-100"
                      style={{ width: "100px", height: "100px" }}
                    >
                      <img
                        src={benefit.image}
                        alt={benefit.alt}
                        className="w-full h-full object-contain rounded-xl"
                      />
                    </div>
                  </LazyLoad>
                  <h4 className="text-xl font-bold text-center">
                    {benefit.title}
                  </h4>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
                {colIndex < row.length - 1 && (
                  <LazyLoad height={200} offset={100}>
                    <img
                      src={LineImage}
                      alt="Arrow"
                      // mx-auto add
                      className=" hidden md:block"
                      style={{ width: "200px" }}
                    />
                  </LazyLoad>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Lasts;
