import React from "react";
import img from "../images/c1.png";
const CategoriesSection = () => {
  const categories = ["Booking", "Discount", "Driver", "Mobile", "Services"];

  return (
    <div className="flex-col items-center space-x-2 p-8 bg-white rounded-lg shadow">
      <h2 className="text-lg font-semibold">Categories</h2>
      <ul className="space-y-2">
        {categories.map((category, index) => (
          <li key={index} className="flex items-center space-x-2">
            <span className="text-green-500">
              <img
                className="m-[0.2rem_0.3rem_0.3rem_0] w-[0.8rem] h-[0.8rem]"
                src={img}
                alt={category}
              />
            </span>
            <span>{category}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoriesSection;
