import React from "react";
import locationDeliveryMan from "../images/locationDeliveryMan.png"; // Replace with the actual import path
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const HowItWorksSection = () => {
  return (
    <div className="w-full py-10 mt-24" style={{ backgroundColor: "#f0f5ff" }}>
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 mt-6">
          How the Rikaab deliveryman app works
        </h2>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/3 mb-8 lg:mb-0">
            <img
              src={locationDeliveryMan}
              alt="App Interface"
              className="mx-auto lg:mr-8"
              style={{ height: "auto", maxHeight: "560px" }}
            />
          </div>
          <div className="lg:w-2/3 grid grid-cols-1 sm:grid-cols-2 gap-8 justify-center">
            {[
              {
                title: "Receive orders",
                description:
                  "Unum liber commune in mel, ut pri tritani propriae menandri. Cum et magna porro intellegat.",
              },
              {
                title: "Pick up the order",
                description:
                  "Unum liber commune in mel, ut pri tritani propriae menandri. Cum et magna porro intellegat.",
              },
              {
                title: "Deliver",
                description:
                  "Unum liber commune in mel, ut pri tritani propriae menandri. Cum et magna porro intellegat.",
              },
              {
                title: "Track your earnings",
                description:
                  "Unum liber commune in mel, ut pri tritani propriae menandri. Cum et magna porro intellegat.",
              },
            ].map((item, index) => (
              <div key={index} className="flex items-start">
                <div className="w-12 h-12 bg-custom-green text-white flex items-center justify-center rounded-full mr-4">
                  <FontAwesomeIcon icon={faCheck} className="text-xl px-4" />
                </div>
                <div>
                  <h4 className="text-lg md:text-2xl font-bold">
                    {item.title}
                  </h4>
                  <p className="text-gray-700 text-sm md:text-base">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
