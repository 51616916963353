import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload"; // Import LazyLoad
import { AppContext } from "../Context/Provider";

const SupportDetailsSection = () => {
  const { FetchSupportDetails, SupportDetailsData } = useContext(AppContext);
  useEffect(() => {
    FetchSupportDetails();
  }, []);
  return (
    <>
      {SupportDetailsData?.map((el) => (
        <div className="relative mt-6">
          <LazyLoad height={532} offset={100}>
            <div
              className="relative bg-cover bg-center"
              style={{
                backgroundImage: `url(${el.fileName})`,
                marginTop: "10px",
                height: "532px",
              }}
            >
              <div className="relative z-10 flex items-center justify-center h-[532px] p-4 mr-0 md:mr-66">
                <div className="text-center text-white bg-opacity-75 rounded-lg items-center">
                  <div className="flex justify-center">
                    <Link
                      to="#" // Replace with the actual path
                      className="text-white py-2 rounded-md  flex items-center text space-x-2 text-2xl md:text-6xl mt-20 ms-5"
                    >
                      <span style={{ fontWeight: 700 }}>{el.title}</span>
                    </Link>
                  </div>
                  <div className="flex justify-center mt-1">
                    <Link
                      to="#" // Replace with the actual path
                      className="text-white py-2 rounded-md  flex items-center space-x-2 md:text-2xl text-1xl"
                    >
                      <span>Support &gt; Restaurant</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoad>
        </div>
      ))}
    </>
  );
};

export default SupportDetailsSection;
