import React, { useEffect, useContext } from "react";
import "../Styles/Service.css"; // Import the CSS file
import LazyLoad from "react-lazyload";
import boostIcon from "../images/Vector(5).png";
import earnIcon from "../images/Vector(4).png";
import settingIcon from "../images/Vector(3).png";
import Icon1 from "../images/Vector2.png";
import Icon2 from "../images/Vector2(2).png";
import center from "../images/center.svg";
import { AppContext } from "../Context/Provider";
import { useMediaQuery } from "react-responsive";
const ServicesConvinience = () => {
  const { FetchHomeLearnMore, HomeLearnMoreData } = useContext(AppContext);

  useEffect(() => {
    FetchHomeLearnMore();
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const c = isMobile ? { marginTop: "0px" } : { marginTop: "0px" };
  return (
    <section className="py-10 learn-about-us-section" style={{ paddingRight: "15%", paddingLeft: "15%", ...c}}>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-6">
        <div className="md:w-1/2 flex flex-col justify-center h-full mt-20">
          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
              <LazyLoad offset={100}>
                <img
                  src={boostIcon}
                  alt="Variety of Cuisines"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-semibold text-xl md:text-xl">
                Variety of Cuisines
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Explore a diverse selection of local restaurants and
                international cuisines, all available for delivery.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
              <LazyLoad offset={100}>
                <img src={earnIcon} alt="Easy Ordering" className="w-8 h-8" />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-semibold text-xl md:text-xl">
                Easy Ordering
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Browse menus, customize your orders, and track your delivery
                with just a few clicks on our app.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
              <LazyLoad offset={100}>
                <img
                  src={settingIcon}
                  alt="Wide Selection"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-semibold text-xl md:text-xl">
                Wide Selection
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Access a comprehensive range of products, including fresh
                produce, pantry staples, personal care items, and more.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
              <LazyLoad offset={100}>
                <img
                  src={Icon1}
                  alt="Convenient Shopping"
                  className="w-8 h-8"
                />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-semibold text-xl md:text-xl">
                Convenient Shopping
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Our intuitive platform makes it easy to find and purchase
                everything you need from the comfort of your home.
              </p>
            </div>
          </div>

          <div className="my-4 flex items-start md:my-6">
            <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
              <LazyLoad offset={100}>
                <img src={Icon2} alt="Competitive Prices" className="w-8 h-8" />
              </LazyLoad>
            </div>
            <div>
              <h4 className="font-semibold text-xl md:text-xl">
                Competitive Prices
              </h4>
              <p className="text-gray-700 text-base md:text-lg">
                Enjoy great deals and savings on a wide array of products, with
                options to suit every budget.
              </p>
            </div>
          </div>
        </div>

        <div className="md:w-1/2 flex justify-center items-center">
          {/* {HomeLearnMoreData?.map((img) => {
            return ( */}
          <LazyLoad height={795} offset={100} once key={center}>
            <img
              src={center}
              alt="Rikaab App Screenshot"
              width="511"
              height="795"
            />
          </LazyLoad>
          {/* );
          })} */}
        </div>
      </div>
    </section>
  );
};

export default ServicesConvinience;
