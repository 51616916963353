import React, { useEffect } from "react";
import DeliveryCoverSection from "../../Components/DeliveryCoverSection";
import DeliveryBenefitsSection from "../../Components/DeliveryBenefitsSection";
import DegDegSection from "../../Components/DegDegSection";
import RequirementsSection from "../../Components/RequirementsSection";
import HowItWorksSection from "../../Components/HowItWorksSection";
import FAQSection from "../../Components/SupportFAQSection";
import FAQSectionQuestions from "../../Components/SupportFAQSection 1";
import Footer from "../../Components/Footer";
import { useLocation } from "react-router-dom";
import GeneralQuestions from "../../Components/GeneralFAQ";
function Delivery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { id } = location.state || {};
  console.log("delivery:", id);
  return (
    <div>
      {/* <DetailsLayout /> */}
      <DeliveryCoverSection />
      <DeliveryBenefitsSection />
      <DegDegSection />
      <RequirementsSection />
      <HowItWorksSection />
      {/* <FAQSection /> */}
      {/* <FAQSectionQuestions /> */}
      <GeneralQuestions id={id} />
      <Footer />
    </div>
  );
}

export default Delivery;
