import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";

const RecentPosts = ({ id }) => {
  const { FetchBlogService, BlogServiceData, FetchPostsContents } =
    useContext(AppContext);
  const getid = sessionStorage.getItem("postId");
  const [selectedPostId, setSelectedPostId] = useState(getid);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    FetchBlogService();
  }, []);

  const truncateDescription = (element) => {
    const words = element.split(" ");
    if (words.length > 8) {
      return words.slice(0, 8).join(" ") + " ...";
    }
    return element;
  };

  const handleChnages = (postid) => {
    if (postid) {
      FetchPostsContents(postid);
      setSelectedPostId(postid);
      sessionStorage.setItem("postId", postid);
    } else {
      console.warn("postid is undefined or null, sessionStorage not updated.");
    }
  };

  // Filter the posts based on the search query
  const filteredPosts = BlogServiceData?.filter((post) =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg">
      {/* Search Box */}
      <div className="flex items-center space-x-2 p-2 bg-white rounded-lg shadow">
        <input
          type="text"
          className="w-full p-2 rounded-lg border border-gray-300"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
        />
        <div className="rounded-[0.6rem] border-[0.1rem_solid_#EAEAEA] bg-[#054752] flex p-[1.2rem_0_1.2rem_0.2rem] w-[4.1rem] h-[3.5rem] box-sizing-border">
          <i
            className="fa fa-search w-[1.1rem] h-[1.1rem] text-white px-4"
            aria-hidden="true"
          ></i>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4 text-left text-gray-900">
        Recent Posts
      </h2>

      <div>
        {filteredPosts?.map((post, index) => (
          <Link
            key={post._id}
            onClick={() => handleChnages(post._id)}
            to="#"
            className={index < BlogServiceData.length - 1 ? "mb-4 block" : ""}
          >
            <div
              className={`flex items-center p-3 bg-white rounded-lg hover:bg-gray-200 transition ${
                selectedPostId === post._id ? "ring-2 ring-blue-400" : ""
              }`}
            >
              {/* Image */}
              <div className="w-[156px] h-[89px] rounded-md overflow-hidden flex-shrink-0">
                <LazyLoad height={89} offset={100} once>
                  <img
                    src={post.fileName}
                    alt={post.title}
                    className="object-cover"
                    width={"156px"}
                    height={"89px"}
                  />
                </LazyLoad>
              </div>

              {/* Text */}
              <div className="ml-4 flex flex-col">
                <div
                  className="text-sm font-medium text-gray-900 leading-tight"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                  }}
                >
                  {truncateDescription(post.title)}
                </div>
                <div className="text-xs text-gray-500 flex items-center mt-2">
                  <FaRegCalendarAlt className="mr-1" /> {/* Date icon */}
                  {post.currentDateInfo
                    ? `${post?.currentDateInfo?.monthName} ${post?.currentDateInfo?.monthNumber}, ${post?.currentDateInfo?.year}`
                    : "Date not available"}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RecentPosts;
