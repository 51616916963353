import LazyLoad from "react-lazyload";
const ServiceItem = ({ icon, title, description }) => {
  return (
    <div className="my-4 flex items-start md:my-6">
      <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-white mr-4">
        <LazyLoad offset={100}>
          <img src={icon} alt={title} className="w-8 h-8" />
        </LazyLoad>
      </div>
      <div>
        <h4 className="font-semibold text-xl md:text-xl">{title}</h4>
        <p className="text-gray-700 text-base md:text-lg">{description}</p>
      </div>
    </div>
  );
};
export default ServiceItem;
