import React from "react";
import moneyVector from "../images/moneyVector.png";
import taxVector from "../images/tax_vector.png";
import reliableVector from "../images/reliable_Vector.png";
import LineImage from "../images/LineImage.png";
import LazyLoad from "react-lazyload";

const benefits = [
  {
    id: 1,
    image: moneyVector,
    alt: "Speedy Service",
    title: "Speedy Service",
    description:
      "Time is money, and we value both. Our streamlined delivery process ensures that your packages are picked up and delivered in the shortest possible time. With Rikaab Delivery, you can count on rapid service without compromising on safety.",
  },
  {
    id: 2,
    image: taxVector,
    alt: "Reliable Network",
    title: "Reliable Network",
    description:
      "We have built a robust network of drivers and delivery agents who are dedicated to getting your packages where they need to go. No matter the distance, you can rely on Rikaab to deliver consistently.",
  },
  {
    id: 3,
    image: reliableVector,
    alt: "Customer Support",
    title: "Customer Support",
    description:
      "Our dedicated customer support team is available 24/7 to assist with any questions or concerns. Whether you need help with a delivery or have a special request, we’re here to ensure a smooth and satisfactory experience",
  },
];

const DeliveryBenefitsSection2 = () => (
  <div className="container my-12 px-4 mx-auto">
    <div className="text-center mb-12">
      <h2 className="text-2xl md:text-3xl font-bold">Rikaab Tax Benefits</h2>
      <p className="text-gray-600 text-base md:text-lg">
        The Rikaab app provides detailed records of your earnings, including
        delivery fees and tips. Keeping track of your expenses, such as fuel,
        maintenance, and vehicle depreciation, can also be beneficial. Accurate
        tracking helps ensure you claim all eligible deductions on your tax
        return.
      </p>
    </div>

    <div className="flex flex-wrap text-center justify-center items-center">
      {benefits.map((benefit, index) => (
        <React.Fragment key={benefit.id}>
          <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-8">
            <LazyLoad offset={100}>
              <img
                src={benefit.image}
                alt={benefit.alt}
                className="mx-auto mb-4"
                style={{ width: "90px" }}
              />
            </LazyLoad>
            <h4 className="text-xl md:text-2xl font-bold">{benefit.title}</h4>
            <p className="text-gray-600 text-sm md:text-base">
              {benefit.description}
            </p>
          </div>
          {(index + 1) % 3 !== 0 && index < benefits.length - 1 && (
            <div className="hidden md:flex w-auto justify-center items-center">
              <LazyLoad height={200} offset={100}>
                <img
                  src={LineImage}
                  alt="Arrow"
                  className="mx-auto"
                  style={{ width: "100px" }}
                />
              </LazyLoad>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

export default DeliveryBenefitsSection2;
