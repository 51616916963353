import React from "react";
import SupportDetailsSection from "../../Components/SupportDetailsSection";
import SearchSection from "../../Components/SearchSections";
import FrequantlyQuestions from "../../Components/FrequantlyQusetions";
import ContactUsSection from "../../Components/ContactUsSection";
import Footer from "../../Components/Footer";
import { useLocation } from "react-router-dom";

// import FAQSectionQuestions from "../../Components/SupportFAQSection 1";
function SupportDetails() {
  const location = useLocation();
  const { id } = location.state || {};
  console.log("Received ID:", id);
  return (
    <div>
      <SupportDetailsSection />
      <SearchSection />
      <FrequantlyQuestions id={id} />
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default SupportDetails;
