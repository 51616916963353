import React, { useEffect, useContext } from "react";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const ServiceBenefits = () => {
  const { FetchServiceCard, ServiceCardData } = useContext(AppContext);

  useEffect(() => {
    FetchServiceCard();
  }, []);

  // Divide the ServiceCardData array into chunks of 3
  const benefitRows = [];
  for (let i = 0; i < ServiceCardData.length; i += 3) {
    benefitRows.push(ServiceCardData.slice(i, i + 3));
  }

  return (
    <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <h2 className="text-2xl md:text-3xl font-bold">
          Rikaab Driver Benefits
        </h2>
        <p className="text-gray-600 mt-3">
          At Rikaab, we value our drivers as essential partners in our mission
          to deliver exceptional service and convenience to our customers.
          <br /> We are committed to creating an environment where our drivers
          thrive professionally and personally
        </p>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        {benefitRows.map((row, rowIndex) => (
          <div
            className="w-full flex flex-wrap justify-center items-center mb-8"
            key={rowIndex}
          >
            {row.map((benefit, colIndex) => (
              <React.Fragment key={benefit.id}>
                <div className="w-full md:w-1/3 lg:w-1/4 px-6 mt-10 text-center">
                  <LazyLoad offset={100}>
                    <div
                      className="mx-auto mb-4 p-4 rounded-xl bg-green-200 bg-opacity-50"
                      style={{ width: "100px", height: "100px" }}
                    >
                      <img
                        src={benefit.fileName} // Assuming the image URL comes from `benefit.fileName`
                        alt={benefit.alt}
                        className="w-full h-full object-contain rounded-xl"
                      />
                    </div>
                  </LazyLoad>
                  <h4 className="text-xl font-bold">{benefit.title}</h4>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
                {/* {colIndex < row.length - 1 && (
                  <div className="hidden md:flex justify-center items-center">
                    <LazyLoad height={50} offset={100}>
                      <img
                        src={LineImage}
                        alt="Arrow"
                        className="w-16 lg:w-24"
                      />
                    </LazyLoad>
                  </div>
                )} */}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceBenefits;
