import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../Context/Provider";

const ShareAndComment = () => {
  const { CreateCustomersComments } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const getid = sessionStorage.getItem("postId");
  const clearData = () => {
    setName("");
    setEmail("");
    setComment("");
    setError("");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      comments: comment,
      comentId: getid,
    };

    if (!name || !email || !comment) {
      setError("Please fill out all fields.");
      return;
    }

    setIsLoading(true);
    try {
      const resp = await CreateCustomersComments(data);
      if (resp) {
        clearData(); // Clear fields only if data is successfully saved
      }
    } catch (error) {
      // You might want to set an error message here
      setError("Failed to save your comment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-screen-lg">
      <div className="bg-white p-8 rounded-lg">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
          <div className="mb-4 sm:mb-0">
            <span className="text-gray-600">Tags :</span>{" "}
            <Link to="#" className="text-green-500">
              Booking
            </Link>
          </div>
          <div className="flex space-x-2">
            <span className="text-black font-bold py-2">Share This :</span>

            <Link
              to="https://www.facebook.com/Rikaab"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-4 md:px-4 py-3 md:py-2"
            >
              <i className="fab fa-facebook-f"></i>
            </Link>

            <Link
              to="https://www.instagram.com/rikaab_app?igsh=M29jZnFnb2psZmZr"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-3 md:px-4 py-3 md:py-2"
            >
              <i className="fab fa-instagram"></i>
            </Link>

            <Link
              to="https://www.linkedin.com/company/rikaab/"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-3 md:px-4 py-3 md:py-2"
            >
              <i className="fab fa-linkedin-in"></i>
            </Link>
            <Link
              to="https://x.com/Rikaabapp"
              className="text-white text-2xl hover:text-gray-800 bg-[#054752] rounded-[0.6rem] px-4 py-3"
            >
              <i className="fab fa-twitter"></i>
            </Link>
          </div>
        </div>

        <h2 className="text-4xl font-bold mb-4 mt-24">Leave a Reply</h2>
        <p className="text-gray-600 mb-6">
          Your email address will not be published. Required fields are marked *
        </p>

        <form className="space-y-4" onSubmit={handleSave}>
          <div>
            <label
              htmlFor="name"
              className="block text-1xl font-bold font-medium text-black"
            >
              Name
            </label>
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="name"
              name="name"
              value={name}
              required
              className={`mt-1 block w-full py-2 rounded-md border-gray-300 shadow-sm focus:border-[#000] focus:ring focus:ring-green-500 focus:ring-opacity-50 bg-[#FFF]`}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-1xl font-bold font-medium text-black"
            >
              Email
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              name="email"
              value={email}
              required
              className={`mt-1 block w-full py-2 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 bg-[#FFF]`}
            />
          </div>
          <div>
            <label
              htmlFor="comment"
              className="block text-1xl font-bold font-medium text-black"
            >
              Comment
            </label>
            <textarea
              onChange={(e) => setComment(e.target.value)}
              id="comment"
              name="comment"
              rows="10"
              value={comment}
              required
              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 bg-[#FFF]`}
            ></textarea>
          </div>

          <div>
            <button
              disabled={isLoading}
              type="submit"
              className="px-4 py-4 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              {isLoading ? "Saving..." : "Post Comment"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShareAndComment;
